import { Typography } from '@mui/material'
import React from 'react'
// import { useParams } from 'react-router-dom'

function LegisMems() {
   // const params = useParams()

   return (
      <div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #403b4a, #e36363, #e36363, #FFFFFF)',
               pl: 1,
            }}
         >
            Members of this body:
         </Typography>
      </div>
   )
}

export default LegisMems
