import React from 'react'
import styles from './MainHeader.module.css'
import logo from '../../Images/dem4um.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from 'firebase/auth'
import { auth } from '../../FirebaseConfig.js'
import { selectUsers, setUser } from '../../redux/reducers/users-slice.js'

function MainHeader() {
   const user = useSelector(selectUsers).currentUser
   console.log('from main header: ', user)
   const navigate = useNavigate()
   const dispatch = useDispatch()

   function handleSignOut() {
      if (confirm('Are you sure you want to log out?')) {
         signOut(auth)
            .then(() => {
               dispatch(setUser(null))
               navigate('/')
            })
            .catch((error) => {
               // An error happened.
               console.log(error)
            })
      }
   }
   return (
      <div>
         <div className={styles.header}>
            <NavLink style={{ textDecoration: 'none' }} to="/">
               <div className={styles.header__logo}>
                  <img className={styles.logo} src={logo} />
                  {/* <h2 className={styles.header__logoTitle}>Track Democracy</h2> */}
                  <span className={styles.header__logoTitle}>Track Democracy</span>
               </div>
            </NavLink>

            <div className={styles.header__nav}>
               {/* <div className={styles.nav__item}>
                  <span className={styles.nav__itemLineOne}>Your</span>
                  <span className={styles.nav__itemLineTwo}>Shop</span>
               </div> */}
               <div style={{ textDecoration: 'none' }}>
                  <div className={styles.nav__item}>
                     {user ? (
                        <>
                           <span className={styles.nav__itemLineOne}>Hello</span>
                           <span className={styles.nav__itemLineOne}>{user.email}</span>
                        </>
                     ) : (
                        <span className={styles.nav__itemLineOne}>Hello Guest</span>
                     )}
                     {/* {user.currentUser ? ( */}

                     {/* ) : (
                        <span className={styles.nav__itemLineOne}>Hello Guest</span>
                     )} */}
                     {/* <span className={styles.nav__itemLineOne}>Hello Guest</span> */}
                     {/* <span className={styles.nav__itemLineTwo}>Sign In</span> */}
                     {user ? (
                        <div>
                           {/* <button onClick={handleSignOut} className="btn transparent">
                              Logout s
                           </button> */}
                           {/* <Button
                              variant="text"
                              size="small"
                              // startIcon={<LogoutIcon fontSize="13px" />}
                              sx={{
                                 textTransform: 'none',
                                 padding: 0,
                                 minWidth: 0,
                                 color: '#EDC970',
                              }}
                              onClick={() => handleSignOut('login')}
                           >
                              Log Out
                           </Button> */}
                           <span className={styles.nav__itemLineTwo_Two} onClick={() => handleSignOut('login')}>
                              Log Out
                           </span>
                        </div>
                     ) : (
                        <NavLink className={styles.nav__itemLineTwo} to="/login">
                           Sign In
                        </NavLink>
                     )}
                     {/* )} */}
                  </div>
               </div>

               {/* <div style={{ textDecoration: 'none' }}>
                  <div className={styles.nav__itemBasket}>
                     <CartButton />
                     <span className={(styles.nav__itemLineTwo, styles.nav__basketCount)}>xxx</span>
                  </div>
               </div> */}
            </div>
         </div>
         <div className={styles.subHeader}>
            <span className={styles.subHeaderText}>... participate in democratic governance</span>
         </div>
      </div>
   )
}

export default MainHeader
