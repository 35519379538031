import React, { useEffect, useState } from 'react'
import {
   collection,
   addDoc,
   doc,
   updateDoc,
   deleteDoc,
   arrayUnion,
   getDoc,
   arrayRemove,
   increment,
} from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'

import {
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   Link,
   MenuItem,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'

import styles from './GeoDivisions.module.css'
import Header from '../../Components/Header/Header'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../redux/reducers/countries-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { useNavigate } from 'react-router-dom'
import { selectUsers } from '../../redux/reducers/users-slice'

function SubDistrictsAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [subDistrictDetails, setSubDistrictDetails] = useState({
      LGDCode: '',
      Name: '',
      NameLocal: '',
      NameHindi: '',
      NameCode: '',
      NameAlt: '',
      HeadQuarter: '',
      HeadQuarterLocal: '',
      HeadQuarterHindi: '',
      HeadQuarterAlt: '',
      Type: '',
      Flag: null,
      Symbol: null,
      ForLegis: false,
      Country_id: '',
      CountryName: '',
      State_id: '',
      StateName: '',
      Division_id: '',
      DivisionName: '',
      District_id: '',
      DistrictName: '',
      DownLevel: 'Villages',
      Blocks: [],
      Legislatures: [],
      VillagesCount: 0,
      BlocksCount: 0,
      Census: [],
      Map: '',
      PesaStatus: '',
      LocalLanguageEng: '',
      LocalLanguageHindi: '',
      LocalLanguageLocal: '',
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })
   useEffect(() => {
      console.log('SubDistrict details on change of its value: ', subDistrictDetails)
   }, [subDistrictDetails])
   const [showForm, setShowForm] = useState(false)

   // related to countries
   const countries = useSelector(selectCountries).countriesList
   const [selectedCountry, setSelectedCountry] = useState('')

   const handleChangeCountry = (event) => {
      event.preventDefault()

      setSelectedState('')
      setIndividualState('')
      setSelectedDivision('')
      setSelectedDistrict('')
      setIndividualDistrict('')
      setShowForm(false)

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedCountry) {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)
         setSubDistrictDetails({
            ...subDistrictDetails,
            Country_id: selectedCountry,
            CountryName: selectedCountryComplete.Name,
         })
         fetchStatesList()
      } else {
         setSubDistrictDetails({
            ...subDistrictDetails,
            Country_id: '',
            CountryName: '',
         })
         setStatesList([])
      }
   }, [selectedCountry])

   // related to states
   const [statesList, setStatesList] = useState([])
   const [selectedState, setSelectedState] = useState('')
   const [hasDivisions, setHasDivisions] = useState(false)

   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   const [individualState, setIndividualState] = useState('')

   const handleChangeState = async (event) => {
      event.preventDefault()

      setSelectedDivision('')
      setSelectedDistrict('')
      setIndividualDistrict('')
      setShowForm(false)

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedState(event.target.value)

            if (event.target.value !== '') {
               const docRef = doc(db, 'States', event.target.value)
               const docSnap = await getDoc(docRef)

               if (docSnap.exists()) {
                  setIndividualState({ id: docSnap.id, ...docSnap.data() })
               } else {
                  // docSnap.data() will be undefined in this case
                  setIndividualState('')
                  console.log('No such document!')
               }
            } else {
               setIndividualState('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedState && individualState) {
         setSubDistrictDetails({ ...subDistrictDetails, State_id: selectedState, StateName: individualState.Name })

         const sortedListDivisions = [...individualState.Divisions].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDivisionsList(sortedListDivisions)

         const sortedListDistricts = [...individualState.Districts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDistrictsListRoot(sortedListDistricts)

         if (individualState.DownLevel === 'Divisions') {
            setHasDivisions(true)
            setDistrictsList([])
         } else {
            setHasDivisions(false)
            setDistrictsList(sortedListDistricts)
         }
      } else {
         setSubDistrictDetails({ ...subDistrictDetails, State_id: '', StateName: '' })
         setDivisionsList([])
         setDistrictsListRoot([])
         setDistrictsList([])
         setHasDivisions(false)
      }
   }, [selectedState, individualState])

   // related to Divisions
   const [divisionsList, setDivisionsList] = useState('')
   const [selectedDivision, setSelectedDivision] = useState('')

   const handleChangeDivision = (event) => {
      event.preventDefault()

      setSelectedDistrict('')
      setIndividualDistrict('')
      setShowForm(false)

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDivision(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDivision) {
         const selectedDivisionComplete = divisionsList.find((item) => item.Division_id === selectedDivision)
         setSubDistrictDetails({
            ...subDistrictDetails,
            Division_id: selectedDivision,
            DivisionName: selectedDivisionComplete.Name,
         })
         fetchDistrictsListByDivision()
      } else {
         setSubDistrictDetails({
            ...subDistrictDetails,
            Division_id: '',
            DivisionName: '',
         })
         setDistrictsList([])
      }
   }, [selectedDivision])

   // related to districts
   const [districtsListRoot, setDistrictsListRoot] = useState('')
   const [districtsList, setDistrictsList] = useState('')
   const [selectedDistrict, setSelectedDistrict] = useState('')
   const [individualDistrict, setIndividualDistrict] = useState('')

   const fetchDistrictsListByDivision = async () => {
      setDistrictsList(districtsListRoot.filter((item) => item.Division_id === selectedDivision))
   }

   const handleChangeDistrict = async (event) => {
      event.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDistrict(event.target.value)

            if (event.target.value !== '') {
               const docRef = doc(db, 'Districts', event.target.value)
               const docSnap = await getDoc(docRef)

               if (docSnap.exists()) {
                  setIndividualDistrict({ id: docSnap.id, ...docSnap.data() })
                  setShowForm(true)
               } else {
                  // docSnap.data() will be undefined in this case
                  setIndividualDistrict('')
                  setShowForm(false)
               }
            } else {
               setIndividualDistrict('')
               setShowForm(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDistrict && individualDistrict) {
         setSubDistrictDetails({
            ...subDistrictDetails,
            District_id: selectedDistrict,
            DistrictName: individualDistrict.Name,
         })

         const sortedListSubDistricts = [...individualDistrict.SubDistricts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setSubDistrictsList(sortedListSubDistricts)
         setShowForm(true)
      } else {
         setSubDistrictDetails({
            ...subDistrictDetails,
            District_id: '',
            DistrictName: '',
         })
         setSubDistrictsList([])
         setShowForm(false)
      }
   }, [selectedDistrict, individualDistrict])

   // related to subDistricts
   const [subDistrictsList, setSubDistrictsList] = useState('')

   // related to form filling and submission
   function handleSubDistrictDetails(e) {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSubDistrictDetails({ ...subDistrictDetails, [e.target.name]: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const [lastSubDistrict, setLastSubDistrict] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddSubDistrict = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               subDistrictDetails.Name.length >= 3 &&
               subDistrictDetails.NameHindi.length >= 3 &&
               subDistrictDetails.LGDCode.length > 1 &&
               subDistrictDetails.Country_id.length >= 2 &&
               subDistrictDetails.State_id.length >= 2 &&
               subDistrictDetails.District_id.length >= 2
            ) {
               subDistrictDetails.CrBy = auth.currentUser.uid
               subDistrictDetails.CrDt = new Date()
               subDistrictDetails.CrIP = ip.data.ip
               console.log('subDistrictDetails 1: ', subDistrictDetails)
               setLastSubDistrict(subDistrictDetails.Name)
               setSubDistrictDetails({
                  ...subDistrictDetails,
                  Name: '',
                  NameLocal: '',
                  NameHindi: '',
                  LGDCode: '',
                  PesaStatus: '',
                  CrBy: '',
                  CrDt: '',
                  CrDtSys: '',
                  CrIP: '',
               })
               setUploading(true)
               try {
                  const docRef = await addDoc(collection(db, 'SubDistricts'), subDistrictDetails)
                  subDistrictDetails.id = docRef.id

                  const newDetailForInsertUpdate = {
                     SubDistrict_id: docRef.id,
                     Name: subDistrictDetails.Name,
                     NameLocal: subDistrictDetails.NameLocal,
                     NameHindi: subDistrictDetails.NameHindi,
                     Flag: subDistrictDetails.Flag,
                     Symbol: subDistrictDetails.Symbol,
                     ForLegis: subDistrictDetails.ForLegis,
                  }

                  const docRefUpdateDistrict = doc(db, 'Districts', selectedDistrict)
                  const docRefUpdateState = doc(db, 'States', selectedState)
                  const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)

                  await updateDoc(docRefUpdateDistrict, {
                     SubDistricts: arrayUnion(newDetailForInsertUpdate),
                     SubDistrictsCount: increment(1),
                  })

                  if (selectedDivision !== '') {
                     const docRefUpdateDivision = doc(db, 'Divisions', selectedDivision)
                     await updateDoc(docRefUpdateDivision, {
                        SubDistrictsCount: increment(1),
                     })
                  }

                  await updateDoc(docRefUpdateState, {
                     SubDistrictsCount: increment(1),
                  })

                  await updateDoc(docRefUpdateCountry, {
                     SubDistrictsCount: increment(1),
                  })

                  setSubDistrictsList([...subDistrictsList, newDetailForInsertUpdate])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding sub district name')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const subDistrictRef = doc(db, 'SubDistricts', id)
            try {
               await updateDoc(subDistrictRef, {
                  ForLegis: !thisForLegis,
               })

               const docRefDistrict = doc(db, 'Districts', selectedDistrict)

               await updateDoc(docRefDistrict, {
                  SubDistricts: arrayRemove({
                     SubDistrict_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: thisForLegis,
                  }),
               })

               await updateDoc(docRefDistrict, {
                  SubDistricts: arrayUnion({
                     SubDistrict_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: !thisForLegis,
                  }),
               })

               setEditStatus(true)
               setForLegis({ id, thisForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         // eslint-disable-next-line array-callback-return
         districtsList.map((item) => {
            if (item.District_id === forLegis.id) {
               item.ForLegis = !forLegis.ForLegis
            }
         })
         setEditStatus(false)
      }
   }, [forLegis, editStatus])

   const handleDelete = async (e, id, thisName, thisNameLocal, thisNameHindi, thisFlag, thisSymbol, thisForLegis) => {
      e.preventDefault()

      console.log('reached 1')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this sub-district?')) {
               try {
                  console.log('reached 2', id)
                  await deleteDoc(doc(db, 'SubDistricts', id))
                  console.log('reached 3')
                  const docRefDistrict = doc(db, 'Districts', selectedDistrict)
                  const docRefState = doc(db, 'States', selectedState)
                  const docRefCountry = doc(db, 'Countries', selectedCountry)
                  console.log('reached 4')
                  await updateDoc(docRefDistrict, {
                     SubDistricts: arrayRemove({
                        SubDistrict_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        ForLegis: thisForLegis,
                     }),
                     SubDistrictsCount: increment(-1),
                  })
                  console.log('reached 5')
                  if (selectedDivision !== '') {
                     const docRefDivision = doc(db, 'Divisions', selectedDivision)
                     await updateDoc(docRefDivision, {
                        SubDistrictsCount: increment(-1),
                     })
                  }
                  console.log('reached 6')
                  await updateDoc(docRefState, {
                     SubDistrictsCount: increment(-1),
                  })
                  console.log('reached 7')
                  await updateDoc(docRefCountry, {
                     SubDistrictsCount: increment(-1),
                  })
                  console.log('reached 8')
                  setSubDistrictsList(subDistrictsList.filter((item) => item.SubDistrict_id !== id))
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         {(isDataEditor || isSuper) && (
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }} useFlexGap flexWrap="wrap">
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link
                  href="/geo-political-divisions/subDistricts/admin"
                  color="#FFFFFF"
                  onClick={(e) => onClick(e)}
                  className={styles.link2}
               >
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         )}
         {(isDataEditor || isSuper) && (
            <Box
               sx={{
                  border: 1,
                  my: 1,
                  mx: 5,
                  pb: 1,
                  px: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-root': { width: '15ch' },
                  '& .MuiTextField-root': { width: '50ch' },
                  '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     fontWeight: 600,
                     textAlign: 'center',
                     fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                     ],
                  }}
               >
                  List a sub-district
               </Typography>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel id="demo-select-small-label">Country</InputLabel>
                     <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedCountry}
                        label="Country"
                        onChange={handleChangeCountry}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {countries.map((item) => (
                           <MenuItem key={item.id} value={item.id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>State</InputLabel>
                     <Select value={selectedState} label="State" onChange={handleChangeState}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.State_id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {hasDivisions && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Division</InputLabel>
                     <Select value={selectedDivision} label="Division" onChange={handleChangeDivision}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {divisionsList &&
                           divisionsList.map((item) => (
                              <MenuItem key={item.Division_id} value={item.Division_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               )}
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>District</InputLabel>
                     <Select value={selectedDistrict} label="District" onChange={handleChangeDistrict}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {districtsList &&
                           districtsList.map((item) => (
                              <MenuItem key={item.District_id} value={item.District_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {showForm && (
                  <div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleSubDistrictDetails(e)
                           }}
                           value={subDistrictDetails.Name}
                           name="Name"
                           id="name"
                           label="Name"
                           variant="outlined"
                           margin="dense"
                           required
                           fullWidth
                           size="small"
                           sx={{ paddingBottom: 0 }}
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleSubDistrictDetails(e)
                           }}
                           value={subDistrictDetails.NameLocal}
                           name="NameLocal"
                           id="nameLocal"
                           label="Local name"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleSubDistrictDetails(e)
                           }}
                           value={subDistrictDetails.NameHindi}
                           name="NameHindi"
                           id="nameHindi"
                           label="Name in Hindi"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           required
                           fullWidth
                        ></TextField>
                     </div>
                     {/* <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleSubDistrictDetails(e)
                           }}
                           value={subDistrictDetails.NameCode}
                           name="NameCode"
                           id="nameCode"
                           label="Name Code"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div> */}
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleSubDistrictDetails(e)
                           }}
                           value={subDistrictDetails.LGDCode}
                           name="LGDCode"
                           id="lGDCode"
                           label="LGD Code"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>Pesa Status</InputLabel>
                           <Select
                              value={subDistrictDetails.PesaStatus}
                              name="PesaStatus"
                              label="Pesa Status"
                              onChange={(e) => {
                                 handleSubDistrictDetails(e)
                              }}
                           >
                              <MenuItem value="">
                                 <em>-- select --</em>
                              </MenuItem>
                              <MenuItem value="Covered">Covered</MenuItem>
                              <MenuItem value="Partially covered">Partially covered</MenuItem>
                              <MenuItem value="Not Covered">Not Covered</MenuItem>
                           </Select>
                        </FormControl>
                     </div>
                     {/* <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleSubDistrictDetails(e)
                           }}
                           value={subDistrictDetails.Flag}
                           name="Flag"
                           id="flag"
                           label="Flag"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleSubDistrictDetails(e)
                           }}
                           value={subDistrictDetails.Symbol}
                           name="Symbol"
                           id="symbol"
                           label="Symbol"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div> */}
                     <div className={styles.alertDiv}>
                        {uploading && (
                           <MyLoaderCircularGradient title={'Listing the sub-district ...'}></MyLoaderCircularGradient>
                        )}
                        {uploaded && (
                           <Alert variant="outlined" severity="success">
                              Sub-District <strong>{lastSubDistrict}</strong> listed successfully.
                           </Alert>
                        )}
                        {uploadingFailed && (
                           <Alert variant="outlined" severity="error">
                              Error occured! Sub-District <strong>{lastSubDistrict}</strong> could not be listed.
                           </Alert>
                        )}
                     </div>
                     <div>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           size="small"
                           onClick={(e) => {
                              handleAddSubDistrict(e)
                           }}
                           disabled={
                              subDistrictDetails.Name.length < 3 ||
                              subDistrictDetails.NameHindi.length < 3 ||
                              subDistrictDetails.LGDCode.length < 2 ||
                              subDistrictDetails.Country_id.length < 2 ||
                              subDistrictDetails.State_id.length < 2 ||
                              subDistrictDetails.District_id.length < 2
                           }
                        >
                           Submit
                        </Button>
                     </div>
                  </div>
               )}
            </Box>
         )}

         <div className={styles.div200}>
            {(isDataEditor || isSuper) && (
               <div>
                  <Header title="List of sub-districts" />
                  {subDistrictsList &&
                     subDistrictsList.map((item, index) => (
                        <Box
                           key={item.SubDistrict_id}
                           alignItems="center"
                           sx={{
                              border: 1,
                              my: 1,
                              p: 0.5,
                              mx: 5,
                              borderRadius: 2,
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <div>{index + 1}.</div>
                           <div className={styles.state__details}>
                              <p className={styles.state__capital}>{item.SubDistrict_id}</p>
                              <h3 className={styles.state__name}>{item.Name}</h3>
                              <p className={styles.state__capital}>
                                 {item.NameLocal} {item.NameHindi}
                              </p>
                           </div>
                           <div className={item.ForLegis ? styles.statusOk : styles.statusFalse}>
                              {item.ForLegis ? 'Open for operation' : 'Not ready for operation'}
                           </div>
                           <div className={styles.editButtons}>
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={item.ForLegis ? 'error' : 'success'}
                                 size="small"
                                 sx={{ textTransform: 'none', marginBottom: 0.5, px: 1, py: 0, minWidth: 0 }}
                                 onClick={(e) => {
                                    handleOperation(
                                       e,
                                       item.SubDistrict_id,
                                       item.Name,
                                       item.NameLocal,
                                       item.NameHindi,
                                       item.Flag,
                                       item.Symbol,
                                       item.ForLegis,
                                    )
                                 }}
                              >
                                 {item.ForLegis ? 'Stop operation' : 'Start operation'}
                              </Button>
                              {isDataEditor && (
                                 <Button
                                    type="submit"
                                    align="center"
                                    variant="contained"
                                    color={'error'}
                                    size="small"
                                    sx={{ textTransform: 'none', px: 1, py: 0, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleDelete(
                                          e,
                                          item.SubDistrict_id,
                                          item.Name,
                                          item.NameLocal,
                                          item.NameHindi,
                                          item.Flag,
                                          item.Symbol,
                                          item.ForLegis,
                                       )
                                    }}
                                 >
                                    Delete
                                 </Button>
                              )}
                           </div>
                        </Box>
                     ))}
               </div>
            )}
         </div>
      </div>
   )
}

export default SubDistrictsAdmin
