import React from 'react'
import PropTypes from 'prop-types'

function ElecStarCampaigners({ props }) {
   const elec = props
   console.log('value from props inside star campaigner page: ', elec)
   return <div>Elec Star Campaigners</div>
}

ElecStarCampaigners.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecStarCampaigners
