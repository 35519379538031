import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import HomeTabPanelLegis from './HomeTabPanelLegis'
import { useNavigate } from 'react-router-dom'
import HomeTabPanelExecutive from './HomeTabPanelExecutive'
import HomeTabPanelJudiciary from './HomeTabPanelJudiciary'

import MydbOperations from '../MydbOperations/MydbOperations'

import Marquee from 'react-fast-marquee'
import moment from 'moment'

// const Item = styled(Paper)(({ theme }) => ({
//    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//    ...theme.typography.body2,
//    padding: theme.spacing(1),
//    textAlign: 'center',
//    color: theme.palette.text.secondary,
//    height: 70,
// }))

// const sxTypoGraphy = {
//    fontWeight: 600,
//    textAlign: 'center',
//    fontSize: '16px',
//    whiteSpace: 'pre-line',
//    backgroundColor: '#d6d6d6',
//    fontFamily: [
//       '-apple-system',
//       'BlinkMacSystemFont',
//       '"Segoe UI"',
//       'Roboto',
//       '"Helvetica Neue"',
//       'Arial',
//       'sans-serif',
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//    ],
// }

const sxTypoGraphy2 = {
   fontWeight: 500,
   textAlign: 'center',
   fontSize: '14px',
   whiteSpace: 'pre-line',
   backgroundColor: '#d6d6d6',
   borderRadius: 1,
   fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
   ],
}

function Home() {
   const [initialState, setInitialState] = useState('idle')
   const [partiesListNational, setPartiesListNational] = useState('')
   const [electionsList, setElectionsList] = useState('')

   const navigate = useNavigate()

   useEffect(() => {
      if (initialState === 'idle') {
         fetchPartiesListNational()
         fetchElections()
         setInitialState('success')
      }
   }, [])

   // related to countries
   // const countries = useSelector(selectCountries).countriesList
   // const countryId = useSelector(selectCountries).selectedCountryInformation.id

   // related to parties list

   const fetchPartiesListNational = async () => {
      try {
         const docId = 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4'

         let partiesListBase = []
         const docRef = doc(db, 'BaseLists', docId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            partiesListBase = docSnap.data().Parties
            console.log('parties list fetched : ', partiesListBase)
         }

         const partiesListFiltered = partiesListBase.filter((party) => party.ECIStatus === 'National')
         const sortedListParties = partiesListFiltered.sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setPartiesListNational(sortedListParties)

         console.log('parties list fetched by National level: ', sortedListParties)
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchElections = async () => {
      try {
         let electionsListBase = []
         const docRef = doc(db, 'BaseLists', 'Elections-India-000001')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            electionsListBase = docSnap.data().Elections
            console.log('elections list fetched : ', electionsListBase)
         }

         const dateLimit = new Date()
         dateLimit.setMonth(dateLimit.getMonth() - 1)
         console.log('date limit : ', dateLimit)

         const electionsListFiltered = electionsListBase.filter((item) =>
            moment(item.DateStart.toDate()).isAfter(moment(dateLimit)),
         )
         const electionsListFiltered1 = electionsListBase.filter((item) =>
            moment(item.DateStart.toDate()).isAfter(moment(dateLimit)),
         )
         const electionsListFiltered2 = electionsListBase.filter((item) =>
            moment(item.DateStart.toDate()).isBefore(moment(dateLimit)),
         )

         console.log('elections list filtered : ', electionsListFiltered)
         console.log('elections list filtered 1 : ', electionsListFiltered1)
         console.log('elections list filtered 2 : ', electionsListFiltered2)

         const sortedListElections = electionsListFiltered.sort((a, b) => {
            if (moment(b.DateStart.toDate()).isAfter(moment(a.DateStart.toDate()))) {
               return -1
            } else if (moment(b.DateStart.toDate()).isBefore(moment(a.DateStart.toDate()))) {
               return 1
            } else {
               return 0
            }
         })
         setElectionsList(sortedListElections)

         console.log('Elections list sorted: ', sortedListElections)
      } catch (err) {
         console.log('error', err)
      }
   }

   const handleClickCreateParty = (e) => {
      e.preventDefault()

      navigate('/political-parties/India/lsTDGRF8XHWnR3VjVuB4')
   }

   const handleClickParties = (e) => {
      e.preventDefault()

      navigate('/political-parties/India/lsTDGRF8XHWnR3VjVuB4')
   }

   const handleClickStudentUnions = (e) => {
      e.preventDefault()

      navigate('/political-parties/India/lsTDGRF8XHWnR3VjVuB4')
   }

   // const handleClickScams = (e) => {
   //    e.preventDefault()

   //    navigate('/political-parties/India/lsTDGRF8XHWnR3VjVuB4', { replace: true })
   // }

   const handleClickConstitution = (e) => {
      e.preventDefault()

      navigate('/constitution/India/lsTDGRF8XHWnR3VjVuB4')
   }

   return (
      <Box id="mainBoxHome">
         <Grid container sx={{ mt: 1, mx: 1 }} disableEqualOverflow>
            <Grid xs={12}>
               <Marquee pauseOnHover="true">
                  {electionsList &&
                     electionsList.length > 0 &&
                     electionsList.map((item) => (
                        <Link
                           key={item.Election_id}
                           href={`/election/${item.Name}/${item.Election_id}`}
                           sx={{ textDecoration: 'none !important' }}
                        >
                           <Box
                              key={item.Election_id}
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 // backgroundColor: '#dcff9c',
                                 // backgroundColor: '#D0D3D5',
                                 // backgroundColor: '#D5D0D3',
                                 backgroundColor: '#F2F0EB',
                                 mr: 5,
                                 borderRadius: 1,
                                 px: 1,
                                 py: 0.5,
                                 lineHeight: 1,
                              }}
                           >
                              <Typography sx={{ fontSize: 15, lineHeight: 1.1, color: '#000000' }}>
                                 {item.Name}
                              </Typography>
                              <Typography sx={{ fontSize: 14, lineHeight: 1.1, color: '#FF5733 ', fontWeight: 500 }}>
                                 {moment(item.DateStart.toDate()).format('Do MMM YYYY')}

                                 {moment(item.DateStart.toDate()).format('Do MMM YYYY') !==
                                    moment(item.DateFinish.toDate()).format('Do MMM YYYY') && (
                                    <> - {moment(item.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                 )}
                              </Typography>
                              {item.NameLocal ? (
                                 <Typography sx={{ fontSize: 15, lineHeight: 1.1, color: '#000000' }}>
                                    {item.NameLocal}
                                 </Typography>
                              ) : (
                                 <Typography sx={{ fontSize: 15, lineHeight: 1.1, color: '#000000' }}>
                                    {item.NameHindi}
                                 </Typography>
                              )}
                           </Box>
                        </Link>
                     ))}
               </Marquee>
            </Grid>
         </Grid>

         <Grid container sx={{ mt: 1, mx: 0.5 }} disableEqualOverflow>
            <Grid xs={12}>
               <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  {partiesListNational &&
                     partiesListNational.length > 0 &&
                     partiesListNational.map((item) => (
                        <Link
                           key={item.Party_id}
                           href={`/political-party/${item.Name}/${item.Party_id}`}
                           sx={{ textDecoration: 'none !important', px: 0 }}
                        >
                           <Box
                              key={item.Party_id}
                              component="img"
                              src={item.Flag}
                              alt={item.Name}
                              sx={{ height: '50px', maxWidth: 150, borderRadius: 1 }}
                           />
                        </Link>
                     ))}
               </Stack>
            </Grid>
         </Grid>

         <Grid id="home_grid_container_2" container spacing={0.5} sx={{ my: 0.5, mx: 0.5 }} disableEqualOverflow>
            <Grid
               id="home_grid_Container_2_child_1"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 1, sm: 1, md: 1 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Legislative (विधायिका)</Typography>
               </Box>
               <Box>
                  <HomeTabPanelLegis />
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_2"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 2, sm: 4, md: 3 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Executive (कार्यपालिका)</Typography>
               </Box>
               <Box>
                  <HomeTabPanelExecutive />
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_3"
               xs={4}
               sm={4}
               md={2}
               sx={{ alignItems: 'center', justifyContent: 'center', mt: 0.5, borderRadius: 1 }}
               order={{ xs: 3, sm: 2, md: 2 }}
            >
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     color="error"
                     size="small"
                     sx={{
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={(e) => {
                        handleClickConstitution(e)
                     }}
                  >
                     संविधान {'\n'} Constitution
                  </Button>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={(e) => {
                        handleClickParties(e)
                     }}
                  >
                     राजनीतिक दल {'\n'} Political Parties
                  </Button>
               </Box>
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={(e) => {
                        handleClickStudentUnions(e)
                     }}
                  >
                     छात्र संघ {'\n'} Student Unions
                  </Button>
               </Box>
               {/* <Box sx={{ width: '100%', mt: 0.5 }}>
                  <ThemeProvider theme={theme}>
                     <Button
                        variant="contained"
                        size="small"
                        color="ochre"
                        sx={{
                           textTransform: 'none',
                           padding: 1,
                           minWidth: 0,
                           lineHeight: 1.1,
                           width: '100%',
                           fontWeight: 500,
                           fontSize: 12,
                           whiteSpace: 'pre-line',
                        }}
                        onClick={(e) => {
                           handleClickScams(e)
                        }}
                     >
                        घोटाले {'\n'} Scams
                     </Button>
                  </ThemeProvider>
               </Box> */}
               <Box sx={{ width: '100%', mt: 0.5 }}>
                  <Button
                     variant="contained"
                     color="error"
                     size="small"
                     sx={{
                        backgroundColor: '#a41941',
                        textTransform: 'none',
                        padding: 1,
                        minWidth: 0,
                        lineHeight: 1.1,
                        width: '100%',
                        fontWeight: 400,
                        fontSize: 12,
                        whiteSpace: 'pre-line',
                     }}
                     onClick={(e) => {
                        handleClickCreateParty(e)
                     }}
                  >
                     अपना दल बनायें {'\n'} Create Own Party
                  </Button>
               </Box>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_4"
               xs={6}
               sm={4}
               md={2}
               order={{ xs: 4, sm: 3, md: 4 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 2,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Your Constituencies (आपके निर्वाचन क्षेत्र)</Typography>
               </Box>
               <Grid container spacing={1} sx={{ my: 0.5 }} disableEqualOverflow>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Parliamentary (संसदीय):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        State Assembly (राज्य विधायिका):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        District Level (जिला स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Sub-District Level (उप-जिला स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Block Level (ब्लॉक स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#ffb2b2',
                        }}
                     >
                        Base Level (मूल स्तर):
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: 12,
                           px: 1,
                           textAlign: 'left',
                           backgroundColor: '#a5c9ff',
                        }}
                     >
                        Bhagalpur (39)
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid
               id="home_Grid_Container_2_child_5"
               xs={12}
               sm={8}
               md={4}
               order={{ xs: 5, sm: 5, md: 5 }}
               sx={{
                  backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
                  pr: 0.5,
                  mt: 0.5,
                  borderRadius: 1,
               }}
            >
               <Box
                  sx={{
                     backgroundColor: '#d6d6d6',
                     p: 1,
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 25,
                     mb: 1,
                     borderRadius: 1,
                  }}
                  id="boxBBB"
               >
                  <Typography sx={sxTypoGraphy2}>Judiciary (न्यायपालिका)</Typography>
               </Box>
               <Box>
                  <HomeTabPanelJudiciary />
               </Box>
            </Grid>
         </Grid>

         {/* <HomeAccordian /> */}
         <MydbOperations />
      </Box>
   )
}

export default Home
