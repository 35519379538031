import React, { useEffect, useState } from 'react'
import {
   collection,
   addDoc,
   doc,
   updateDoc,
   deleteDoc,
   arrayRemove,
   arrayUnion,
   getDoc,
   increment,
} from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'

import {
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   Link,
   MenuItem,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'

import styles from './GeoDivisions.module.css'
import Header from '../../Components/Header/Header'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../redux/reducers/countries-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { selectUsers } from '../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'

function DivisionsAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [divisionDetails, setDivisionDetails] = useState({
      Name: '',
      NameAlt: '',
      HeadQuarter: '',
      HeadQuarter_alt: '',
      Type: '',
      Flag: null,
      Symbol: null,
      ForLegis: false,
      Country_id: '',
      CountryName: '',
      State_id: '',
      StateName: '',
      Districts: [],
      Legislatures: [],
      VillagesCount: 0,
      BlocksCount: 0,
      SubDistrictsCount: 0,
      DistrictsCount: 0,
      LGDCode: '',
      NameLocal: '',
      NameHindi: '',
      CensusCode: [],
      Map: '',
      NameCode: '',
      LocalLanguageEng: '',
      LocalLanguageHindi: '',
      LocalLanguageLocal: '',
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })
   useEffect(() => {
      console.log('Division details on change of its value: ', divisionDetails)
   }, [divisionDetails])

   const [showForm, setShowForm] = useState(false)

   // related to Countries
   const countries = useSelector(selectCountries).countriesList
   const [selectedCountry, setSelectedCountry] = useState('')

   const handleChangeCountry = (event) => {
      event.preventDefault()
      setShowDenial(false)
      setSelectedState('')

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(event.target.value)
            setShowForm(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedCountry) {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)
         setDivisionDetails({
            ...divisionDetails,
            Country_id: selectedCountry,
            CountryName: selectedCountryComplete.Name,
         })
         fetchStatesList()
      } else {
         setDivisionDetails({ ...divisionDetails, Country_id: '', CountryName: '' })
         setStatesList('')
      }
   }, [selectedCountry])

   // related to States
   const [statesList, setStatesList] = useState('')
   const [showDenial, setShowDenial] = useState(false)
   const [selectedState, setSelectedState] = useState('')
   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }
   const handleChangeState = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedState(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedState) {
         const selectedStateComplete = statesList.find((item) => item.State_id === selectedState)
         setDivisionDetails({ ...divisionDetails, State_id: selectedState, StateName: selectedStateComplete.Name })
         fetchIndividualState()
      } else {
         setDivisionDetails({ ...divisionDetails, State_id: '', StateName: '' })
         setDivisionsList('')
      }
   }, [selectedState])

   // related to Divisions
   const [divisionsList, setDivisionsList] = useState('')

   // fetch divisions and districts through single ut/state's details

   const [individualState, setIndividualState] = useState('')

   const fetchIndividualState = async () => {
      try {
         const docRef = doc(db, 'States', selectedState)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            setIndividualState(docSnap.data())
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document!')
         }
      } catch (err) {
         ///
      }
   }

   useEffect(() => {
      if (individualState) {
         if (individualState.DownLevel === 'Divisions') {
            setShowDenial(false)
            setDivisionsList(individualState.Divisions)
            setShowForm(true)
         } else {
            setShowDenial(true)
            setDivisionsList([])
            setShowForm(false)
         }
      }
   }, [individualState])

   // related to form filling and submission
   function handleDivisionDetails(e) {
      setDivisionDetails({ ...divisionDetails, [e.target.name]: e.target.value })
   }

   const [lastDivision, setLastDivision] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddDivision = async (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               divisionDetails.Name.length >= 3 &&
               divisionDetails.HeadQuarter.length >= 3 &&
               divisionDetails.Country_id.length >= 2 &&
               divisionDetails.State_id.length >= 2
            ) {
               divisionDetails.CrBy = auth.currentUser.uid
               divisionDetails.CrDt = new Date()
               divisionDetails.CrIP = ip.data.ip
               setLastDivision(divisionDetails.Name)
               setDivisionDetails({
                  ...divisionDetails,
                  Name: '',
                  NameAlt: '',
                  HeadQuarter: '',
                  HeadQuarter_alt: '',
                  Type: '',
                  LGDCode: '',
                  Flag: null,
                  Symbol: null,
                  CrBy: '',
                  CrDt: '',
                  CrDtSys: '',
                  CrIP: '',
               })
               setUploading(true)
               try {
                  const docRef = await addDoc(collection(db, 'Divisions'), divisionDetails)
                  divisionDetails.id = docRef.id

                  const newDetailForDivisionList = {
                     Division_id: docRef.id,
                     Name: divisionDetails.Name,
                     NameLocal: divisionDetails.NameLocal,
                     NameHindi: divisionDetails.NameHindi,
                     NameAlt: divisionDetails.NameAlt,
                     HeadQuarter: divisionDetails.HeadQuarter,
                     Flag: divisionDetails.Flag,
                     Symbol: divisionDetails.Symbol,
                     ForLegis: divisionDetails.ForLegis,
                  }

                  const docRefUpdateState = doc(db, 'States', selectedState)
                  await updateDoc(docRefUpdateState, {
                     Divisions: arrayUnion(newDetailForDivisionList),
                     DivisionsCount: increment(1),
                  })

                  const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)
                  await updateDoc(docRefUpdateCountry, {
                     DivisionsCount: increment(1),
                  })

                  setDivisionsList([...divisionsList, newDetailForDivisionList])

                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding Division name')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisNameAlt,
      thisHeadQuarter,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const divisionRef = doc(db, 'Divisions', id)
            try {
               await updateDoc(divisionRef, {
                  ForLegis: !thisForLegis,
               })

               const docRefUpdate = doc(db, 'States', selectedState)

               await updateDoc(docRefUpdate, {
                  Divisions: arrayRemove({
                     Division_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     NameAlt: thisNameAlt,
                     HeadQuarter: thisHeadQuarter,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: thisForLegis,
                  }),
               })

               await updateDoc(docRefUpdate, {
                  Divisions: arrayUnion({
                     Division_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     NameAlt: thisNameAlt,
                     HeadQuarter: thisHeadQuarter,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: !thisForLegis,
                  }),
               })

               setEditStatus(true)
               setForLegis({ id, thisForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         // eslint-disable-next-line array-callback-return
         divisionsList.map((item) => {
            if (item.Division_id === forLegis.id) {
               item.ForLegis = !forLegis.ForLegis
            }
         })
         setEditStatus(false)
      }
   }, [forLegis, editStatus])

   const handleDelete = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisNameAlt,
      thisHeadQuarter,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this division?')) {
               try {
                  await deleteDoc(doc(db, 'Divisions', id))

                  const docRefUpdate = doc(db, 'States', selectedState)
                  await updateDoc(docRefUpdate, {
                     Divisions: arrayRemove({
                        Division_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        NameAlt: thisNameAlt,
                        HeadQuarter: thisHeadQuarter,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        ForLegis: thisForLegis,
                     }),
                     DivisionsCount: increment(-1),
                  })

                  const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)
                  await updateDoc(docRefUpdateCountry, {
                     DivisionsCount: increment(-1),
                  })

                  setDivisionsList(divisionsList.filter((item) => item.Division_id !== id))
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error deleting division')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         {(isDataEditor || isSuper) && (
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }} useFlexGap flexWrap="wrap">
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link
                  href="/geo-political-divisions/divisions/admin"
                  onClick={(e) => onClick(e)}
                  color="#ffffff"
                  className={styles.link2}
               >
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         )}
         {(isDataEditor || isSuper) && (
            <Box
               sx={{
                  border: 1,
                  my: 1,
                  mx: 5,
                  pb: 1,
                  px: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-root': { width: '15ch' },
                  '& .MuiTextField-root': { width: '50ch' },
                  '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     fontWeight: 600,
                     textAlign: 'center',
                     fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                     ],
                  }}
               >
                  List a division
               </Typography>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Country</InputLabel>
                     <Select value={selectedCountry} label="Country" onChange={handleChangeCountry}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {countries.map((item) => (
                           <MenuItem key={item.id} value={item.id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
               <div>
                  <FormControl sx={{ m: 1 }} size="small" fullWidth>
                     <InputLabel>State</InputLabel>
                     <Select value={selectedState} label="State" onChange={handleChangeState}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {showDenial && (
                  <Alert variant="outlined" severity="error">
                     This {individualState.Type} <strong>{individualState.Name}</strong> does not have a system for
                     Divisions.
                  </Alert>
               )}
               {showForm && (
                  <div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDivisionDetails(e)
                           }}
                           value={divisionDetails.Name}
                           name="Name"
                           id="name"
                           label="Name"
                           variant="outlined"
                           margin="dense"
                           required
                           fullWidth
                           size="small"
                           sx={{ paddingBottom: 0 }}
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDivisionDetails(e)
                           }}
                           value={divisionDetails.NameAlt}
                           name="NameAlt"
                           id="nameAlt"
                           label="Other name"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDivisionDetails(e)
                           }}
                           value={divisionDetails.HeadQuarter}
                           name="HeadQuarter"
                           id="headquarter"
                           label="Headquarter"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           required
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDivisionDetails(e)
                           }}
                           value={divisionDetails.HeadQuarter_alt}
                           name="HeadQuarter_alt"
                           id="headquarterAlt"
                           label="Other Headquarter"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDivisionDetails(e)
                           }}
                           value={divisionDetails.LGDCode}
                           name="LGDCode"
                           id="lGDCode"
                           label="LGD Code"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDivisionDetails(e)
                           }}
                           value={divisionDetails.Flag}
                           name="Flag"
                           id="flag"
                           label="Flag"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDivisionDetails(e)
                           }}
                           value={divisionDetails.Symbol}
                           name="Symbol"
                           id="symbol"
                           label="Symbol"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        {uploading && (
                           <MyLoaderCircularGradient
                              title={`Listing the division ${lastDivision} ...`}
                           ></MyLoaderCircularGradient>
                        )}
                        {uploaded && (
                           <Alert variant="outlined" severity="success">
                              Division <strong>{lastDivision}</strong> listed successfully.
                           </Alert>
                        )}
                        {uploadingFailed && (
                           <Alert variant="outlined" severity="error">
                              Error occured! Division <strong>{lastDivision}</strong> could not be listed.
                           </Alert>
                        )}
                     </div>
                     <div>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           size="small"
                           onClick={(e) => {
                              handleAddDivision(e)
                           }}
                           disabled={
                              divisionDetails.Name.length < 3 ||
                              divisionDetails.HeadQuarter.length < 3 ||
                              divisionDetails.Country_id.length < 2 ||
                              divisionDetails.State_id.length < 2
                           }
                        >
                           Submit
                        </Button>
                     </div>
                  </div>
               )}
            </Box>
         )}

         {(isDataEditor || isSuper) && (
            <div>
               <Header title="List of divisions" />
               {divisionsList &&
                  divisionsList.map((item, index) => (
                     <Box
                        key={item.id}
                        alignItems="center"
                        sx={{
                           border: 1,
                           my: 1,
                           p: 0.5,
                           mx: 5,
                           borderRadius: 2,
                           display: 'flex',
                           flexDirection: 'row',
                        }}
                     >
                        <div>{index + 1}.</div>
                        <div className={styles.state__details}>
                           <p className={styles.state__capital}>{item.Division_id}</p>
                           <h3 className={styles.state__name}>{item.Name}</h3>
                           <p className={styles.state__capital}>{item.HeadQuarter}</p>
                           <p className={styles.state__capital}>State / UT: {item.StateName}</p>
                        </div>
                        <div className={item.ForLegis ? styles.statusOk : styles.statusFalse}>
                           {item.ForLegis ? 'Open for operation' : 'Not ready for operation'}
                        </div>
                        <div className={styles.editButtons}>
                           <Button
                              type="submit"
                              align="center"
                              variant="contained"
                              color={item.ForLegis ? 'error' : 'success'}
                              size="small"
                              sx={{ textTransform: 'none', marginBottom: 0.5, px: 1, py: 0, minWidth: 0 }}
                              onClick={(e) => {
                                 handleOperation(
                                    e,
                                    item.Division_id,
                                    item.Name,
                                    item.NameAlt,
                                    item.HeadQuarter,
                                    item.Flag,
                                    item.Symbol,
                                    item.ForLegis,
                                 )
                              }}
                           >
                              {item.ForLegis ? 'Stop operation' : 'Start operation'}
                           </Button>
                           {isDataEditor && (
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={'error'}
                                 size="small"
                                 sx={{ textTransform: 'none', px: 1, py: 0, minWidth: 0 }}
                                 onClick={(e) => {
                                    handleDelete(
                                       e,
                                       item.Division_id,
                                       item.Name,
                                       item.NameAlt,
                                       item.HeadQuarter,
                                       item.Flag,
                                       item.Symbol,
                                       item.ForLegis,
                                    )
                                 }}
                              >
                                 Delete
                              </Button>
                           )}
                        </div>
                     </Box>
                  ))}
            </div>
         )}
      </div>
   )
}

export default DivisionsAdmin
