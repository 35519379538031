import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ElecCandis.module.css'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Button,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Select,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
// import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { v4 } from 'uuid'

import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   deleteDoc,
   doc,
   getDoc,
   increment,
   setDoc,
   updateDoc,
} from 'firebase/firestore'

import AddCandis from './AddCandis'
import moment from 'moment'
import { stringAvatar80, stringAvatar80Single } from '../../../Utils/MUITheme/MUITheme'
import { sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

function ElecCandis({ legis, elec, constituencyId }) {
   console.log('value from props from inside Elec Candis page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('ElecCandis - first use effect entered')

      if (user.currentUser !== null) {
         console.log('ElecCandis - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('ElecCandis - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // style for modal
   const styleModalBox = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: '#ffffff',
      border: '0',
      borderRadius: 1,
      boxShadow: 24,
      pb: 1,
      mx: 1,

      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
         width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
         background: '#f1f1f1',
         borderRadius: 5,
         // margin: 5,
      },
      '&::-webkit-scrollbar-thumb': {
         backgroundColor: '#000000',
         borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb:hover': {
         background: '#555',
      },

      maxHeight: '90%',
      overflowY: 'auto',

      // background: 'linear-gradient(to top, #cfd1a8 0%, #f9fff3 100%)',
   }

   const styleModalTypography = {
      fontSize: '14px',
      color: '#ffffff',
      backgroundImage: 'linear-gradient(to right, #ffffff, #eb9191, #797580, #797580, #eb9191, #ffffff)',
      mt: 1,
      mb: 2,
   }

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [constisList, setConstisList] = useState('')
   const [selectedConsti, setSelectedConsti] = useState('')
   const [selectedConstiName, setSelectedConstiName] = useState('')
   const [selectedConstiNameHindi, setSelectedConstiNameHindi] = useState('')
   const [selectedConstiNameLocal, setSelectedConstiNameLocal] = useState('')
   const [selectedConstiNumber, setSelectedConstiNumber] = useState('')
   const [selectedConstiPhaseNum, setSelectedConstiPhaseNum] = useState('')
   const [selectedConstiPhaseDate, setSelectedConstiPhaseDate] = useState('')

   const [candisNewListRoot, setCandisNewListRoot] = useState('')
   const [candisNewList, setCandisNewList] = useState('')
   const [partiesList, setPartiesList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         console.log('candidtaes core lists: ', elec.CandidatesCoreLists)
         //
         const sortedConstisList = [...elec.Constituencies].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setConstisList(sortedConstisList)

         const candisCoreListFetched = []

         elec.CandidatesCoreLists.forEach(async (item) => {
            const docRefCore = doc(db, 'ElectionCandidates', item)
            const docSnapCore = await getDoc(docRefCore)
            candisCoreListFetched.push(...docSnapCore.data().Candidates)
         })
         setCandisNewListRoot(candisCoreListFetched)
         console.log('Candidates from core list: ', candisCoreListFetched)

         setFirstLoadStatus('success')

         console.log('ElecCandis - first use effect blank')
      }
   }, [])

   useEffect(() => {
      if (constisList && constisList.length > 0) {
         setSelectedConsti(constituencyId)
         console.log('ElecCandis - use effect of constis list')
      }
   }, [constisList])

   const handleChangeConsti = async (e) => {
      e.preventDefault()

      setSelectedConsti(e.target.value)
      console.log('ElecCandis - handle change constis')
   }

   useEffect(() => {
      console.log('ElecCandis - use effect of selected consti - start - out')
      if (selectedConsti && selectedConsti !== '') {
         console.log('ElecCandis - use effect of selected consti - start - in - 1', selectedConsti)
         const consti = elec.Constituencies.find((item) => item.Constituency_id === selectedConsti)

         setSelectedConstiName(consti.Name)
         setSelectedConstiNameHindi(consti.NameHindi)
         setSelectedConstiNameLocal(consti.NameLocal)
         setSelectedConstiNumber(consti.ConstituencyNumber)
         setSelectedConstiPhaseNum(consti.PhaseNum)

         const phase = elec.Phases.find((item) => item.PhaseNum === consti.PhaseNum)
         setSelectedConstiPhaseDate(phase.DateOfPoll)

         console.log('ElecCandis - use effect of selected consti - start - in - 2')

         const list = candisNewListRoot.filter((item) => item.Constituency_id === selectedConsti)
         let sortedList = []
         if (!elec.ResultsDeclared) {
            sortedList = [...list].sort((a, b) => {
               if (b.Name > a.Name) {
                  return -1
               } else if (b.Name < a.Name) {
                  return 1
               } else {
                  return 0
               }
            })
            console.log('ElecCandis - use effect of selected consti - start - in - 3')
         } else {
            sortedList = [...list].sort((a, b) => a.Votes - b.Votes)
         }

         setCandisNewList(sortedList)
         console.log('ElecCandis - use effect of selected consti - end: sortedList: ', sortedList)
      } else {
         setCandisNewList([])
      }
   }, [selectedConsti])

   // related to form filling and submission of constis

   const [openModalAddCandis, setOpenModalAddCandis] = useState(false)

   const handleOpenModalAddCandis = async (e) => {
      e.preventDefault()

      if (partiesList.length > 0) {
         setOpenModalAddCandis(true)
      } else {
         /// fetch parties list
         const docRef = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            const sortedList = [...docSnap.data().Parties].sort((a, b) => {
               if (b.Name > a.Name) {
                  return -1
               } else if (b.Name < a.Name) {
                  return 1
               } else {
                  return 0
               }
            })
            setPartiesList(sortedList)
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document for parties!')
         }
      }
   }

   useEffect(() => {
      if (partiesList && partiesList.length > 0) {
         setOpenModalAddCandis(true)
      }
   }, [partiesList])

   const handleCloseModalAddCandis = () => setOpenModalAddCandis(false)

   const [lastCandi, setLastCandi] = useState('')
   const [uploadingCandis, setUploadingCandis] = useState(false)
   const [uploadedCandis, setUploadedCandis] = useState(false)
   const [uploadingFailedCandis, setUploadingFailedCandis] = useState(false)

   const handleAddCandis = async (candiDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (candiDetails.Name.length > 3 && !isNaN(candiDetails.Age)) {
               setUploadingCandis(true)

               candiDetails.CrBy = auth.currentUser.uid
               candiDetails.CrDt = new Date()
               candiDetails.CrIP = ip.data.ip

               candiDetails.Country_id = legis.Country_id
               candiDetails.CountryName = legis.CountryName
               candiDetails.State_id = legis.State_id
               candiDetails.StateName = legis.StateName
               candiDetails.Division_id = legis.Division_id
               candiDetails.DivisionName = legis.DivisionName
               candiDetails.District_id = legis.District_id
               candiDetails.DistrictName = legis.DistrictName
               candiDetails.SubDistrict_id = legis.SubDistrict_id
               candiDetails.SubDistrictName = legis.SubDistrictName
               candiDetails.Block_id = legis.Block_id
               candiDetails.BlockName = legis.BlockName
               candiDetails.Village_id = legis.Village_id
               candiDetails.VillageName = legis.VillageName

               candiDetails.Name = sentenceCase(candiDetails.Name)

               candiDetails.NameOld = [
                  {
                     Name: candiDetails.Name,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (candiDetails.NameHindi !== '') {
                  candiDetails.NameHindiOld = [
                     { Name: candiDetails.NameHindi, CrBy: auth.currentUser.uid, CrDt: new Date(), CrIP: ip.data.ip },
                  ]
               }
               if (candiDetails.NameLocal !== '') {
                  candiDetails.NameLocalOld = [
                     {
                        Name: candiDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               candiDetails.DocType = 'Candidate'
               candiDetails.Legislature_id = legis.id
               candiDetails.LegislatureName = legis.Name
               candiDetails.LegislatureNameHindi = legis.NameHindi
               candiDetails.LegislatureNameLocal = legis.NameLocal

               candiDetails.Election_id = elec.id
               candiDetails.ElectionName = elec.Name
               candiDetails.ElectionNameHindi = elec.NameHindi
               candiDetails.ElectionNameLocal = elec.NameLocal

               candiDetails.PhaseNum = selectedConstiPhaseNum
               candiDetails.Constituency_id = selectedConsti
               candiDetails.ConstituencyName = selectedConstiName
               candiDetails.ConstituencyNameHindi = selectedConstiNameHindi
               candiDetails.ConstituencyNameLocal = selectedConstiNameLocal
               candiDetails.ConstituencyNumber = selectedConstiNumber

               console.log('candiDetails 1: ', candiDetails)

               setLastCandi(candiDetails.Name)
               console.log('candiDetails 2: ', candiDetails, ' uploading: ', uploadingCandis)

               let coreListId = ''

               console.log('local CandidatesCount before core list name: ', elec.CandidatesCount)

               if (elec.CandidatesCount < 1001) {
                  coreListId = elec.id + '-CoreList-001'
               } else if (elec.CandidatesCount > 1000 && elec.CandidatesCount < 2001) {
                  coreListId = elec.id + '-CoreList-002'
               } else if (elec.CandidatesCount > 2000 && elec.CandidatesCount < 3001) {
                  coreListId = elec.id + '-CoreList-003'
               } else if (elec.CandidatesCount > 3000 && elec.CandidatesCount < 4001) {
                  coreListId = elec.id + '-CoreList-004'
               } else if (elec.CandidatesCount > 4000 && elec.CandidatesCount < 5001) {
                  coreListId = elec.id + '-CoreList-005'
               } else if (elec.CandidatesCount > 5000 && elec.CandidatesCount < 6001) {
                  coreListId = elec.id + '-CoreList-006'
               } else if (elec.CandidatesCount > 6000 && elec.CandidatesCount < 7001) {
                  coreListId = elec.id + '-CoreList-007'
               } else if (elec.CandidatesCount > 7000 && elec.CandidatesCount < 8001) {
                  coreListId = elec.id + '-CoreList-008'
               } else if (elec.CandidatesCount > 8000 && elec.CandidatesCount < 9001) {
                  coreListId = elec.id + '-CoreList-009'
               } else if (elec.CandidatesCount > 9000 && elec.CandidatesCount < 10001) {
                  coreListId = elec.id + '-CoreList-010'
               } else if (elec.CandidatesCount > 10000 && elec.CandidatesCount < 11001) {
                  coreListId = elec.id + '-CoreList-011'
               } else if (elec.CandidatesCount > 11000 && elec.CandidatesCount < 12001) {
                  coreListId = elec.id + '-CoreList-012'
               } else if (elec.CandidatesCount > 12000 && elec.CandidatesCount < 13001) {
                  coreListId = elec.id + '-CoreList-013'
               } else if (elec.CandidatesCount > 13000 && elec.CandidatesCount < 14001) {
                  coreListId = elec.id + '-CoreList-014'
               } else if (elec.CandidatesCount > 14000 && elec.CandidatesCount < 15001) {
                  coreListId = elec.id + '-CoreList-015'
               } else if (elec.CandidatesCount > 15000 && elec.CandidatesCount < 16001) {
                  coreListId = elec.id + '-CoreList-016'
               } else if (elec.CandidatesCount > 16000 && elec.CandidatesCount < 17001) {
                  coreListId = elec.id + '-CoreList-017'
               } else if (elec.CandidatesCount > 17000 && elec.CandidatesCount < 18001) {
                  coreListId = elec.id + '-CoreList-018'
               } else if (elec.CandidatesCount > 18000 && elec.CandidatesCount < 19001) {
                  coreListId = elec.id + '-CoreList-019'
               } else if (elec.CandidatesCount > 19000 && elec.CandidatesCount < 20001) {
                  coreListId = elec.id + '-CoreList-020'
               }

               candiDetails.CoreListId = coreListId

               const record = {
                  Candidate_id: '',
                  Name: candiDetails.Name,
                  NameHindi: candiDetails.NameHindi,
                  NameLocal: candiDetails.NameLocal,
                  PhaseNum: selectedConstiPhaseNum,
                  Constituency_id: selectedConsti,
                  ConstituencyName: selectedConstiName,
                  ConstituencyNameHindi: selectedConstiNameHindi,
                  ConstituencyNameLocal: selectedConstiNameLocal,
                  Age: candiDetails.Age,
                  DoB: candiDetails.DoB,
                  Gender: candiDetails.Gender,
                  Party_id: candiDetails.Party_id,
                  PartyName: candiDetails.PartyName,
                  PartyNameHindi: candiDetails.PartyNameHindi,
                  PartyNameLocal: candiDetails.PartyNameLocal,
                  PartyAbbreviation: candiDetails.PartyAbbreviation,
                  PartyFlag: candiDetails.PartyFlag,
                  PartySymbol: candiDetails.PartySymbol,
                  AllottedSymbol: candiDetails.AllottedSymbol,
                  Alliances: candiDetails.Alliances,
                  Image: '',
                  CoreListId: coreListId,
               }

               try {
                  let recordNew = {}

                  if (!elec.CandidatesCoreLists.includes(coreListId)) {
                     //
                     console.log('core list from local state of election before creation: ', elec.CandidatesCoreLists)

                     const docRef = doc(db, 'ElectionCandidates', coreListId)
                     setDoc(docRef, { Candidates: [], Election_id: elec.id, DocType: 'CoreList' }, { merge: true })

                     console.log('core list created: ', coreListId)
                     elec.CandidatesCoreLists.push(coreListId)
                     console.log('core list from local state of election after creation: ', elec.CandidatesCoreLists)
                     //
                  }
                  if (candiDetails.Image !== '') {
                     const imageRef = ref(dbStorage, `ElectionCandidates/${elec.id}/${candiDetails.Image.name + v4()}`)
                     await uploadBytes(imageRef, candiDetails.Image).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then(async (url) => {
                           candiDetails = {
                              ...candiDetails,
                              Image: url,
                              ImagesOld: [
                                 {
                                    Image: url,
                                    CrBy: auth.currentUser.uid,
                                    CrDt: new Date(),
                                    CrIP: ip.data.ip,
                                 },
                              ],
                           }

                           const docRef = await addDoc(collection(db, 'ElectionCandidates'), candiDetails)
                           candiDetails.id = docRef.id

                           recordNew = { ...record, Candidate_id: docRef.id, Image: url }
                           console.log('record new after iamge upload: ', recordNew)

                           const docRefUpdate = doc(db, 'ElectionCandidates', coreListId)
                           console.log('core list update stage 1')
                           await updateDoc(docRefUpdate, {
                              Candidates: arrayUnion(recordNew),
                           })

                           setCandisNewListRoot([...candisNewListRoot, recordNew])
                           setCandisNewList([...candisNewList, recordNew])

                           setLastCandi(candiDetails.Name)
                        })
                     })
                  } else {
                     const docRef = await addDoc(collection(db, 'ElectionCandidates'), candiDetails)
                     candiDetails.id = docRef.id

                     recordNew = { ...record, Candidate_id: docRef.id }
                     console.log('record new without iamge upload: ', recordNew)

                     const docRefUpdate = doc(db, 'ElectionCandidates', coreListId)
                     console.log('core list update stage 2')
                     await updateDoc(docRefUpdate, {
                        Candidates: arrayUnion(recordNew),
                     })

                     setCandisNewListRoot([...candisNewListRoot, recordNew])
                     setCandisNewList([...candisNewList, recordNew])

                     setLastCandi(candiDetails.Name)
                  }

                  // Note:
                  // increment the CandidatesCount and CandidatesCoreLists in Elections
                  const docRefUpdateElection = doc(db, 'Elections', elec.id)
                  await updateDoc(docRefUpdateElection, {
                     CandidatesCount: increment(1),
                     CandidatesCoreLists: arrayUnion(coreListId),
                  })

                  // Note:
                  // increment the CandidatesCount in local state
                  elec.CandidatesCount = elec.CandidatesCount + 1

                  console.log('local CandidatesCount after increment ', elec.CandidatesCount)

                  setUploadingCandis(false)
                  setUploadedCandis(true)
                  setUploadingFailedCandis(false)
               } catch (error) {
                  alert('Error adding candidate: ', error.message)
                  console.log('Error adding candidate: ', error)
                  setUploadingCandis(false)
                  setUploadedCandis(false)
                  setUploadingFailedCandis(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (
      e,
      thisCandidateId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisPhaseNum,
      thisConstituencyId,
      thisConstituencyName,
      thisConstituencyNameHindi,
      thisConstituencyNameLocal,
      thisAge,
      thisDoB,
      thisGender,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisPartySymbol,
      thisAllottedSymbol,
      thisAlliances,
      thisImage,
      thisCoreListId,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this candidate?')) {
               try {
                  //
                  await deleteDoc(doc(db, 'ElectionCandidates', thisCandidateId))

                  const record = {
                     Candidate_id: thisCandidateId,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     PhaseNum: thisPhaseNum,
                     Constituency_id: thisConstituencyId,
                     ConstituencyName: thisConstituencyName,
                     ConstituencyNameHindi: thisConstituencyNameHindi,
                     ConstituencyNameLocal: thisConstituencyNameLocal,
                     Age: thisAge,
                     DoB: thisDoB,
                     Gender: thisGender,
                     Party_id: thisPartyId,
                     PartyName: thisPartyName,
                     PartyNameHindi: thisPartyNameHindi,
                     PartyNameLocal: thisPartyNameLocal,
                     PartyAbbreviation: thisPartyAbbreviation,
                     PartyFlag: thisPartyFlag,
                     PartySymbol: thisPartySymbol,
                     AllottedSymbol: thisAllottedSymbol,
                     Alliances: thisAlliances,
                     Image: thisImage,
                     CoreListId: thisCoreListId,
                  }
                  console.log('value to remove: ', record)
                  const docRefUpdate = doc(db, 'ElectionCandidates', thisCoreListId)
                  await updateDoc(docRefUpdate, {
                     Candidates: arrayRemove(record),
                  })

                  const docRefUpdateElection = doc(db, 'Elections', elec.id)
                  await updateDoc(docRefUpdateElection, {
                     CandidatesCount: increment(-1),
                  })

                  setCandisNewList(candisNewList.filter((item) => item.Candidate_id !== thisCandidateId))
                  setCandisNewListRoot(candisNewListRoot.filter((item) => item.Candidate_id !== thisCandidateId))
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleEdit = async (id) => {
      console.log('ElecCandis - handle edit')
      //
   }

   function countWordsUsingReplace(str) {
      // Replace multiple spaces, newlines, and tabs with a single space
      const normalizedStr = str.replace(/\s+/g, ' ').trim()
      // Split the string by a single space and count the resulting words
      return normalizedStr === '' ? 0 : normalizedStr.split(' ').length
   }

   return (
      <Box sx={{ px: 1 }}>
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               px: 1,
               mt: 0.5,
               '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '35ch' },
            }}
         >
            <Box sx={{ pt: 1 }}>
               <FormControl sx={{ ml: 1 }} size="small">
                  <InputLabel>Constituency / निर्वाचन क्षेत्र</InputLabel>
                  <Select
                     value={selectedConsti}
                     label="Constituency / निर्वाचन क्षेत्र"
                     onChange={(e) => {
                        handleChangeConsti(e)
                     }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {constisList &&
                        constisList.length > 0 &&
                        constisList.map((item) => (
                           <MenuItem key={item.Constituency_id} value={item.Constituency_id} sx={{ fontSize: 13 }}>
                              {item.Name} ({item.ConstituencyNumber}) - {item.NameHindi}{' '}
                              {item.NameLocal !== '' ? `-${item.NameLocal}` : null}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Box>
         </Box>
         <Box>
            <Box
               sx={{
                  p: 1,
                  mt: 0.5,
                  backgroundImage: 'linear-gradient(to right, #48464c, #605d66, #eb9191, #eb9191, #efa7a7)',
                  color: '#ffffff',
               }}
            >
               {selectedConsti === '' && <Box>Candidates for this election:</Box>}
               {selectedConsti === '' && <Box>इस चुनाव के लिए प्रत्याशी:</Box>}
               {constisList && constisList.length > 0 && selectedConsti !== '' && (
                  <Box>
                     Phase: <strong>{selectedConstiPhaseNum}</strong>
                     <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                     {selectedConstiPhaseDate && moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')}
                  </Box>
               )}
               {constisList && constisList.length > 0 && selectedConsti !== '' && (
                  <Box>
                     Candidates for constituency{' '}
                     <strong>
                        <u>
                           {selectedConstiName} ({selectedConstiNumber})
                        </u>
                     </strong>
                     :
                  </Box>
               )}
               {constisList && constisList.length > 0 && selectedConsti !== '' && (
                  <Box>
                     {selectedConstiNameLocal !== '' && (
                        <>
                           निर्वाचन क्षेत्र{' '}
                           <strong>
                              <u>
                                 {selectedConstiNameLocal} - {selectedConstiNameHindi} ({selectedConstiNumber})
                              </u>
                           </strong>{' '}
                           के लिए प्रत्याशी:
                        </>
                     )}
                     {selectedConstiNameLocal === '' && (
                        <>
                           निर्वाचन क्षेत्र{' '}
                           <strong>
                              <u>
                                 {selectedConstiNameHindi} ({selectedConstiNumber})
                              </u>
                           </strong>{' '}
                           के लिए प्रत्याशी:
                        </>
                     )}
                  </Box>
               )}
            </Box>
            {selectedConsti !== '' && constisList && constisList.length < 1 && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        To be available soon. <br /> शीघ्र उपलब्ध होगा |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}
            {selectedConsti === '' && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        Please select a constituency. <br /> कृपया एक निर्वाचन क्षेत्र चुनें |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}

            {(isSuper || isDataEditor) && constisList && constisList.length > 0 && selectedConsti !== '' && (
               <Box>
                  <Button
                     sx={{ py: 0, px: 1, minWidth: 0 }}
                     onClick={(e) => {
                        handleOpenModalAddCandis(e)
                     }}
                  >
                     Add Candidates
                  </Button>
               </Box>
            )}

            <Box>
               <List dense>
                  {candisNewList &&
                     candisNewList.length > 0 &&
                     candisNewList.map((item, index) => (
                        <div key={item.Candidate_id}>
                           <ListItem
                              sx={{ pl: 0, ml: 0 }}
                              key={item.Candidate_id}
                              secondaryAction={
                                 <Box sx={{ ml: 'auto' }}>
                                    {isSuper && (
                                       <IconButton
                                          edge="end"
                                          aria-label="delete"
                                          onClick={(e) => {
                                             handleDelete(
                                                e,
                                                item.Candidate_id,
                                                item.Name,
                                                item.NameHindi,
                                                item.NameLocal,
                                                item.PhaseNum,
                                                item.Constituency_id,
                                                item.ConstituencyName,
                                                item.ConstituencyNameHindi,
                                                item.ConstituencyNameLocal,
                                                item.Age,
                                                item.DoB,
                                                item.Gender,
                                                item.Party_id,
                                                item.PartyName,
                                                item.PartyNameHindi,
                                                item.PartyNameLocal,
                                                item.PartyAbbreviation,
                                                item.PartyFlag,
                                                item.PartySymbol,
                                                item.AllottedSymbol,
                                                item.Alliances,
                                                item.Image,
                                                item.CoreListId,
                                             )
                                          }}
                                          sx={{ mr: 1, height: '25px', width: '25px' }}
                                       >
                                          <DeleteIcon fontSize="small" />
                                       </IconButton>
                                    )}
                                    {(isDataEditor || isSuper) && (
                                       <IconButton
                                          edge="end"
                                          aria-label="edit"
                                          onClick={() => {
                                             handleEdit(item.id)
                                          }}
                                          sx={{ mr: 1, height: '25px', width: '25px' }}
                                       >
                                          <EditIcon fontSize="small" />
                                       </IconButton>
                                    )}

                                    {/* <IconButton
                                       edge="end"
                                       aria-label="detail"
                                       onClick={() => {
                                          handleDetail(item.id)
                                       }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <InfoSharpIcon fontSize="small" />
                                    </IconButton> */}
                                    {/* <Button
                                       size="small"
                                       endIcon={<PeopleIcon />}
                                       onClick={(e) => {
                                          handleSubmitCandidateLink(e, item.Constituency_id)
                                       }}
                                    >
                                       Candidates
                                    </Button> */}
                                 </Box>
                              }
                           >
                              <Link
                                 href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                 sx={{ textDecoration: 'none !important' }}
                              >
                                 <ListItemAvatar>
                                    {item.Name &&
                                       (countWordsUsingReplace(item.Name) > 1 ? (
                                          <Avatar {...stringAvatar80(item.Name)} alt="Travis Howard" src={item.Image} />
                                       ) : (
                                          <Avatar
                                             {...stringAvatar80Single(item.Name)}
                                             alt="Travis Howard"
                                             src={item.Image}
                                          />
                                       ))}
                                 </ListItemAvatar>
                              </Link>

                              <ListItemText
                                 sx={{ ml: 1 }}
                                 secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                 primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                 primary={
                                    <>
                                       <Link
                                          color="inherit"
                                          underline="hover"
                                          href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                          sx={{ textDecoration: 'none !important' }}
                                       >
                                          <strong>{item.Name.toUpperCase()}</strong>
                                       </Link>{' '}
                                       -{' '}
                                       {item.NameLocal && (
                                          <Link
                                             href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                             className={styles.overrideUnderline}
                                          >
                                             <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '}
                                             {item.NameLocal}
                                          </Link>
                                       )}
                                       {'  '}-{' '}
                                       <Link
                                          underline="none"
                                          href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                          color="warning"
                                       >
                                          {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                                          {item.NameHindi}
                                          {'  '}
                                       </Link>
                                       ({item.Age}y)
                                    </>
                                 }
                                 component={'span'}
                                 secondary={
                                    <>
                                       <>
                                          <Link href={`/political-party/${item.PartyName}/${item.Party_id}`}>
                                             <img src={item.PartyFlag} className={styles.imgX2} />
                                          </Link>
                                          {item.PartySymbol ? (
                                             <img src={item.PartySymbol} className={styles.imgX2} />
                                          ) : (
                                             <img src={item.AllottedSymbol} className={styles.imgX2} />
                                          )}

                                          <br />
                                       </>
                                       <>
                                          {item.PartyName !== 'Independent' && (
                                             <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                             >
                                                <Link
                                                   href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                   underline="none"
                                                >
                                                   {item.PartyName}
                                                </Link>
                                             </Typography>
                                          )}
                                          {item.PartyName === 'Independent' && (
                                             <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                             >
                                                {item.PartyName}
                                             </Typography>
                                          )}
                                       </>
                                    </>
                                 }
                              />
                           </ListItem>
                           <Divider />
                        </div>
                     ))}
               </List>
               {candisNewList && candisNewList.length < 1 && selectedConsti !== '' && (
                  <Box>
                     <Alert
                        variant="outlined"
                        severity="warning"
                        sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                     >
                        <AlertTitle sx={{ fontSize: 12 }}>
                           List of candidates for{' '}
                           <strong>
                              <u>{selectedConstiName}</u>
                           </strong>{' '}
                           to be available soon. <br />{' '}
                           <strong>
                              <u>{selectedConstiNameHindi}</u>
                           </strong>{' '}
                           के लिए प्रत्याशियों की सूची शीघ्र ही उपलब्ध होगी |
                        </AlertTitle>
                     </Alert>
                  </Box>
               )}
            </Box>
            <Box id="boxModals">
               <Modal
                  open={openModalAddCandis}
                  onClose={handleCloseModalAddCandis}
                  aria-labelledby="modal-modal-title-addCandis"
                  aria-describedby="modal-modal-description-addCandis"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addCandis"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add candidates for {selectedConstiName}
                     </Typography>
                     <AddCandis
                        partiesList={partiesList}
                        lastCandi={lastCandi}
                        uploadingCandis={uploadingCandis}
                        uploadedCandis={uploadedCandis}
                        uploadingFailedCandis={uploadingFailedCandis}
                        handleAddCandis={handleAddCandis}
                     ></AddCandis>
                  </Box>
               </Modal>
            </Box>
         </Box>
      </Box>
   )
}

ElecCandis.propTypes = {
   legis: PropTypes.object.isRequired,
   elec: PropTypes.object.isRequired,
   constituencyId: PropTypes.string,
}

export default ElecCandis
