import React from 'react'
import PropTypes from 'prop-types'

function ElecCandiFeed({ props }) {
   const elecCandi = props
   console.log('value from props from inside Elec Candi Feed : ', elecCandi)
   return <div>Elec Candi Feed </div>
}

ElecCandiFeed.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecCandiFeed
