import React, { useEffect, useState } from 'react'
import {
   collection,
   addDoc,
   doc,
   updateDoc,
   deleteDoc,
   arrayUnion,
   getDoc,
   arrayRemove,
   increment,
} from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'

import {
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   Link,
   MenuItem,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'

import styles from './GeoDivisions.module.css'
import Header from '../../Components/Header/Header'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../redux/reducers/countries-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { useNavigate } from 'react-router-dom'
import { selectUsers } from '../../redux/reducers/users-slice'

function VillagesAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [villageDetails, setVillageDetails] = useState({
      LGDCode: '',
      Name: '',
      NameLocal: '',
      NameHindi: '',
      NameAlt: '',
      Type: '',
      Flag: null,
      Symbol: null,
      ForLegis: false,
      BulkArray: '',
      Country_id: '',
      CountryName: '',
      State_id: '',
      StateName: '',
      Division_id: '',
      DivisionName: '',
      District_id: '',
      DistrictName: '',
      SubDistrict_id: '',
      SubDistrictName: '',
      Block_id: '',
      BlockName: '',
      Legislatures: [],
      Census: [],
      Map: '',
      LocalLanguage: '',
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })
   useEffect(() => {
      console.log('Village details on change of its value: ', villageDetails)
   }, [villageDetails])
   const [showForm, setShowForm] = useState(false)
   const [showBulkButton, setShowBulkButton] = useState(false)

   // related to countries
   const countries = useSelector(selectCountries).countriesList
   const [selectedCountry, setSelectedCountry] = useState('')

   const handleChangeCountry = (event) => {
      event.preventDefault()

      setSelectedState('')
      setIndividualState('')
      setSelectedDivision('')
      setSelectedDistrict('')
      setIndividualDistrict('')
      setSelectedSubDistrict('')
      setIndividualSubDistrict('')
      setShowForm(false)
      setShowBulkButton(false)
      setVillagesList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedCountry) {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)
         setVillageDetails({
            ...villageDetails,
            Country_id: selectedCountry,
            CountryName: selectedCountryComplete.Name,
         })
         fetchStatesList()
      } else {
         setVillageDetails({
            ...villageDetails,
            Country_id: '',
            CountryName: '',
         })
         setStatesList([])
      }
   }, [selectedCountry])

   // related to states
   const [statesList, setStatesList] = useState([])
   const [selectedState, setSelectedState] = useState('')
   const [hasDivisions, setHasDivisions] = useState(false)

   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   const [individualState, setIndividualState] = useState('')

   const handleChangeState = async (event) => {
      event.preventDefault()

      setSelectedDivision('')
      setSelectedDistrict('')
      setIndividualDistrict('')
      setSelectedSubDistrict('')
      setIndividualSubDistrict('')
      setShowForm(false)
      setShowBulkButton(false)
      setVillagesList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedState(event.target.value)

            if (event.target.value !== '') {
               const docRef = doc(db, 'States', event.target.value)
               const docSnap = await getDoc(docRef)

               if (docSnap.exists()) {
                  setIndividualState({ id: docSnap.id, ...docSnap.data() })
               } else {
                  // docSnap.data() will be undefined in this case
                  setIndividualState('')
                  console.log('No such document!')
               }
            } else {
               setIndividualState('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedState && individualState) {
         setVillageDetails({ ...villageDetails, State_id: selectedState, StateName: individualState.Name })

         const sortedListDivisions = [...individualState.Divisions].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDivisionsList(sortedListDivisions)

         const sortedListDistricts = [...individualState.Districts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDistrictsListRoot(sortedListDistricts)

         if (individualState.DownLevel === 'Divisions') {
            setHasDivisions(true)
            setDistrictsList([])
         } else {
            setHasDivisions(false)
            setDistrictsList(sortedListDistricts)
         }
      } else {
         setVillageDetails({ ...villageDetails, State_id: '', StateName: '' })
         setDivisionsList([])
         setDistrictsListRoot([])
         setDistrictsList([])
         setHasDivisions(false)
      }
   }, [selectedState, individualState])

   // related to Divisions
   const [divisionsList, setDivisionsList] = useState('')
   const [selectedDivision, setSelectedDivision] = useState('')

   const handleChangeDivision = (event) => {
      event.preventDefault()

      setSelectedDistrict('')
      setIndividualDistrict('')
      setSelectedSubDistrict('')
      setIndividualSubDistrict('')
      setShowForm(false)
      setShowBulkButton(false)
      setVillagesList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDivision(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDivision) {
         const selectedDivisionComplete = divisionsList.find((item) => item.Division_id === selectedDivision)
         setVillageDetails({
            ...villageDetails,
            Division_id: selectedDivision,
            DivisionName: selectedDivisionComplete.Name,
         })
         fetchDistrictsListByDivision()
      } else {
         setVillageDetails({
            ...villageDetails,
            Division_id: '',
            DivisionName: '',
         })
         setDistrictsList([])
      }
   }, [selectedDivision])

   // related to districts
   const [districtsListRoot, setDistrictsListRoot] = useState('')
   const [districtsList, setDistrictsList] = useState('')
   const [selectedDistrict, setSelectedDistrict] = useState('')
   const [individualDistrict, setIndividualDistrict] = useState('')

   const fetchDistrictsListByDivision = async () => {
      setDistrictsList(districtsListRoot.filter((item) => item.Division_id === selectedDivision))
   }

   const handleChangeDistrict = async (event) => {
      event.preventDefault()

      setSelectedSubDistrict('')
      setIndividualSubDistrict('')
      setShowForm(false)
      setShowBulkButton(false)
      setVillagesList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDistrict(event.target.value)

            if (event.target.value !== '') {
               const docRef = doc(db, 'Districts', event.target.value)
               const docSnap = await getDoc(docRef)

               if (docSnap.exists()) {
                  setIndividualDistrict({ id: docSnap.id, ...docSnap.data() })
               } else {
                  // docSnap.data() will be undefined in this case
                  setIndividualDistrict('')
                  console.log('No such document!')
               }
            } else {
               setIndividualDistrict('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDistrict && individualDistrict) {
         setVillageDetails({
            ...villageDetails,
            District_id: selectedDistrict,
            DistrictName: individualDistrict.Name,
         })

         const sortedListSubDistricts = [...individualDistrict.SubDistricts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setSubDistrictsList(sortedListSubDistricts)
      } else {
         setVillageDetails({
            ...villageDetails,
            District_id: '',
            DistrictName: '',
         })
         setSubDistrictsList([])
      }
   }, [selectedDistrict, individualDistrict])

   // related to subDistricts
   const [subDistrictsList, setSubDistrictsList] = useState('')
   const [selectedSubDistrict, setSelectedSubDistrict] = useState('')
   const [individualSubDistrict, setIndividualSubDistrict] = useState('')
   const [villagesList, setVillagesList] = useState([])

   const handleChangeSubDistrict = async (event) => {
      event.preventDefault()

      setShowForm(false)
      setShowBulkButton(false)
      setVillagesList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedSubDistrict(event.target.value)

            if (event.target.value !== '') {
               const docRef = doc(db, 'SubDistricts', event.target.value)
               const docSnap = await getDoc(docRef)

               if (docSnap.exists()) {
                  setIndividualSubDistrict({ id: docSnap.id, ...docSnap.data() })
               } else {
                  // docSnap.data() will be undefined in this case
                  setIndividualSubDistrict('')
                  console.log('No such document!')
               }
            } else {
               setIndividualSubDistrict('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedSubDistrict && individualSubDistrict) {
         setVillageDetails({
            ...villageDetails,
            SubDistrict_id: selectedSubDistrict,
            SubDistrictName: individualSubDistrict.Name,
         })

         const sortedListVillages = [...individualSubDistrict.Villages].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setVillagesList(sortedListVillages)
         setShowBulkButton(true)
      } else {
         setVillageDetails({
            ...villageDetails,
            SubDistrict_id: '',
            SubDistrictName: '',
         })

         setShowBulkButton(false)
      }
   }, [selectedSubDistrict, individualSubDistrict])

   // related to form filling and submission
   function handleVillageDetails(e) {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setVillageDetails({ ...villageDetails, [e.target.name]: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const [lastVillage, setLastVillage] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddVillage = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               villageDetails.Name.length >= 3 &&
               villageDetails.Country_id.length >= 2 &&
               villageDetails.State_id.length >= 2 &&
               villageDetails.District_id.length >= 2 &&
               villageDetails.SubDistrict_id.length >= 2
            ) {
               villageDetails.CrBy = auth.currentUser.uid
               villageDetails.CrDt = new Date()
               villageDetails.CrIP = ip.data.ip
               console.log('villageDetails 1: ', villageDetails)
               setLastVillage(villageDetails.Name)
               setVillageDetails({
                  ...villageDetails,
                  Name: '',
                  NameAlt: '',
                  Type: '',
                  LGDCode: '',
                  Flag: null,
                  Symbol: null,
                  CrBy: '',
                  CrDt: '',
                  CrDtSys: '',
                  CrIP: '',
               })
               setUploading(true)
               try {
                  const docRef = await addDoc(collection(db, 'Villages'), villageDetails)
                  villageDetails.id = docRef.id

                  const newDetailForInsertUpdate = {
                     Village_id: docRef.id,
                     Name: villageDetails.Name,
                     NameLocal: villageDetails.NameLocal,
                     NameHindi: villageDetails.NameHindi,
                     NameAlt: villageDetails.NameAlt,
                     Flag: villageDetails.Flag,
                     Symbol: villageDetails.Symbol,
                     ForLegis: villageDetails.ForLegis,
                  }

                  const docRefUpdateSubDistrict = doc(db, 'SubDistricts', selectedSubDistrict)
                  const docRefUpdateDistrict = doc(db, 'Districts', selectedDistrict)
                  const docRefUpdateState = doc(db, 'States', selectedState)
                  const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)

                  await updateDoc(docRefUpdateSubDistrict, {
                     Villages: arrayUnion(newDetailForInsertUpdate),
                     VillagesCount: increment(1),
                  })

                  await updateDoc(docRefUpdateDistrict, {
                     VillagesCount: increment(1),
                  })

                  if (selectedDivision !== '') {
                     const docRefUpdateDivision = doc(db, 'Divisions', selectedDivision)
                     await updateDoc(docRefUpdateDivision, {
                        VillagesCount: increment(1),
                     })
                  }

                  await updateDoc(docRefUpdateState, {
                     VillagesCount: increment(1),
                  })

                  await updateDoc(docRefUpdateCountry, {
                     VillagesCount: increment(1),
                  })

                  setVillagesList([...villagesList, newDetailForInsertUpdate])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding sub district name')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleAddVillageBulk = async (e) => {
      e.preventDefault()

      console.log('stage 1A')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('stage 1B')
         if (isDataEditor || isSuper) {
            console.log('stage 1C')
            if (
               villageDetails.Country_id.length >= 2 &&
               villageDetails.State_id.length >= 2 &&
               villageDetails.District_id.length >= 2 &&
               villageDetails.SubDistrict_id.length >= 2
            ) {
               console.log('stage 1D')
               villageDetails.CrBy = auth.currentUser.uid
               villageDetails.CrDt = new Date()
               villageDetails.CrIP = ip.data.ip
               console.log('stage 2', villageDetails.BulkArray)

               const newT2 = '[' + villageDetails.BulkArray + ']'
               console.log('stage 2A: ', newT2)
               const data = JSON.parse(newT2)

               console.log('stage 3: ', data)

               data.forEach(async (item) => {
                  villageDetails.LGDCode = item['Village LGD Code']
                  villageDetails.Name = item['Village Name (In English)']
                  let newNameLocal
                  let newNameHindi
                  console.log('stage 4')
                  if (item.NameLocal !== undefined) {
                     newNameLocal = item.NameLocal
                  } else {
                     newNameLocal = ''
                  }
                  if (item.NameHindi !== undefined) {
                     newNameHindi = item.NameHindi
                  } else {
                     newNameHindi = ''
                  }

                  villageDetails.NameLocal = newNameLocal
                  villageDetails.NameHindi = newNameHindi
                  villageDetails.PesaStatus = item['Pesa Status']
                  villageDetails.Census = [
                     {
                        Year: 2001,
                        Code: item['Census 2001 Code'] ? item['Census 2001 Code'] : '',
                     },
                     {
                        Year: 2011,
                        Code: item['Census2011 Code'] ? item['Census2011 Code'] : '',
                     },
                  ]
                  villageDetails.VillageStatus = item['Village Status']
                  console.log('stage 5')
                  setUploading(true)
                  try {
                     console.log('stage 6')
                     const docRef = await addDoc(collection(db, 'Villages'), villageDetails)
                     villageDetails.id = docRef.id
                     console.log('stage 6')
                     console.log('stage 7', docRef.id)
                     const newDetailForInsertUpdate = {
                        Village_id: docRef.id,
                        Name: item['Village Name (In English)'],
                        NameLocal: newNameLocal,
                        NameHindi: newNameHindi,
                        VillageStatus: item['Village Status'],
                        Flag: '',
                        Symbol: '',
                        ForLegis: '',
                     }

                     const docRefUpdateSubDistrict = doc(db, 'SubDistricts', selectedSubDistrict)
                     const docRefUpdateDistrict = doc(db, 'Districts', selectedDistrict)
                     const docRefUpdateState = doc(db, 'States', selectedState)
                     const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)

                     await updateDoc(docRefUpdateSubDistrict, {
                        Villages: arrayUnion(newDetailForInsertUpdate),
                        VillagesCount: increment(1),
                     })

                     await updateDoc(docRefUpdateDistrict, {
                        VillagesCount: increment(1),
                     })

                     console.log('stage 8')

                     if (selectedDivision !== '') {
                        const docRefUpdateDivision = doc(db, 'Divisions', selectedDivision)
                        await updateDoc(docRefUpdateDivision, {
                           VillagesCount: increment(1),
                        })
                     }
                     console.log('stage 9')
                     await updateDoc(docRefUpdateState, {
                        VillagesCount: increment(1),
                     })
                     console.log('stage 10')
                     await updateDoc(docRefUpdateCountry, {
                        VillagesCount: increment(1),
                     })
                     console.log('stage 11')
                     setVillagesList([...villagesList, newDetailForInsertUpdate])

                     setLastVillage(item['Village Name (In English)'])
                     setUploading(false)
                     setUploaded(true)
                     setUploadingFailed(false)

                     setVillageDetails({
                        ...villageDetails,
                        BulkArray: '',
                        CrBy: '',
                        CrDt: '',
                        CrDtSys: '',
                        CrIP: '',
                     })

                     console.log('uploaded: ', item['Village Name (In English)'])
                  } catch (error) {
                     alert('Error adding village name')
                     console.log('Error: ', error.message)
                     setUploading(false)
                     setUploaded(false)
                     setUploadingFailed(false)
                  }
               })
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
         console.log('test stage')
      }
   }

   // Related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisVillageStatus,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const villageRef = doc(db, 'Villages', id)
            try {
               await updateDoc(villageRef, {
                  ForLegis: !thisForLegis,
               })

               const docRefBlock = doc(db, 'SubDistrictss', selectedSubDistrict)

               await updateDoc(docRefBlock, {
                  Villages: arrayRemove({
                     Village_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     VillageStatus: thisVillageStatus,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: thisForLegis,
                  }),
               })

               await updateDoc(docRefBlock, {
                  Villages: arrayUnion({
                     Village_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     VillageStatus: thisVillageStatus,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: !thisForLegis,
                  }),
               })

               setEditStatus(true)
               setForLegis({ id, thisForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         // eslint-disable-next-line array-callback-return
         districtsList.map((item) => {
            if (item.District_id === forLegis.id) {
               item.ForLegis = !forLegis.ForLegis
            }
         })
         setEditStatus(false)
      }
   }, [forLegis, editStatus])

   const handleDelete = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisVillageStatus,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this district?')) {
               try {
                  await deleteDoc(doc(db, 'Villages', id))

                  const docRefSubDistrict = doc(db, 'SubDistricts', selectedSubDistrict)
                  const docRefDistrict = doc(db, 'Districts', selectedDistrict)
                  const docRefState = doc(db, 'States', selectedState)
                  const docRefCountry = doc(db, 'Countries', selectedCountry)

                  await updateDoc(docRefSubDistrict, {
                     Villages: arrayRemove({
                        Village_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        VillageStatus: thisVillageStatus,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        ForLegis: thisForLegis,
                     }),
                     VillagesCount: increment(-1),
                  })

                  await updateDoc(docRefDistrict, {
                     VillagesCount: increment(-1),
                  })

                  if (selectedDivision !== '') {
                     const docRefDivision = doc(db, 'Divisions', selectedDivision)
                     await updateDoc(docRefDivision, {
                        VillagesCount: increment(-1),
                     })
                  }

                  await updateDoc(docRefState, {
                     VillagesCount: increment(-1),
                  })

                  await updateDoc(docRefCountry, {
                     VillagesCount: increment(-1),
                  })

                  setVillagesList(villagesList.filter((item) => item.Village_id !== id))
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         {(isDataEditor || isSuper) && (
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }} useFlexGap flexWrap="wrap">
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link
                  href="/geo-political-divisions/villages/admin"
                  color="#FFFFFF"
                  onClick={(e) => onClick(e)}
                  className={styles.link2}
               >
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         )}
         {(isDataEditor || isSuper) && (
            <Box
               sx={{
                  border: 1,
                  my: 1,
                  mx: 5,
                  pb: 1,
                  px: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-root': { width: '15ch' },
                  '& .MuiTextField-root': { width: '50ch' },
                  '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     fontWeight: 600,
                     textAlign: 'center',
                     fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                     ],
                  }}
               >
                  List a village
               </Typography>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel id="demo-select-small-label">Country</InputLabel>
                     <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedCountry}
                        label="Country"
                        onChange={handleChangeCountry}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {countries.map((item) => (
                           <MenuItem key={item.id} value={item.id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>State</InputLabel>
                     <Select value={selectedState} label="State" onChange={handleChangeState}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.State_id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {hasDivisions && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Division</InputLabel>
                     <Select value={selectedDivision} label="Division" onChange={handleChangeDivision}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {divisionsList &&
                           divisionsList.map((item) => (
                              <MenuItem key={item.Division_id} value={item.Division_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               )}
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>District</InputLabel>
                     <Select value={selectedDistrict} label="District" onChange={handleChangeDistrict}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {districtsList &&
                           districtsList.map((item) => (
                              <MenuItem key={item.District_id} value={item.District_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Sub-District</InputLabel>
                     <Select value={selectedSubDistrict} label="Sub-District" onChange={handleChangeSubDistrict}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {subDistrictsList &&
                           subDistrictsList.map((item) => (
                              <MenuItem key={item.SubDistrict_id} value={item.SubDistrict_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {showForm && (
                  <div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleVillageDetails(e)
                           }}
                           value={villageDetails.Name}
                           name="Name"
                           id="name"
                           label="Name"
                           variant="outlined"
                           margin="dense"
                           required
                           fullWidth
                           size="small"
                           sx={{ paddingBottom: 0 }}
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleVillageDetails(e)
                           }}
                           value={villageDetails.NameAlt}
                           name="NameAlt"
                           id="nameAlt"
                           label="Other name"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleVillageDetails(e)
                           }}
                           value={villageDetails.LGDCode}
                           name="LGDCode"
                           id="lGDCode"
                           label="LGD Code"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleVillageDetails(e)
                           }}
                           value={villageDetails.Flag}
                           name="Flag"
                           id="flag"
                           label="Flag"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleVillageDetails(e)
                           }}
                           value={villageDetails.Symbol}
                           name="Symbol"
                           id="symbol"
                           label="Symbol"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div className={styles.alertDiv}>
                        {uploading && (
                           <MyLoaderCircularGradient title={'Listing the district ...'}></MyLoaderCircularGradient>
                        )}
                        {uploaded && (
                           <Alert variant="outlined" severity="success">
                              District <strong>{lastVillage}</strong> listed successfully.
                           </Alert>
                        )}
                        {uploadingFailed && (
                           <Alert variant="outlined" severity="error">
                              Error occured! District <strong>{lastVillage}</strong> could not be listed.
                           </Alert>
                        )}
                     </div>
                     <div>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           size="small"
                           onClick={(e) => {
                              handleAddVillage(e)
                           }}
                           disabled={
                              villageDetails.Name.length < 3 ||
                              villageDetails.Country_id.length < 2 ||
                              villageDetails.State_id.length < 2 ||
                              villageDetails.District_id.length < 2 ||
                              villageDetails.SubDistrict_id.length < 2
                           }
                        >
                           Submit
                        </Button>
                     </div>
                  </div>
               )}
               {showBulkButton && (
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleVillageDetails(e)
                        }}
                        value={villageDetails.BulkArray}
                        name="BulkArray"
                        id="bulkArray"
                        label="Bulk Array"
                        variant="outlined"
                        margin="dense"
                        required
                        size="small"
                        fullWidth
                        multiline
                        rows={4}
                        sx={{ paddingBottom: 0 }}
                     ></TextField>
                     <Button
                        type="submit"
                        align="center"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                           handleAddVillageBulk(e)
                        }}
                        disabled={
                           villageDetails.Country_id.length < 2 ||
                           villageDetails.State_id.length < 2 ||
                           villageDetails.District_id.length < 2 ||
                           villageDetails.SubDistrict_id.length < 2
                        }
                     >
                        Submit Bulk
                     </Button>
                  </div>
               )}
            </Box>
         )}

         <div className={styles.div200}>
            {(isDataEditor || isSuper) && (
               <div>
                  <Header title="List of villages" />
                  {villagesList &&
                     villagesList.map((item, index) => (
                        <Box
                           key={item.Block_id}
                           alignItems="center"
                           sx={{
                              border: 1,
                              my: 1,
                              p: 0.5,
                              mx: 5,
                              borderRadius: 2,
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <div>{index + 1}.</div>
                           <div className={styles.state__details}>
                              <p className={styles.state__capital}>{item.Village_id}</p>
                              <h3 className={styles.state__name}>{item.Name}</h3>
                           </div>
                           <div className={item.ForLegis ? styles.statusOk : styles.statusFalse}>
                              {item.ForLegis ? 'Open for operation' : 'Not ready for operation'}
                           </div>
                           <div className={styles.editButtons}>
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={item.ForLegis ? 'error' : 'success'}
                                 size="small"
                                 sx={{ textTransform: 'none', marginBottom: 0.5, px: 1, py: 0, minWidth: 0 }}
                                 onClick={(e) => {
                                    handleOperation(
                                       e,
                                       item.Village_id,
                                       item.Name,
                                       item.NameHindi,
                                       item.NameLocal,
                                       item.VillageStatus,
                                       item.Flag,
                                       item.Symbol,
                                       item.ForLegis,
                                    )
                                 }}
                              >
                                 {item.ForLegis ? 'Stop operation' : 'Start operation'}
                              </Button>
                              {isDataEditor && (
                                 <Button
                                    type="submit"
                                    align="center"
                                    variant="contained"
                                    color={'error'}
                                    size="small"
                                    sx={{ textTransform: 'none', px: 1, py: 0, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleDelete(
                                          e,
                                          item.Village_id,
                                          item.Name,
                                          item.NameHindi,
                                          item.NameLocal,
                                          item.VillageStatus,
                                          item.Flag,
                                          item.Symbol,
                                          item.ForLegis,
                                       )
                                    }}
                                 >
                                    Delete
                                 </Button>
                              )}
                           </div>
                        </Box>
                     ))}
               </div>
            )}
         </div>
      </div>
   )
}

export default VillagesAdmin
