import React, { useEffect, useState } from 'react'
import { collection, query, getDocs } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'

import { TextField, Button, Box, Typography } from '@mui/material'

import styles from './LegisAll.module.css'
// import { useSelector } from 'react-redux'
// import { selectUser } from '../../redux/reducers/usersSlice'
import Header from '../../../Components/Header/Header'

function Legislature() {
   // const currentUser = useSelector(selectUser)
   const [legislatureDetails, setLegislatureDetails] = useState({
      name: '',
      nameAlt: '',
      strata: '',
      countryId: '',
      stateId: '',
      districtId: '',
      nature: '',
      isMinistry: '',
      hasHouses: '',
      houseDuration: '',
      singularMem: '',
      electiveSeats: '',
      nominatedSeats: '',
      nominatingAuthorities: '',
      memberDration: '',
      connectedLegisId: '',
      crBy: '',
      crDt: '',
      crIP: '',
      modBy: '',
      modDt: '',
      modIP: '',
   })
   // const [error, setError] = useState('')
   // console.log(currentUser)

   useEffect(() => {
      const fetchLegislatures = async () => {
         const q = query(collection(db, 'Legislatures'))
         const legislatureList = []
         const querySnapshot = await getDocs(q)
         querySnapshot.forEach((doc) => {
            legislatureList.push({ id: doc.id, ...doc.data() })
            // console.log(doc.id, ' => ', doc.data())
         })
         console.log(legislatureList)
      }

      fetchLegislatures()
   }, [])

   function handleLegislatureDetails(e) {
      // console.log(e.target.value)
      setLegislatureDetails({ ...legislatureDetails, [e.target.name]: e.target.value })
   }

   useEffect(() => {
      console.log(legislatureDetails)
   }, [legislatureDetails])

   function handleAddLegislature(e) {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         <Box
            sx={{
               border: 1,
               mb: 1,
               mx: 5,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch' },
               '& .MuiTextField-root': { width: '50ch' },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Typography
               variant="h6"
               sx={{
                  fontWeight: 600,
                  textAlign: 'center',
                  fontFamily: [
                     '-apple-system',
                     'BlinkMacSystemFont',
                     '"Segoe UI"',
                     'Roboto',
                     '"Helvetica Neue"',
                     'Arial',
                     'sans-serif',
                     '"Apple Color Emoji"',
                     '"Segoe UI Emoji"',
                     '"Segoe UI Symbol"',
                  ],
               }}
            >
               List a legislature:
            </Typography>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.name}
                  name="name"
                  id="name"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.nameAlt}
                  name="nameAlt"
                  id="nameAlt"
                  label="Other name"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.strata}
                  name="strata"
                  id="strata"
                  label="Strata"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  required
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.nature}
                  name="nature"
                  id="nature"
                  label="Nature"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.houseDuration}
                  name="houseDuration"
                  id="houseDuration"
                  label="HouseDuration"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.memberDuration}
                  name="memberDuration"
                  id="memberDuration"
                  label="MemberDuration"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.singularMem}
                  name="singularMem"
                  id="singularMem"
                  label="Singular Member"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.hasHouses}
                  name="hasHouses"
                  id="hasHouses"
                  label="Has Houses"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.isMinistry}
                  name="isMinistry"
                  id="isMinistry"
                  label="Is Ministry"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.electiveSeats}
                  name="electiveSeats"
                  id="electiveSeats"
                  label="Elective Seats"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.nominatedSeats}
                  name="nominatedSeats"
                  id="nominatedSeats"
                  label="Nominated Seats"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleLegislatureDetails(e)
                  }}
                  value={legislatureDetails.nominatingAuthorities}
                  name="nominatingAuthorities"
                  id="nominatingAuthorities"
                  label="Nominating Authorities"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleAddLegislature(e)
                  }}
               >
                  Submit
               </Button>
               {/* {error && <div className={styles.error}>{error}</div>} */}
            </div>
         </Box>
         <Header title="List of legislatures" />
      </div>
   )
}

export default Legislature
