import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TextField, Button, Box, Typography, Alert, Stack, Link } from '@mui/material'

import styles from './GeoDivisions.module.css'
// import { useSelector } from 'react-redux'
import Header from '../../Components/Header/Header'
import { addCountry, eraseCountry, selectCountries, toggleForLegis } from '../../redux/reducers/countries-slice'
import { selectUsers } from '../../redux/reducers/users-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { auth, db } from '../../FirebaseConfig'
import { arrayRemove, arrayUnion, doc, increment, updateDoc } from 'firebase/firestore'

function CountriesAdmin() {
   const dispatch = useDispatch()
   const user = useSelector(selectUsers)
   const ip = user.ip
   console.log('ip: ', ip)

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('countries - first use effect entered')

      if (user.currentUser !== null) {
         console.log('countries - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('countries - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [countryDetails, setCountryDetails] = useState({
      Name: '',
      NameAlt: '',
      Capital: '',
      CapitalAlt: '',
      Flag: null,
      Symbol: null,
      DownLevel: 'States',
      ForLegis: false,
      States: [],
      Legislatures: [],
      VillagesCount: 0,
      BlocksCount: 0,
      SubDistrictsCount: 0,
      DistrictsCount: 0,
      DivisionsCount: 0,
      StatesCount: 0,
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })
   // const [countryDetailsFinal, setCountryDetailsFinal] = useState(null)
   const [lastCountry, setLastCountry] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const countries = useSelector(selectCountries).countriesList
   console.log('List of countries: ', countries)

   function handleCountryDetails(e) {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setUploaded(false)
            setUploadingFailed(false)
            setCountryDetails({ ...countryDetails, [e.target.name]: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      console.log(countryDetails)
   }, [countryDetails])

   const handleAddCountry = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            countryDetails.CrBy = auth.currentUser.uid
            countryDetails.CrDt = new Date()
            countryDetails.CrIP = ip.data.ip
            setLastCountry(countryDetails.Name)
            setCountryDetails({
               Name: '',
               NameAlt: '',
               Capital: '',
               CapitalAlt: '',
               Flag: null,
               Symbol: null,
               DownLevel: 'States',
               ForLegis: false,
               States: [],
               Legislatures: [],
               VillagesCount: 0,
               BlocksCount: 0,
               SubDistrictsCount: 0,
               DistrictsCount: 0,
               DivisionsCount: 0,
               StatesCount: 0,
               CrBy: '',
               CrDt: '',
               CrDtSys: '',
               CrIP: '',
            })
            setUploading(true)
            dispatch(addCountry(countryDetails)).then(async (response) => {
               if (response.error) {
                  console.log(response)
                  setUploading(false)
                  setUploadingFailed(true)
               } else {
                  console.log('response raw', response)
                  console.log('response data', response.payload)

                  console.log('response id', response.payload.id)

                  const detailsForInsertUpdate = {
                     Country_id: response.payload.id,
                     Capital: response.payload.Capital,
                     DownLevel: response.payload.DownLevel,
                     Flag: response.payload.Flag,
                     Name: response.payload.Name,
                     Symbol: response.payload.Symbol,
                     ForLegis: response.payload.ForLegis,
                  }
                  const docRefUpdate = doc(db, 'Lists', 'CountriesList')
                  await updateDoc(docRefUpdate, {
                     Countries: arrayUnion(detailsForInsertUpdate),
                     Count: increment(1),
                  })

                  setUploading(false)
                  setUploaded(true)
               }
            })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   function handleToggleForLegis(e, id, ForLegis) {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            dispatch(toggleForLegis({ id, ForLegis }))
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   async function handleDelete(e, id, thisName, thisCapital, thisFlag, thisSymbol, thisForLegis, thisDownLevel) {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are yo sure you want to erase this country?')) {
               dispatch(eraseCountry(id))

               const detailsForDeleteUpdate = {
                  Country_id: id,
                  Name: thisName,
                  Capital: thisCapital,
                  DownLevel: thisDownLevel,
                  Flag: thisFlag,
                  Symbol: thisSymbol,
                  ForLegis: thisForLegis,
               }
               const docRefUpdate = doc(db, 'Lists', 'CountriesList')
               await updateDoc(docRefUpdate, {
                  Countries: arrayRemove(detailsForDeleteUpdate),
                  Count: increment(-1),
               })

               setUploading(false)
               setUploaded(false)
               setUploadingFailed(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         <div>
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }} useFlexGap flexWrap="wrap">
               <Link
                  href="/geo-political-divisions/countries/admin"
                  color="#ffffff"
                  onClick={(e) => onClick(e)}
                  className={styles.link2}
               >
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         </div>
         {(isSuper || isDataEditor) && (
            <Box
               sx={{
                  border: 1,
                  my: 1,
                  mx: 5,
                  pb: 1,
                  px: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-root': { width: '15ch' },
                  '& .MuiTextField-root': { width: '50ch' },
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     fontWeight: 600,
                     textAlign: 'center',
                     fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                     ],
                  }}
               >
                  List a country
               </Typography>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleCountryDetails(e)
                     }}
                     value={countryDetails.Name}
                     name="Name"
                     id="name"
                     label="Name"
                     variant="outlined"
                     margin="dense"
                     required
                     fullWidth
                     size="small"
                     sx={{ paddingBottom: 0 }}
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleCountryDetails(e)
                     }}
                     value={countryDetails.NameAlt}
                     name="NameAlt"
                     id="nameAlt"
                     label="Other name"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleCountryDetails(e)
                     }}
                     value={countryDetails.Capital}
                     name="Capital"
                     id="capital"
                     label="Capital"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     required
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleCountryDetails(e)
                     }}
                     value={countryDetails.CapitalAlt}
                     name="CapitalAlt"
                     id="capitalAlt"
                     label="CapitalAlt"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleCountryDetails(e)
                     }}
                     value={countryDetails.Flag}
                     name="Flag"
                     id="flag"
                     label="Flag"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleCountryDetails(e)
                     }}
                     value={countryDetails.Symbol}
                     name="Symbol"
                     id="symbol"
                     label="Symbol"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>

               {uploading && <MyLoaderCircularGradient title={'Listing the country ...'}></MyLoaderCircularGradient>}
               {uploaded && (
                  <Alert variant="outlined" severity="success">
                     Country <strong>{lastCountry}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailed && (
                  <Alert variant="outlined" severity="error">
                     Error occured! Country <strong>{lastCountry}</strong> could not be listed.
                  </Alert>
               )}
               <div>
                  <Button
                     type="submit"
                     align="center"
                     variant="contained"
                     color="primary"
                     size="small"
                     onClick={(e) => {
                        handleAddCountry(e)
                     }}
                     disabled={countryDetails.Name.length < 3 || countryDetails.Capital.length < 3}
                  >
                     Submit
                  </Button>
                  {/* {error && <div className={styles.error}>{error}</div>} */}
               </div>
            </Box>
         )}

         {(isSuper || isDataEditor) && (
            <div>
               <Header title="List of countries" />
               {countries.map((item) => (
                  <Box
                     key={item.id}
                     alignItems="center"
                     sx={{
                        border: 1,
                        my: 1,
                        p: 0.5,
                        mx: 5,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'row',
                     }}
                  >
                     <Box sx={{ width: '120px' }}>
                        <Box component="img" src={item.Flag} alt={item.Name} sx={{ height: '50px', maxWidth: 150 }} />
                     </Box>
                     <div className={styles.country__details}>
                        <h3 className={styles.country__name}>{item.Name}</h3>
                        <p className={styles.country__capital}>{item.Capital}</p>
                     </div>
                     <div className={item.ForLegis ? styles.statusOk : styles.statusFalse}>
                        {item.ForLegis ? 'Open for operation' : 'Not ready for operation'}
                     </div>
                     <div className={styles.editButtons}>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color={item.ForLegis ? 'error' : 'success'}
                           size="small"
                           sx={{ textTransform: 'none', marginBottom: 0.5, px: 1, py: 0, minWidth: 0 }}
                           onClick={(e) => {
                              handleToggleForLegis(e, item.id, item.ForLegis)
                           }}
                        >
                           {item.ForLegis ? 'Stop operation' : 'Start operation'}
                        </Button>
                        {isSuper && (
                           <Button
                              type="submit"
                              align="center"
                              variant="contained"
                              color={'error'}
                              size="small"
                              sx={{ textTransform: 'none', px: 1, py: 0, minWidth: 0 }}
                              onClick={(e) => {
                                 handleDelete(
                                    e,
                                    item.id,
                                    item.Name,
                                    item.Capital,
                                    item.Flag,
                                    item.Symbol,
                                    item.ForLegis,
                                    item.DownLevel,
                                 )
                              }}
                           >
                              Delete
                           </Button>
                        )}
                     </div>
                  </Box>
               ))}
            </div>
         )}
      </div>
   )
}

export default CountriesAdmin
