import React from 'react'
import { useParams } from 'react-router-dom'

function ElecManifesto() {
   const params = useParams()
   return (
      <div>
         <p>Manifesto id: {params.manifestoId}</p>
         Elec Manifesto
      </div>
   )
}

export default ElecManifesto
