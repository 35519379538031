export const TempDataBlocks = [
   {
      'S No': 1,
      'Development Block LGD Code': 2919,
      'Development Block Name (In English)': 'Alipurduar-I',
      Hierarchy: 'Alipurduar(District) / West Bengal(State)',
   },
   {
      'S No': 2,
      'Development Block LGD Code': 2920,
      'Development Block Name (In English)': 'Alipurduar-Ii',
      Hierarchy: 'Alipurduar(District) / West Bengal(State)',
   },
   {
      'S No': 3,
      'Development Block LGD Code': 2723,
      'Development Block Name (In English)': 'Amdanga',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 4,
      'Development Block LGD Code': 2905,
      'Development Block Name (In English)': 'Amta-I',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 5,
      'Development Block LGD Code': 2906,
      'Development Block Name (In English)': 'Amta-Ii',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 6,
      'Development Block LGD Code': 2887,
      'Development Block Name (In English)': 'Arambagh',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 7,
      'Development Block LGD Code': 3044,
      'Development Block Name (In English)': 'Arsha',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 8,
      'Development Block LGD Code': 2796,
      'Development Block Name (In English)': 'Ausgram-I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 9,
      'Development Block LGD Code': 2797,
      'Development Block Name (In English)': 'Ausgram-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 10,
      'Development Block LGD Code': 2724,
      'Development Block Name (In English)': 'Baduria',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 11,
      'Development Block LGD Code': 2725,
      'Development Block Name (In English)': 'Bagda',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 12,
      'Development Block LGD Code': 3045,
      'Development Block Name (In English)': 'Bagmundi',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 13,
      'Development Block LGD Code': 2907,
      'Development Block Name (In English)': 'Bagnan-I',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 14,
      'Development Block LGD Code': 2908,
      'Development Block Name (In English)': 'Bagnan-Ii',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 15,
      'Development Block LGD Code': 2888,
      'Development Block Name (In English)': 'Balagarh',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 16,
      'Development Block LGD Code': 3046,
      'Development Block Name (In English)': 'Balarampur',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 17,
      'Development Block LGD Code': 2909,
      'Development Block Name (In English)': 'Bally-Jagacha',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 18,
      'Development Block LGD Code': 2870,
      'Development Block Name (In English)': 'Balurghat',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 19,
      'Development Block LGD Code': 2932,
      'Development Block Name (In English)': 'Bamongola',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 20,
      'Development Block LGD Code': 7483,
      'Development Block Name (In English)': 'Banarhat',
      'Development Block Name (In Local language)': 'BANARHAT',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 21,
      'Development Block LGD Code': 2774,
      'Development Block Name (In English)': 'Bankura-I',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 22,
      'Development Block LGD Code': 2775,
      'Development Block Name (In English)': 'Bankura-Ii',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 23,
      'Development Block LGD Code': 2871,
      'Development Block Name (In English)': 'Bansihari',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 24,
      'Development Block LGD Code': 2798,
      'Development Block Name (In English)': 'Barabani',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 25,
      'Development Block LGD Code': 3047,
      'Development Block Name (In English)': 'Barabazar',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 26,
      'Development Block LGD Code': 2726,
      'Development Block Name (In English)': 'Barasat-I',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 27,
      'Development Block LGD Code': 2727,
      'Development Block Name (In English)': 'Barasat-Ii',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 28,
      'Development Block LGD Code': 2776,
      'Development Block Name (In English)': 'Barjora',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 29,
      'Development Block LGD Code': 2728,
      'Development Block Name (In English)': 'Barrackpur-I',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 30,
      'Development Block LGD Code': 2729,
      'Development Block Name (In English)': 'Barrackpur-Ii',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 31,
      'Development Block LGD Code': 2745,
      'Development Block Name (In English)': 'Baruipur',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 32,
      'Development Block LGD Code': 2746,
      'Development Block Name (In English)': 'Basanti',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 33,
      'Development Block LGD Code': 2730,
      'Development Block Name (In English)': 'Basirhat-I',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 34,
      'Development Block LGD Code': 2731,
      'Development Block Name (In English)': 'Basirhat-Ii',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 35,
      'Development Block LGD Code': 3001,
      'Development Block Name (In English)': 'Beldanga-I',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 36,
      'Development Block LGD Code': 3002,
      'Development Block Name (In English)': 'Beldanga-Ii',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 37,
      'Development Block LGD Code': 3003,
      'Development Block Name (In English)': 'Berhampore',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 38,
      'Development Block LGD Code': 3004,
      'Development Block Name (In English)': 'Bhagabangola-Ii',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 39,
      'Development Block LGD Code': 3005,
      'Development Block Name (In English)': 'Bhagawangola-I',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 40,
      'Development Block LGD Code': 2947,
      'Development Block Name (In English)': 'Bhagawanpur-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 41,
      'Development Block LGD Code': 2948,
      'Development Block Name (In English)': 'Bhagawanpur-Ii',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 42,
      'Development Block LGD Code': 2747,
      'Development Block Name (In English)': 'Bhangar-I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 43,
      'Development Block LGD Code': 2748,
      'Development Block Name (In English)': 'Bhangar-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 44,
      'Development Block LGD Code': 3006,
      'Development Block Name (In English)': 'Bharatpur-I',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 45,
      'Development Block LGD Code': 3007,
      'Development Block Name (In English)': 'Bharatpur-Ii',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 46,
      'Development Block LGD Code': 2799,
      'Development Block Name (In English)': 'Bhatar',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 47,
      'Development Block LGD Code': 2972,
      'Development Block Name (In English)': 'Binpur-I',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 48,
      'Development Block LGD Code': 2973,
      'Development Block Name (In English)': 'Binpur-Ii',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 49,
      'Development Block LGD Code': 2749,
      'Development Block Name (In English)': 'Bishnupur-I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 50,
      'Development Block LGD Code': 2750,
      'Development Block Name (In English)': 'Bishnupur-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 51,
      'Development Block LGD Code': 2827,
      'Development Block Name (In English)': 'Bolpur-Sriniketan',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 52,
      'Development Block LGD Code': 2732,
      'Development Block Name (In English)': 'Bongaon',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 53,
      'Development Block LGD Code': 2751,
      'Development Block Name (In English)': 'Budge Budge-I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 54,
      'Development Block LGD Code': 2752,
      'Development Block Name (In English)': 'Budge Budge-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 55,
      'Development Block LGD Code': 3048,
      'Development Block Name (In English)': 'Bundwan',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 56,
      'Development Block LGD Code': 2800,
      'Development Block Name (In English)': 'Burdwan-I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 57,
      'Development Block LGD Code': 2801,
      'Development Block Name (In English)': 'Burdwan-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 58,
      'Development Block LGD Code': 3008,
      'Development Block Name (In English)': 'Burwan',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 59,
      'Development Block LGD Code': 2753,
      'Development Block Name (In English)': 'Canning-I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 60,
      'Development Block LGD Code': 2754,
      'Development Block Name (In English)': 'Canning-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 61,
      'Development Block LGD Code': 3027,
      'Development Block Name (In English)': 'Chakdah',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 62,
      'Development Block LGD Code': 2933,
      'Development Block Name (In English)': 'Chanchal-I',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 63,
      'Development Block LGD Code': 2934,
      'Development Block Name (In English)': 'Chanchal-Ii',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 64,
      'Development Block LGD Code': 2949,
      'Development Block Name (In English)': 'Chandipur',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 65,
      'Development Block LGD Code': 2889,
      'Development Block Name (In English)': 'Chanditala-I',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 66,
      'Development Block LGD Code': 2890,
      'Development Block Name (In English)': 'Chanditala-Ii',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 67,
      'Development Block LGD Code': 2974,
      'Development Block Name (In English)': 'Chandrakona-I',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 68,
      'Development Block LGD Code': 2975,
      'Development Block Name (In English)': 'Chandrakona-Ii',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 69,
      'Development Block LGD Code': 3028,
      'Development Block Name (In English)': 'Chapra',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 70,
      'Development Block LGD Code': 2777,
      'Development Block Name (In English)': 'Chhatna',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 71,
      'Development Block LGD Code': 2891,
      'Development Block Name (In English)': 'Chinsurah-Magrah',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 72,
      'Development Block LGD Code': 2878,
      'Development Block Name (In English)': 'Chopra',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 73,
      'Development Block LGD Code': 2950,
      'Development Block Name (In English)': 'Contai-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 74,
      'Development Block LGD Code': 2951,
      'Development Block Name (In English)': 'Contai-Iii',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 75,
      'Development Block LGD Code': 2847,
      'Development Block Name (In English)': 'Coochbehar I',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 76,
      'Development Block LGD Code': 2846,
      'Development Block Name (In English)': 'Cooch Behar II',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 77,
      'Development Block LGD Code': 2976,
      'Development Block Name (In English)': 'Dantan-I',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 78,
      'Development Block LGD Code': 2977,
      'Development Block Name (In English)': 'Dantan-Ii',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 79,
      'Development Block LGD Code': 2858,
      'Development Block Name (In English)': 'Darjeeling-Pulbazar',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 80,
      'Development Block LGD Code': 2978,
      'Development Block Name (In English)': 'Daspur-I',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 81,
      'Development Block LGD Code': 2979,
      'Development Block Name (In English)': 'Daspur-Ii',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 82,
      'Development Block LGD Code': 2980,
      'Development Block Name (In English)': 'Debra',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 83,
      'Development Block LGD Code': 2733,
      'Development Block Name (In English)': 'Deganga',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 84,
      'Development Block LGD Code': 2952,
      'Development Block Name (In English)': 'Deshapran',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 85,
      'Development Block LGD Code': 2892,
      'Development Block Name (In English)': 'Dhaniakhali',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 86,
      'Development Block LGD Code': 2921,
      'Development Block Name (In English)': 'Dhupguri',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 87,
      'Development Block LGD Code': 2755,
      'Development Block Name (In English)': 'Diamond Harbour-I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 88,
      'Development Block LGD Code': 2756,
      'Development Block Name (In English)': 'Diamond Harbour-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 89,
      'Development Block LGD Code': 2848,
      'Development Block Name (In English)': 'Dinhata-I',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 90,
      'Development Block LGD Code': 2849,
      'Development Block Name (In English)': 'Dinhata-Ii',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 91,
      'Development Block LGD Code': 2910,
      'Development Block Name (In English)': 'Domjur',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 92,
      'Development Block LGD Code': 3009,
      'Development Block Name (In English)': 'Domkal',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 93,
      'Development Block LGD Code': 2828,
      'Development Block Name (In English)': 'Dubrajpur',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 94,
      'Development Block LGD Code': 2953,
      'Development Block Name (In English)': 'Egra-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 95,
      'Development Block LGD Code': 2954,
      'Development Block Name (In English)': 'Egra-Ii',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 96,
      'Development Block LGD Code': 2935,
      'Development Block Name (In English)': 'English Bazar',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 97,
      'Development Block LGD Code': 2922,
      'Development Block Name (In English)': 'Falakata',
      Hierarchy: 'Alipurduar(District) / West Bengal(State)',
   },
   {
      'S No': 98,
      'Development Block LGD Code': 2757,
      'Development Block Name (In English)': 'Falta',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 99,
      'Development Block LGD Code': 3010,
      'Development Block Name (In English)': 'Farakka',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 100,
      'Development Block LGD Code': 2802,
      'Development Block Name (In English)': 'Faridpur - Durgapur',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 101,
      'Development Block LGD Code': 2734,
      'Development Block Name (In English)': 'Gaighata',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 102,
      'Development Block LGD Code': 2803,
      'Development Block Name (In English)': 'Galsi -I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 103,
      'Development Block LGD Code': 2804,
      'Development Block Name (In English)': 'Galsi-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 104,
      'Development Block LGD Code': 2778,
      'Development Block Name (In English)': 'Gangajal Ghati',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 105,
      'Development Block LGD Code': 2872,
      'Development Block Name (In English)': 'Gangarampur',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 106,
      'Development Block LGD Code': 2981,
      'Development Block Name (In English)': 'Garbeta-I',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 107,
      'Development Block LGD Code': 2982,
      'Development Block Name (In English)': 'Garbeta-Ii',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 108,
      'Development Block LGD Code': 2983,
      'Development Block Name (In English)': 'Garbeta-Iii',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 109,
      'Development Block LGD Code': 2936,
      'Development Block Name (In English)': 'Gazole',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 110,
      'Development Block LGD Code': 2984,
      'Development Block Name (In English)': 'Ghatal',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 111,
      'Development Block LGD Code': 2880,
      'Development Block Name (In English)': 'Goalpokhar-I',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 112,
      'Development Block LGD Code': 2879,
      'Development Block Name (In English)': 'Goalpokhar II',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 113,
      'Development Block LGD Code': 2893,
      'Development Block Name (In English)': 'Goghat-I',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 114,
      'Development Block LGD Code': 2894,
      'Development Block Name (In English)': 'Goghat-Ii',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 115,
      'Development Block LGD Code': 2986,
      'Development Block Name (In English)': 'Gopiballavpur-I',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 116,
      'Development Block LGD Code': 2985,
      'Development Block Name (In English)': 'Gopiballav Pur -Ii',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 117,
      'Development Block LGD Code': 2859,
      'Development Block Name (In English)': 'Gorubathan',
      Hierarchy: 'Kalimpong(District) / West Bengal(State)',
   },
   {
      'S No': 118,
      'Development Block LGD Code': 2758,
      'Development Block Name (In English)': 'Gosaba',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 119,
      'Development Block LGD Code': 2937,
      'Development Block Name (In English)': 'Habibpur',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 120,
      'Development Block LGD Code': 2735,
      'Development Block Name (In English)': 'Habra-I',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 121,
      'Development Block LGD Code': 2736,
      'Development Block Name (In English)': 'Habra-Ii',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 122,
      'Development Block LGD Code': 2955,
      'Development Block Name (In English)': 'Haldia',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 123,
      'Development Block LGD Code': 2850,
      'Development Block Name (In English)': 'Haldibari',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 124,
      'Development Block LGD Code': 3029,
      'Development Block Name (In English)': 'Hanskhali',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 125,
      'Development Block LGD Code': 3011,
      'Development Block Name (In English)': 'Hariharpara',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 126,
      'Development Block LGD Code': 3030,
      'Development Block Name (In English)': 'Haringhata',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 127,
      'Development Block LGD Code': 2895,
      'Development Block Name (In English)': 'Haripal',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 128,
      'Development Block LGD Code': 2873,
      'Development Block Name (In English)': 'Harirampur',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 129,
      'Development Block LGD Code': 2938,
      'Development Block Name (In English)': 'Harishchandrapur-I',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 130,
      'Development Block LGD Code': 2939,
      'Development Block Name (In English)': 'Harishchandrapur-Ii',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 131,
      'Development Block LGD Code': 2737,
      'Development Block Name (In English)': 'Haroa',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 132,
      'Development Block LGD Code': 2738,
      'Development Block Name (In English)': 'Hasnabad',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 133,
      'Development Block LGD Code': 2881,
      'Development Block Name (In English)': 'Hemtabad',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 134,
      'Development Block LGD Code': 2874,
      'Development Block Name (In English)': 'Hili',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 135,
      'Development Block LGD Code': 2739,
      'Development Block Name (In English)': 'Hingalganj',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 136,
      'Development Block LGD Code': 2779,
      'Development Block Name (In English)': 'Hirbandh',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 137,
      'Development Block LGD Code': 3049,
      'Development Block Name (In English)': 'Hura',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 138,
      'Development Block LGD Code': 2829,
      'Development Block Name (In English)': 'Illambazar',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 139,
      'Development Block LGD Code': 2780,
      'Development Block Name (In English)': 'Indpur',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 140,
      'Development Block LGD Code': 2781,
      'Development Block Name (In English)': 'Indus',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 141,
      'Development Block LGD Code': 2882,
      'Development Block Name (In English)': 'Islampur',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 142,
      'Development Block LGD Code': 2883,
      'Development Block Name (In English)': 'Itahar',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 143,
      'Development Block LGD Code': 2911,
      'Development Block Name (In English)': 'Jagatballavpur',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 144,
      'Development Block LGD Code': 3050,
      'Development Block Name (In English)': 'Jaipur',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 145,
      'Development Block LGD Code': 3012,
      'Development Block Name (In English)': 'Jalangi',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 146,
      'Development Block LGD Code': 2923,
      'Development Block Name (In English)': 'Jalpaiguri',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 147,
      'Development Block LGD Code': 2805,
      'Development Block Name (In English)': 'Jamal Pur',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 148,
      'Development Block LGD Code': 2987,
      'Development Block Name (In English)': 'Jambani',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 149,
      'Development Block LGD Code': 2806,
      'Development Block Name (In English)': 'Jamuria',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 150,
      'Development Block LGD Code': 2896,
      'Development Block Name (In English)': 'Jangipara',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 151,
      'Development Block LGD Code': 2759,
      'Development Block Name (In English)': 'Jaynagar-I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 152,
      'Development Block LGD Code': 2760,
      'Development Block Name (In English)': 'Jaynagar-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 153,
      'Development Block LGD Code': 2782,
      'Development Block Name (In English)': 'Jaypur',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 154,
      'Development Block LGD Code': 3051,
      'Development Block Name (In English)': 'Jhalda-I',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 155,
      'Development Block LGD Code': 3052,
      'Development Block Name (In English)': 'Jhalda-Ii',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 156,
      'Development Block LGD Code': 2988,
      'Development Block Name (In English)': 'Jhargram',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 157,
      'Development Block LGD Code': 2860,
      'Development Block Name (In English)': 'Jore Bunglow-Sukiapokhri',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 158,
      'Development Block LGD Code': 2761,
      'Development Block Name (In English)': 'Kak Dwip',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 159,
      'Development Block LGD Code': 2924,
      'Development Block Name (In English)': 'Kalchini',
      Hierarchy: 'Alipurduar(District) / West Bengal(State)',
   },
   {
      'S No': 160,
      'Development Block LGD Code': 2940,
      'Development Block Name (In English)': 'Kaliachak-I',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 161,
      'Development Block LGD Code': 2941,
      'Development Block Name (In English)': 'Kaliachak-Ii',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 162,
      'Development Block LGD Code': 2942,
      'Development Block Name (In English)': 'Kaliachak-Iii',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 163,
      'Development Block LGD Code': 2884,
      'Development Block Name (In English)': 'Kaliaganj',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 164,
      'Development Block LGD Code': 3031,
      'Development Block Name (In English)': 'Kaliganj',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 165,
      'Development Block LGD Code': 2861,
      'Development Block Name (In English)': 'Kalimpong-I',
      Hierarchy: 'Kalimpong(District) / West Bengal(State)',
   },
   {
      'S No': 166,
      'Development Block LGD Code': 2808,
      'Development Block Name (In English)': 'Kalna-I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 167,
      'Development Block LGD Code': 2807,
      'Development Block Name (In English)': 'Kalna II',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 168,
      'Development Block LGD Code': 7115,
      'Development Block Name (In English)': 'Kalyani',
      'Development Block Name (In Local language)': 'KALYANI',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 169,
      'Development Block LGD Code': 3013,
      'Development Block Name (In English)': 'Kandi',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 170,
      'Development Block LGD Code': 2809,
      'Development Block Name (In English)': 'Kanksa',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 171,
      'Development Block LGD Code': 2885,
      'Development Block Name (In English)': 'Karandighi',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 172,
      'Development Block LGD Code': 3032,
      'Development Block Name (In English)': 'Karimpur-1',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 173,
      'Development Block LGD Code': 3033,
      'Development Block Name (In English)': 'Karimpur-Ii',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 174,
      'Development Block LGD Code': 3053,
      'Development Block Name (In English)': 'Kashipur',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 175,
      'Development Block LGD Code': 2810,
      'Development Block Name (In English)': 'Katwa-I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 176,
      'Development Block LGD Code': 2811,
      'Development Block Name (In English)': 'Katwa-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 177,
      'Development Block LGD Code': 2989,
      'Development Block Name (In English)': 'Keshiary',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 178,
      'Development Block LGD Code': 2990,
      'Development Block Name (In English)': 'Keshpur',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 179,
      'Development Block LGD Code': 2813,
      'Development Block Name (In English)': 'Ketugram_I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 180,
      'Development Block LGD Code': 2812,
      'Development Block Name (In English)': 'Ketugram-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 181,
      'Development Block LGD Code': 2897,
      'Development Block Name (In English)': 'Khanakul-I',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 182,
      'Development Block LGD Code': 2898,
      'Development Block Name (In English)': 'Khanakul-Ii',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 183,
      'Development Block LGD Code': 2814,
      'Development Block Name (In English)': 'Khandaghosh',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 184,
      'Development Block LGD Code': 2991,
      'Development Block Name (In English)': 'Kharagpur-I',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 185,
      'Development Block LGD Code': 2992,
      'Development Block Name (In English)': 'Kharagpur-Ii',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 186,
      'Development Block LGD Code': 3014,
      'Development Block Name (In English)': 'Khargram',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 187,
      'Development Block LGD Code': 2863,
      'Development Block Name (In English)': 'Kharibari',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 188,
      'Development Block LGD Code': 2783,
      'Development Block Name (In English)': 'Khatra-I',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 189,
      'Development Block LGD Code': 2956,
      'Development Block Name (In English)': 'Khejuri-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 190,
      'Development Block LGD Code': 2957,
      'Development Block Name (In English)': 'Khejuri-Ii',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 191,
      'Development Block LGD Code': 2830,
      'Development Block Name (In English)': 'Khoyrasol',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 192,
      'Development Block LGD Code': 2958,
      'Development Block Name (In English)': 'Kolaghat',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 193,
      'Development Block LGD Code': 2784,
      'Development Block Name (In English)': 'Kotulpur',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 194,
      'Development Block LGD Code': 7484,
      'Development Block Name (In English)': 'Kranti',
      'Development Block Name (In Local language)': 'KRANTI',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 195,
      'Development Block LGD Code': 3034,
      'Development Block Name (In English)': 'Krishnaganj',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 196,
      'Development Block LGD Code': 3035,
      'Development Block Name (In English)': 'Krishnagar-I',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 197,
      'Development Block LGD Code': 3036,
      'Development Block Name (In English)': 'Krishnagar-Ii',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 198,
      'Development Block LGD Code': 2762,
      'Development Block Name (In English)': 'Kulpi',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 199,
      'Development Block LGD Code': 2763,
      'Development Block Name (In English)': 'Kultali',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 200,
      'Development Block LGD Code': 2875,
      'Development Block Name (In English)': 'Kumarganj',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 201,
      'Development Block LGD Code': 2925,
      'Development Block Name (In English)': 'Kumargram',
      Hierarchy: 'Alipurduar(District) / West Bengal(State)',
   },
   {
      'S No': 202,
      'Development Block LGD Code': 2864,
      'Development Block Name (In English)': 'Kurseong',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 203,
      'Development Block LGD Code': 2876,
      'Development Block Name (In English)': 'Kushmandi',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 204,
      'Development Block LGD Code': 2831,
      'Development Block Name (In English)': 'Labpur',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 205,
      'Development Block LGD Code': 3015,
      'Development Block Name (In English)': 'Lalgola',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 206,
      'Development Block LGD Code': 2862,
      'Development Block Name (In English)': 'Lava',
      Hierarchy: 'Kalimpong(District) / West Bengal(State)',
   },
   {
      'S No': 207,
      'Development Block LGD Code': 2926,
      'Development Block Name (In English)': 'Madarihat',
      Hierarchy: 'Alipurduar(District) / West Bengal(State)',
   },
   {
      'S No': 208,
      'Development Block LGD Code': 2764,
      'Development Block Name (In English)': 'Magra Hat-I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 209,
      'Development Block LGD Code': 2765,
      'Development Block Name (In English)': 'Magra Hat-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 210,
      'Development Block LGD Code': 2959,
      'Development Block Name (In English)': 'Mahishadal',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 211,
      'Development Block LGD Code': 2927,
      'Development Block Name (In English)': 'Mal',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 212,
      'Development Block LGD Code': 3054,
      'Development Block Name (In English)': 'Manbazar-I',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 213,
      'Development Block LGD Code': 3055,
      'Development Block Name (In English)': 'Manbazar-Ii',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 214,
      'Development Block LGD Code': 2766,
      'Development Block Name (In English)': 'Mandirbazar',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 215,
      'Development Block LGD Code': 2815,
      'Development Block Name (In English)': 'Mangolkote',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 216,
      'Development Block LGD Code': 2943,
      'Development Block Name (In English)': 'Manikchak',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 217,
      'Development Block LGD Code': 2816,
      'Development Block Name (In English)': 'Manteswar',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 218,
      'Development Block LGD Code': 2852,
      'Development Block Name (In English)': 'Mathabhanga-I',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 219,
      'Development Block LGD Code': 2851,
      'Development Block Name (In English)': 'Mathabhanga II',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 220,
      'Development Block LGD Code': 2767,
      'Development Block Name (In English)': 'Mathurapur I',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 221,
      'Development Block LGD Code': 2768,
      'Development Block Name (In English)': 'Mathurapur-Ii',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 222,
      'Development Block LGD Code': 2928,
      'Development Block Name (In English)': 'Matiali',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 223,
      'Development Block LGD Code': 2865,
      'Development Block Name (In English)': 'Matigara',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 224,
      'Development Block LGD Code': 2929,
      'Development Block Name (In English)': 'Maynaguri',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 225,
      'Development Block LGD Code': 2832,
      'Development Block Name (In English)': 'Mayureswar-I',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 226,
      'Development Block LGD Code': 2833,
      'Development Block Name (In English)': 'Mayureswar-Ii',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 227,
      'Development Block LGD Code': 2785,
      'Development Block Name (In English)': 'Mejhia',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 228,
      'Development Block LGD Code': 2853,
      'Development Block Name (In English)': 'Mekliganj',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 229,
      'Development Block LGD Code': 2817,
      'Development Block Name (In English)': 'Memari-1',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 230,
      'Development Block LGD Code': 2818,
      'Development Block Name (In English)': 'Memari-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 231,
      'Development Block LGD Code': 2993,
      'Development Block Name (In English)': 'Midnapore',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 232,
      'Development Block LGD Code': 2740,
      'Development Block Name (In English)': 'Minakhan',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 233,
      'Development Block LGD Code': 2866,
      'Development Block Name (In English)': 'Mirik',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 234,
      'Development Block LGD Code': 2834,
      'Development Block Name (In English)': 'Mohammad Bazar',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 235,
      'Development Block LGD Code': 2994,
      'Development Block Name (In English)': 'Mohanpur',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 236,
      'Development Block LGD Code': 2960,
      'Development Block Name (In English)': 'Moyna',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 237,
      'Development Block LGD Code': 2835,
      'Development Block Name (In English)': 'Murarai-I',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 238,
      'Development Block LGD Code': 2836,
      'Development Block Name (In English)': 'Murarai-Ii',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 239,
      'Development Block LGD Code': 3016,
      'Development Block Name (In English)': 'Murshidabad-Jiagunj',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 240,
      'Development Block LGD Code': 3037,
      'Development Block Name (In English)': 'Nabadwip',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 241,
      'Development Block LGD Code': 3017,
      'Development Block Name (In English)': 'Nabagram',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 242,
      'Development Block LGD Code': 2930,
      'Development Block Name (In English)': 'Nagrakata',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 243,
      'Development Block LGD Code': 3038,
      'Development Block Name (In English)': 'Nakashipara',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 244,
      'Development Block LGD Code': 2837,
      'Development Block Name (In English)': 'Nalhati-I',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 245,
      'Development Block LGD Code': 2838,
      'Development Block Name (In English)': 'Nalhati-Ii',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 246,
      'Development Block LGD Code': 2769,
      'Development Block Name (In English)': 'Namkhana',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 247,
      'Development Block LGD Code': 2961,
      'Development Block Name (In English)': 'Nandakumar',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 248,
      'Development Block LGD Code': 2962,
      'Development Block Name (In English)': 'Nandigram-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 249,
      'Development Block LGD Code': 2963,
      'Development Block Name (In English)': 'Nandigram-Ii',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 250,
      'Development Block LGD Code': 2839,
      'Development Block Name (In English)': 'Nanoor',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 251,
      'Development Block LGD Code': 2995,
      'Development Block Name (In English)': 'Narayangarh',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 252,
      'Development Block LGD Code': 3018,
      'Development Block Name (In English)': 'Nawda',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 253,
      'Development Block LGD Code': 2867,
      'Development Block Name (In English)': 'Naxal Bari',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 254,
      'Development Block LGD Code': 2996,
      'Development Block Name (In English)': 'Nayagram',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 255,
      'Development Block LGD Code': 3056,
      'Development Block Name (In English)': 'Neturia',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 256,
      'Development Block LGD Code': 2944,
      'Development Block Name (In English)': 'Old Malda',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 257,
      'Development Block LGD Code': 2786,
      'Development Block Name (In English)': 'Onda',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 258,
      'Development Block LGD Code': 2819,
      'Development Block Name (In English)': 'Ondal',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 259,
      'Development Block LGD Code': 2912,
      'Development Block Name (In English)': 'Panchla',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 260,
      'Development Block LGD Code': 2820,
      'Development Block Name (In English)': 'Pandabeswar',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 261,
      'Development Block LGD Code': 2899,
      'Development Block Name (In English)': 'Pandua',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 262,
      'Development Block LGD Code': 2964,
      'Development Block Name (In English)': 'Panskura-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 263,
      'Development Block LGD Code': 3057,
      'Development Block Name (In English)': 'Para',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 264,
      'Development Block LGD Code': 2965,
      'Development Block Name (In English)': 'Patashpur-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 265,
      'Development Block LGD Code': 2966,
      'Development Block Name (In English)': 'Patashpur-Ii',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 266,
      'Development Block LGD Code': 2770,
      'Development Block Name (In English)': 'Pathar Pratima',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 267,
      'Development Block LGD Code': 2787,
      'Development Block Name (In English)': 'Patrasayer',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 268,
      'Development Block LGD Code': 7355,
      'Development Block Name (In English)': 'Pedong',
      Hierarchy: 'Kalimpong(District) / West Bengal(State)',
   },
   {
      'S No': 269,
      'Development Block LGD Code': 2868,
      'Development Block Name (In English)': 'Phansidewa',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 270,
      'Development Block LGD Code': 2997,
      'Development Block Name (In English)': 'Pingla',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 271,
      'Development Block LGD Code': 2900,
      'Development Block Name (In English)': 'Polba-Dadpur',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 272,
      'Development Block LGD Code': 3058,
      'Development Block Name (In English)': 'Puncha',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 273,
      'Development Block LGD Code': 2821,
      'Development Block Name (In English)': 'Purbasthali-I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 274,
      'Development Block LGD Code': 2822,
      'Development Block Name (In English)': 'Purbasthali-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 275,
      'Development Block LGD Code': 2901,
      'Development Block Name (In English)': 'Pursurah',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 276,
      'Development Block LGD Code': 3059,
      'Development Block Name (In English)': 'Purulia-I',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 277,
      'Development Block LGD Code': 3060,
      'Development Block Name (In English)': 'Purulia-Ii',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 278,
      'Development Block LGD Code': 3019,
      'Development Block Name (In English)': 'Raghunathganj-I',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 279,
      'Development Block LGD Code': 3020,
      'Development Block Name (In English)': 'Raghunathganj-Ii',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 280,
      'Development Block LGD Code': 3061,
      'Development Block Name (In English)': 'Raghunath Pur-I',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 281,
      'Development Block LGD Code': 3062,
      'Development Block Name (In English)': 'Raghunathpur-Ii',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 282,
      'Development Block LGD Code': 2886,
      'Development Block Name (In English)': 'Raiganj',
      Hierarchy: 'Uttar Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 283,
      'Development Block LGD Code': 2823,
      'Development Block Name (In English)': 'Raina-I',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 284,
      'Development Block LGD Code': 2824,
      'Development Block Name (In English)': 'Raina-Ii',
      Hierarchy: 'Purba Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 285,
      'Development Block LGD Code': 2788,
      'Development Block Name (In English)': 'Raipur-I',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 286,
      'Development Block LGD Code': 2741,
      'Development Block Name (In English)': 'Rajarhat',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 287,
      'Development Block LGD Code': 2931,
      'Development Block Name (In English)': 'Rajganj',
      Hierarchy: 'Jalpaiguri(District) / West Bengal(State)',
   },
   {
      'S No': 288,
      'Development Block LGD Code': 2840,
      'Development Block Name (In English)': 'Rajnagar',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 289,
      'Development Block LGD Code': 2967,
      'Development Block Name (In English)': 'Ramnagar-I',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 290,
      'Development Block LGD Code': 2968,
      'Development Block Name (In English)': 'Ramnagar-Ii',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 291,
      'Development Block LGD Code': 2841,
      'Development Block Name (In English)': 'Rampurhat-I',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 292,
      'Development Block LGD Code': 2842,
      'Development Block Name (In English)': 'Rampurhat-Ii',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 293,
      'Development Block LGD Code': 3039,
      'Development Block Name (In English)': 'Ranaghat-I',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 294,
      'Development Block LGD Code': 3040,
      'Development Block Name (In English)': 'Ranaghat-Ii',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 295,
      'Development Block LGD Code': 2869,
      'Development Block Name (In English)': 'Rangli Rangliot',
      Hierarchy: 'Darjeeling(District) / West Bengal(State)',
   },
   {
      'S No': 296,
      'Development Block LGD Code': 2789,
      'Development Block Name (In English)': 'Ranibundh',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 297,
      'Development Block LGD Code': 2825,
      'Development Block Name (In English)': 'Raniganj',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 298,
      'Development Block LGD Code': 3021,
      'Development Block Name (In English)': 'Raninagar-I',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 299,
      'Development Block LGD Code': 3022,
      'Development Block Name (In English)': 'Raninagar-Ii',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 300,
      'Development Block LGD Code': 2945,
      'Development Block Name (In English)': 'Ratua-I',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 301,
      'Development Block LGD Code': 2946,
      'Development Block Name (In English)': 'Ratua-Ii',
      Hierarchy: 'Malda(District) / West Bengal(State)',
   },
   {
      'S No': 302,
      'Development Block LGD Code': 2998,
      'Development Block Name (In English)': 'Sabang',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 303,
      'Development Block LGD Code': 2771,
      'Development Block Name (In English)': 'Sagar',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 304,
      'Development Block LGD Code': 3023,
      'Development Block Name (In English)': 'Sagardighi',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 305,
      'Development Block LGD Code': 2843,
      'Development Block Name (In English)': 'Sainthia',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 306,
      'Development Block LGD Code': 2826,
      'Development Block Name (In English)': 'Salanpur',
      Hierarchy: 'Paschim Bardhaman(District) / West Bengal(State)',
   },
   {
      'S No': 307,
      'Development Block LGD Code': 2999,
      'Development Block Name (In English)': 'Salbani',
      Hierarchy: 'Paschim Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 308,
      'Development Block LGD Code': 2790,
      'Development Block Name (In English)': 'Saltora',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 309,
      'Development Block LGD Code': 2742,
      'Development Block Name (In English)': 'Sandeshkhali-I',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 310,
      'Development Block LGD Code': 2743,
      'Development Block Name (In English)': 'Sandeshkhali-Ii',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 311,
      'Development Block LGD Code': 3000,
      'Development Block Name (In English)': 'Sankrail',
      Hierarchy: 'Jhargram(District) / West Bengal(State)',
   },
   {
      'S No': 312,
      'Development Block LGD Code': 2913,
      'Development Block Name (In English)': 'Sankrail',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 313,
      'Development Block LGD Code': 3041,
      'Development Block Name (In English)': 'Santipur',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 314,
      'Development Block LGD Code': 3063,
      'Development Block Name (In English)': 'Santuri',
      Hierarchy: 'Purulia(District) / West Bengal(State)',
   },
   {
      'S No': 315,
      'Development Block LGD Code': 2791,
      'Development Block Name (In English)': 'Sarenga',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 316,
      'Development Block LGD Code': 2969,
      'Development Block Name (In English)': 'Shahid Matangini',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 317,
      'Development Block LGD Code': 3024,
      'Development Block Name (In English)': 'Shamsherganj',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 318,
      'Development Block LGD Code': 2914,
      'Development Block Name (In English)': 'Shyampur-I',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 319,
      'Development Block LGD Code': 2915,
      'Development Block Name (In English)': 'Shyampur-Ii',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 320,
      'Development Block LGD Code': 2792,
      'Development Block Name (In English)': 'Simlapal',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 321,
      'Development Block LGD Code': 2902,
      'Development Block Name (In English)': 'Singur',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 322,
      'Development Block LGD Code': 2903,
      'Development Block Name (In English)': 'Sirampur-Uttarpara',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 323,
      'Development Block LGD Code': 2854,
      'Development Block Name (In English)': 'Sitai',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 324,
      'Development Block LGD Code': 2855,
      'Development Block Name (In English)': 'Sitalkuchi',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 325,
      'Development Block LGD Code': 2793,
      'Development Block Name (In English)': 'Sonamukhi',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 326,
      'Development Block LGD Code': 2772,
      'Development Block Name (In English)': 'Sonarpur',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 327,
      'Development Block LGD Code': 2844,
      'Development Block Name (In English)': 'Suri-I',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 328,
      'Development Block LGD Code': 2845,
      'Development Block Name (In English)': 'Suri-Ii',
      Hierarchy: 'Birbhum(District) / West Bengal(State)',
   },
   {
      'S No': 329,
      'Development Block LGD Code': 2970,
      'Development Block Name (In English)': 'Sutahata',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 330,
      'Development Block LGD Code': 3025,
      'Development Block Name (In English)': 'Suti-I',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 331,
      'Development Block LGD Code': 3026,
      'Development Block Name (In English)': 'Suti-Ii',
      Hierarchy: 'Murshidabad(District) / West Bengal(State)',
   },
   {
      'S No': 332,
      'Development Block LGD Code': 2744,
      'Development Block Name (In English)': 'Swarupnagar',
      Hierarchy: 'North 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 333,
      'Development Block LGD Code': 2794,
      'Development Block Name (In English)': 'Taldangra',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
   {
      'S No': 334,
      'Development Block LGD Code': 2971,
      'Development Block Name (In English)': 'Tamluk',
      Hierarchy: 'Purba Medinipur(District) / West Bengal(State)',
   },
   {
      'S No': 335,
      'Development Block LGD Code': 2877,
      'Development Block Name (In English)': 'Tapan',
      Hierarchy: 'Dakshin Dinajpur(District) / West Bengal(State)',
   },
   {
      'S No': 336,
      'Development Block LGD Code': 2904,
      'Development Block Name (In English)': 'Tarakeswar',
      Hierarchy: 'Hoogly(District) / West Bengal(State)',
   },
   {
      'S No': 337,
      'Development Block LGD Code': 3042,
      'Development Block Name (In English)': 'Tehatta-I',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 338,
      'Development Block LGD Code': 3043,
      'Development Block Name (In English)': 'Tehatta-Ii',
      Hierarchy: 'Nadia(District) / West Bengal(State)',
   },
   {
      'S No': 339,
      'Development Block LGD Code': 2773,
      'Development Block Name (In English)': 'Thakurpukur Mahestola',
      Hierarchy: 'South 24 Parganas(District) / West Bengal(State)',
   },
   {
      'S No': 340,
      'Development Block LGD Code': 2856,
      'Development Block Name (In English)': 'Tufanganj-I',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 341,
      'Development Block LGD Code': 2857,
      'Development Block Name (In English)': 'Tufanganj-Ii',
      Hierarchy: 'Cooch Behar(District) / West Bengal(State)',
   },
   {
      'S No': 342,
      'Development Block LGD Code': 2916,
      'Development Block Name (In English)': 'Udaynarayanpur',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 343,
      'Development Block LGD Code': 2917,
      'Development Block Name (In English)': 'Uluberia-I',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 344,
      'Development Block LGD Code': 2918,
      'Development Block Name (In English)': 'Uluberia-Ii',
      Hierarchy: 'Howrah(District) / West Bengal(State)',
   },
   {
      'S No': 345,
      'Development Block LGD Code': 2795,
      'Development Block Name (In English)': 'Vishnupur',
      Hierarchy: 'Bankura(District) / West Bengal(State)',
   },
]
