import React, { useEffect, useState } from 'react'

import { TextField, Button, Typography, Box, Stack, Alert, AlertTitle } from '@mui/material'

import { auth } from '../../FirebaseConfig.js'
import {
   createUserWithEmailAndPassword,
   signInWithEmailAndPassword,
   sendPasswordResetEmail,
   onAuthStateChanged,
} from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'

// import FullPageLoader from '../../Components/FullPageLoader/FullPageLoader.js'

import { selectUsers, setUser } from '../../redux/reducers/users-slice.js'
import styles from './Login.module.css'

function Login() {
   const dispatch = useDispatch()

   // const [isLoading, setIsLoading] = useState(true)
   const [loginType, setLoginType] = useState('login')
   const [userCredentials, setUserCredentials] = useState({ email: '', password: '' })
   const [error, setError] = useState('')

   const [isUserLoggedIn, setIsUserLoggedIn] = useState(0)

   useEffect(() => {
      onAuthStateChanged(auth, (user) => {
         if (user) {
            dispatch(setUser({ id: user.uid, email: user.email, displayName: user.displayName }))
            console.log('login stage -1 : useeffect ')
            setIsUserLoggedIn(1)
         } else {
            dispatch(setUser(null))
            setIsUserLoggedIn(2)
         }
      })
   }, [])

   const user = useSelector(selectUsers)
   console.log('login stage -2 : normal: ', user)

   function handleCredentials(e) {
      // console.log(e.target.value)
      setUserCredentials({ ...userCredentials, [e.target.name]: e.target.value })
   }

   // useEffect(() => {
   //    console.log(userCredentials)
   // }, [userCredentials])

   function handleSignup(e) {
      e.preventDefault()
      setError('')
      createUserWithEmailAndPassword(auth, userCredentials.email, userCredentials.password)
         .then((userCredential) => {
            setIsUserLoggedIn(1)
            dispatch(
               setUser({
                  id: userCredential.user.uid,
                  email: userCredential.user.email,
                  dispplayName: userCredential.user.displayName,
               }),
            )
         })
         .catch((error) => {
            setError(error.message)
         })
   }

   function handleLogin(e) {
      e.preventDefault()
      setError('')

      signInWithEmailAndPassword(auth, userCredentials.email, userCredentials.password)
         .then((userCredential) => {
            setIsUserLoggedIn(1)
            dispatch(
               setUser({
                  id: userCredential.user.uid,
                  email: userCredential.user.email,
                  displayName: userCredential.user.displayName,
               }),
            )
            console.log('login stage -3 : useeffect : ', userCredential)
         })
         .catch((error) => {
            setError(error.message)
         })
   }

   function handlePasswordReset() {
      const email = prompt('Please enter your email')
      if (email) {
         sendPasswordResetEmail(auth, email)
            .then(() => {
               alert('Email sent! Check your inbox for password reset instructions.')
            })
            .catch((error) => {
               setError(error.message)
            })
      } else {
         //
      }
   }

   // console.log(auth)

   return (
      <div className={styles.mainDiv}>
         {/* {isLoading && <FullPageLoader></FullPageLoader>} */}
         {isUserLoggedIn === 0 && (
            <div>
               <Alert variant="outlined" severity="warning" sx={{ margin: 5 }}>
                  <AlertTitle>... Loading !</AlertTitle>
               </Alert>
            </div>
         )}
         {isUserLoggedIn === 1 && (
            <div>
               <Alert variant="outlined" severity="success" sx={{ margin: 5 }}>
                  <AlertTitle>Welcome!</AlertTitle>
                  You are logged in the site. Enjoy tracking democracy!
                  {/* {user.currentUser.id} */}
               </Alert>
            </div>
         )}
         {isUserLoggedIn === 2 && (
            <div>
               <Box
                  sx={{
                     border: 1,
                     mb: 1,
                     p: 2,
                     borderRadius: 2,
                     display: 'flex',
                     flexDirection: 'column',
                     '& .MuiButton-root': { width: '18ch' },
                     '& .MuiTextField-root': { width: '50ch' },
                  }}
               >
                  <div>
                     <Typography align="center" color="primary" variant="h6">
                        Please select what you want to do!
                     </Typography>
                     <Stack spacing={2} direction="row" justifyContent="center">
                        <Button
                           variant={loginType === 'login' ? 'contained' : 'outlined'}
                           size="small"
                           sx={{ textTransform: 'none', padding: 0, minWidth: 0 }}
                           onClick={() => setLoginType('login')}
                        >
                           Login
                        </Button>
                        {/* <Button
                           variant={loginType === 'signup' ? 'contained' : 'outlined'}
                           size="small"
                           sx={{ textTransform: 'none', padding: 0, minWidth: 0 }}
                           onClick={() => setLoginType('signup')}
                        >
                           Signup
                        </Button> */}
                        <Button
                           variant={loginType === 'forgot' ? 'contained' : 'outlined'}
                           size="small"
                           sx={{ textTransform: 'none', padding: 0, minWidth: 0 }}
                           onClick={() => setLoginType('forgot')}
                        >
                           Forgot password?
                        </Button>
                     </Stack>
                  </div>
               </Box>

               <Box
                  sx={{
                     border: 1,
                     mb: 1,
                     mx: 5,
                     pb: 1,
                     borderRadius: 2,
                     display: 'flex',
                     flexDirection: 'column',
                     '& .MuiButton-root': { width: '15ch' },
                     '& .MuiTextField-root': { width: '50ch' },
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
               >
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleCredentials(e)
                        }}
                        value={userCredentials.email}
                        name="email"
                        id="email"
                        label="Email"
                        type="email"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                     ></TextField>
                  </div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        // onChange={(event) => setPassword(event.target.value)}
                        onChange={(e) => {
                           handleCredentials(e)
                        }}
                        value={userCredentials.password}
                        name="password"
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        align="center"
                        fullWidth
                        required
                     ></TextField>
                  </div>
                  <div>
                     {loginType === 'login' ? (
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           onClick={(e) => {
                              handleLogin(e)
                           }}
                        >
                           LogIn
                        </Button>
                     ) : (
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           onClick={(e) => {
                              handleSignup(e)
                           }}
                        >
                           Sign up
                        </Button>
                     )}

                     {error && <div className={styles.error}>{error}</div>}
                  </div>
               </Box>

               <Box
                  sx={{
                     border: 1,
                     mb: 2,
                     mx: 5,
                     p: 0,
                     borderRadius: 2,
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
               >
                  <p onClick={handlePasswordReset} className={styles.forgot_password}>
                     Forgot Password?
                  </p>
               </Box>
            </div>
         )}
      </div>
   )
}

export default Login
