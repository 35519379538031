import React from 'react'
import PropTypes from 'prop-types'

function ElecManifestoes({ props }) {
   const elec = props
   console.log('value from props from inside Elec Manifestoes page: ', elec)
   return <div>Elec Manifestoes Admin</div>
}

ElecManifestoes.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecManifestoes
