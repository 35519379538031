import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

function HomeStateJudiciary() {
   return (
      <div>
         <Alert
            variant="outlined"
            severity="warning"
            sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
         >
            <AlertTitle sx={{ fontSize: 12 }}>
               To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
            </AlertTitle>
         </Alert>
      </div>
   )
}

export default HomeStateJudiciary
