import React, { useEffect, useState } from 'react'
import { db } from '../../FirebaseConfig'
import { Button } from '@mui/material'
import {
   query,
   collection,
   getDocs,
   doc,
   getDoc,
   updateDoc,
   deleteField,
   where,
   orderBy,
   arrayUnion,
   getCountFromServer,
   deleteDoc,
} from 'firebase/firestore'

function MydbOperations() {
   // const q = query(collection(db, 'Districts'))
   // const q = query(collection(db, 'Legislatures'), where('State_id', '==', 'Obp65dnOgoPopGBF9SAo'))
   // const q = query(collection(db, 'Districts'), where('Division_id', '==', 'CUfI9eboaYHfNHwGQ7kS'))

   // const updateAllDocsInACollection = async (e) => {
   //    e.preventDefault()
   //    const querySnapshot = await getDocs(q)
   //    querySnapshot.forEach((docSnap) => {
   //       const docRef = doc(db, 'Districts', docSnap.id)
   //       updateDoc(docRef, {
   //          DivisionName: 'Karnal',
   //       })
   //    })
   // }

   const q2 = query(collection(db, 'Districts'))
   const [districtsList, setDistrictsList] = useState('')

   const fetchDistricts = async (e) => {
      e.preventDefault()
      try {
         const districtsListFetched = []
         const querySnapshot = await getDocs(q2)
         querySnapshot.forEach((docSnap) => {
            districtsListFetched.push({ id: docSnap.id, ...docSnap.data() })
         })
         setDistrictsList(districtsListFetched)
         console.log('districts fetched: ', districtsListFetched)
      } catch (error) {
         console.log(error)
      }
   }

   const updateDivisionNameInDistricts = async () => {
      if (districtsList) {
         try {
            districtsList.forEach(async (item) => {
               console.log('division id from list: ', item.Division_id)
               const id = item.Division_id
               if (id) {
                  const docRefDivision = doc(db, 'Divisions', id)
                  const docSnapDivision = await getDoc(docRefDivision)
                  console.log('Division detail: ', docSnapDivision)
                  console.log('division name by division id ref: ', docSnapDivision.data().Name)

                  const docRef = doc(db, 'Districts', item.id)
                  await updateDoc(docRef, {
                     DivisionName: docSnapDivision.data().Name,
                  })
                  console.log('id ', item.id, ' updated')
               } else {
                  const docRef = doc(db, 'Districts', item.id)
                  await updateDoc(docRef, {
                     DivisionName: '',
                  })
               }
            })
         } catch (error) {
            console.log(error)
         }
      }
   }

   useEffect(() => {
      updateDivisionNameInDistricts()
   }, [districtsList])

   const q3 = query(collection(db, 'PoliticalParties'))
   const [partiesList, setPartiesList] = useState('')

   const fetchParties = async (e) => {
      e.preventDefault()
      try {
         const partiesListFetched = []
         const querySnapshot = await getDocs(q3)
         querySnapshot.forEach((docSnap) => {
            partiesListFetched.push({ id: docSnap.id, ...docSnap.data() })
         })
         setPartiesList(partiesListFetched)
         // console.log('parties fetched: ', partiesListFetched)
      } catch (error) {
         console.log(error)
      }
   }

   // const updateParties = async () => {
   //    if (partiesList) {
   //       try {
   //          partiesList.forEach(async (item) => {
   //             // console.log('division id from list: ', item.Division_id)

   //             const docRef = doc(db, 'PoliticalParties', item.id)
   //             await updateDoc(docRef, {
   //                OldFlags: [],
   //                OldSymbols: [],
   //             })
   //             console.log('id ', item.id, ' updated')
   //          })
   //       } catch (error) {
   //          console.log(error.message)
   //       }
   //    }
   // }

   useEffect(() => {
      updateParties()
   }, [partiesList])

   // const addressDetails = {
   //    ValueRadio: '',
   //    State_id: '',
   //    StateName: '',
   //    District_id: '',
   //    DistrictName: '',
   //    PinCode: '',
   //    Address: '',
   //    RecordedDate: '',
   // }

   // const updateParties = async () => {
   //    if (partiesList) {
   //       try {
   //          partiesList.forEach(async (item) => {
   //             // console.log('division id from list: ', item.Division_id)

   //             const docRef = doc(db, 'PoliticalParties', item.id)
   //             await updateDoc(docRef, {
   //                // OldNamesNum: 1,
   //                // OldAbbreviationsNum: 1,
   //                // State_ids: [],
   //                // ECIStatus: item.Level,
   //                // OldECIStatusesNum: item.OldLevelsNum,
   //                Level: deleteField(),
   //                OldLevelsNum: deleteField(),
   //             })
   //             console.log('id ', item.id, ' updated')
   //          })
   //       } catch (error) {
   //          console.log(error.message)
   //       }
   //    }
   // }

   const updateParties = async () => {
      if (partiesList) {
         try {
            partiesList.forEach(async (item) => {
               // console.log('division id from list: ', item.Division_id)

               const docRef = doc(db, 'PoliticalParties', item.id)
               await updateDoc(docRef, {
                  OldHeadQuartersNum: deleteField(),
                  // Headquarter: addressDetails,
                  // OldAbbreviationsNum: 1,
                  // State_ids: [],
                  // ECIStatus: item.Level,
                  // OldECIStatusesNum: item.OldLevelsNum,
               })
               console.log('id ', item.id, ' updated')
            })
         } catch (error) {
            console.log(error.message)
         }
      }
   }

   // const updatePartiesFieldDelete = async () => {
   //    if (partiesList) {
   //       try {
   //          partiesList.forEach(async (item) => {
   //             // console.log('division id from list: ', item.Division_id)

   //             const docRef = doc(db, 'PoliticalParties', item.id)
   //             await updateDoc(docRef, {
   //                OldFlags: deleteField(),
   //                OldSymbols: deleteField(),
   //                OldNames: deleteField(),
   //                OldHeadquarters: deleteField(),
   //                UploadDt: deleteField(),
   //             })
   //             console.log('id ', item.id, ' updated')
   //          })
   //       } catch (error) {
   //          console.log(error.message)
   //       }
   //    }
   // }

   const updatePinCodes = async () => {
      try {
         const querryPinCode = query(collection(db, 'PinCodes'))
         const querySnapshot = await getDocs(querryPinCode)
         querySnapshot.forEach((docSnap) => {
            const docRef = doc(db, 'PinCodes', docSnap.id)
            updateDoc(docRef, {
               ForAddress: true,
            })
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   const updateLegislatures = async () => {
      try {
         const querryLegis = query(collection(db, 'Legislatures'))
         const querySnapshot = await getDocs(querryLegis)
         querySnapshot.forEach((docSnap) => {
            const docRef = doc(db, 'Legislatures', docSnap.id)
            updateDoc(docRef, {
               // NamesOld: [],
               // NameAltsOld: [],
               // TaglinesOld: [],
               // ElectiveSeatsOld: [],
               // NominatedSeatsOld: [],
               // NominatingAuthoritiesOld: [],
               // ImagesOld: [],
            })
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   // updating the country with an array of all the states under it
   const updateCountry = async () => {
      try {
         const querryStates = query(collection(db, 'States'), where('Country_id', '==', 'lsTDGRF8XHWnR3VjVuB4'))
         const querySnapshot = await getDocs(querryStates)

         const statesListFetched = []

         querySnapshot.forEach((docSnap) => {
            statesListFetched.push({
               State_id: docSnap.id,
               Name: docSnap.data().Name,
               NameAlt: docSnap.data().NameAlt,
               Type: docSnap.data().Type,
               Capital: docSnap.data().Capital,
               Downlevel: docSnap.data().DownLevel,
               Flag: docSnap.data().Flag,
               Symbol: docSnap.data().Symbol,
            })
         })

         console.log(statesListFetched)

         const docRef = doc(db, 'Countries', 'lsTDGRF8XHWnR3VjVuB4')
         updateDoc(docRef, {
            States: statesListFetched,
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   // updating the state with an array of all the districts under it

   const [statesListForUpdate, setStatesListForUpdate] = useState('')

   const fetchStatesList = async () => {
      try {
         const querryStates = query(collection(db, 'States'))
         const querySnapshot = await getDocs(querryStates)

         const statesListFetched = []

         querySnapshot.forEach((docSnap) => {
            statesListFetched.push({ id: docSnap.id, ...docSnap.data() })

            setStatesListForUpdate(statesListFetched)
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   useEffect(() => {
      if (statesListForUpdate.length > 0) {
         updateStates()
      }
   }, [statesListForUpdate])

   const updateStates = async () => {
      if (statesListForUpdate && statesListForUpdate.length > 0) {
         try {
            statesListForUpdate.forEach(async (item) => {
               const districtsListFetched = []

               const querryDistricts = query(collection(db, 'Districts'), where('State_id', '==', item.id))
               const querySnapshot = await getDocs(querryDistricts)
               console.log('querySnapshot: ', querySnapshot)

               querySnapshot.forEach((docSnap) => {
                  districtsListFetched.push({
                     District_id: docSnap.id,
                     Name: docSnap.data().Name,
                     NameAlt: docSnap.data().NameAlt,
                     HeadQuarter: docSnap.data().HeadQuarter,
                     Division_id: docSnap.data().Division_id,
                     State_id: docSnap.data().State_id,
                     Flag: docSnap.data().Flag,
                     Symbol: docSnap.data().Symbol,
                     ForLegis: docSnap.data().ForLegis,
                  })
               })

               console.log('querySnapshot: ', districtsListFetched)

               const docRef = doc(db, 'States', item.id)
               await updateDoc(docRef, {
                  Districts: districtsListFetched,
               })
               console.log('state id ', item.id, ' updated for districts')
            })
         } catch (error) {
            console.log(error.message)
         }
      }
   }

   // updating process for any new data

   const [legisListForUpdateOfConstituencies, setLegisListForUpdateOfConstituencies] = useState('')

   const fetchLegisListForUpdateOfConstituencies = async () => {
      try {
         const querryLegislatures = query(collection(db, 'Legislatures'))
         const querySnapshot = await getDocs(querryLegislatures)

         const legislaturesListFetched = []

         querySnapshot.forEach((docSnap) => {
            legislaturesListFetched.push({ id: docSnap.id, ...docSnap.data() })

            setLegisListForUpdateOfConstituencies(legislaturesListFetched)
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   useEffect(() => {
      if (legisListForUpdateOfConstituencies.length > 0) {
         updateLegislaturesForUpdateOfConstituencies()
      }
   }, [legisListForUpdateOfConstituencies])

   const updateLegislaturesForUpdateOfConstituencies = async () => {
      if (legisListForUpdateOfConstituencies && legisListForUpdateOfConstituencies.length > 0) {
         try {
            legisListForUpdateOfConstituencies.forEach(async (item) => {
               const constituenciesListFetched = []

               const querryConstituencies = query(
                  collection(db, 'LegisConstituencies'),
                  where('Legislature_id', '==', item.id),
                  orderBy('ConstituencyNumber', 'asc'),
               )
               const querySnapshot = await getDocs(querryConstituencies)
               console.log('querySnapshot: ', querySnapshot)

               querySnapshot.forEach((docSnap) => {
                  constituenciesListFetched.push({
                     Constituency_id: docSnap.id,
                     ConstituencyNumber: docSnap.data().ConstituencyNumber,
                     Name: docSnap.data().Name,
                     NameAlt: docSnap.data().NameAlt,
                     StateName: docSnap.data().StateName,
                     State_id: docSnap.data().State_id,
                     DivisionName: docSnap.data().DivisionName,
                     Division_id: docSnap.data().Division_id,
                     DistrictName: docSnap.data().DistrictName,
                     District_id: docSnap.data().District_id,
                     ParentConsti_id: docSnap.data().ParentConsti_id,
                     ParentConstiName: docSnap.data().ParentConstiName,
                     IsNominated: docSnap.data().IsNominated,
                  })
               })

               console.log('querySnapshot: ', constituenciesListFetched)

               const docRef = doc(db, 'Legislatures', item.id)
               if (constituenciesListFetched.length > 0) {
                  await updateDoc(docRef, {
                     Constituencies: constituenciesListFetched,
                  })
                  console.log('state id ', item.id, ' updated for Constituencies with value')
               } else {
                  await updateDoc(docRef, {
                     Constituencies: [],
                  })
                  console.log('state id ', item.id, ' updated for Constituencies without value')
               }
            })
         } catch (error) {
            console.log(error.message)
         }
      }
   }

   // self update of constituencies

   const [constituenciesListForSelfUpdate, setConstituenciesListForSelfUpdate] = useState('')

   const fetchConstituenciesForSelfUpdate = async () => {
      try {
         const querryConstituencies = query(collection(db, 'LegisConstituencies'), where('ParentConsti_id', '!=', ''))
         const querySnapshot = await getDocs(querryConstituencies)

         const constituenciesListFetched = []

         querySnapshot.forEach((docSnap) => {
            constituenciesListFetched.push({ id: docSnap.id, ...docSnap.data() })

            setConstituenciesListForSelfUpdate(constituenciesListFetched)
         })

         console.log('constituenciesListFetched for update: ', constituenciesListFetched)
      } catch (error) {
         console.log(error.message)
      }
   }

   useEffect(() => {
      if (constituenciesListForSelfUpdate.length > 0) {
         selfUpdateConstituencies()
      }
   }, [constituenciesListForSelfUpdate])

   const selfUpdateConstituencies = async () => {
      if (constituenciesListForSelfUpdate && constituenciesListForSelfUpdate.length > 0) {
         try {
            const parentConstisListFetched = []
            const querryParentConstituencies = query(
               collection(db, 'LegisConstituencies'),
               where('Strata', '==', 'Central'),
            )
            const querySnapshotParent = await getDocs(querryParentConstituencies)

            querySnapshotParent.forEach((docSnap) => {
               parentConstisListFetched.push({ id: docSnap.id, ...docSnap.data() })
            })

            console.log('parentConstisListFetched: ', parentConstisListFetched)

            constituenciesListForSelfUpdate.forEach(async (item) => {
               const parentConsti = parentConstisListFetched.find(
                  (itemParent) => itemParent.id === item.ParentConsti_id,
               )

               console.log('ParentConsti_id: ', item.ParentConsti_id, ' ParentConstiName: ', parentConsti.Name)

               const docRef = doc(db, 'LegisConstituencies', item.id)
               await updateDoc(docRef, {
                  ParentConstiName: parentConsti.Name,
               })
            })
         } catch (error) {
            console.log(error.message)
         }
      }
   }

   /// extra area

   const [legisListForReverseUpdate, setLegisListForReverseUpdate] = useState('')

   const fetchLegisListForReverseUpdate = async () => {
      try {
         const querryLeg = query(collection(db, 'Legislatures'))
         const querySnapshot = await getDocs(querryLeg)

         const legListFetched = []

         querySnapshot.forEach((docSnap) => {
            legListFetched.push({ id: docSnap.id, ...docSnap.data() })

            setLegisListForReverseUpdate(legListFetched)
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   useEffect(() => {
      if (legisListForReverseUpdate.length > 0) {
         reverseUpdateLegis()
      }
   }, [legisListForReverseUpdate])

   const reverseUpdateLegis = async () => {
      if (legisListForReverseUpdate && legisListForReverseUpdate.length > 0) {
         try {
            legisListForReverseUpdate.forEach(async (item) => {
               if (item.Strata === 'Central') {
                  const docRefCentral = doc(db, 'Countries', item.Country_id)
                  await updateDoc(docRefCentral, {
                     Legislatures: arrayUnion({
                        Legislature_id: item.id,
                        Name: item.Name,
                        NameAlt: item.NameAlt,
                        Strata: item.Strata,
                        Nature: item.Nature,
                        HasHouses: item.HasHouses,
                        HasConstituencies: item.HasConstituencies,
                        Image: item.Image,
                        MinistryLegis_id: item.MinistryLegis_id,
                        MinistryLegisName: item.MinistryLegisName,
                        Priority: item.Priority,
                     }),
                  })
               } else if (item.Strata === 'State') {
                  const docRefState = doc(db, 'States', item.State_id)
                  await updateDoc(docRefState, {
                     Legislatures: arrayUnion({
                        Legislature_id: item.id,
                        Name: item.Name,
                        NameAlt: item.NameAlt,
                        Strata: item.Strata,
                        Nature: item.Nature,
                        HasHouses: item.HasHouses,
                        HasConstituencies: item.HasConstituencies,
                        Image: item.Image,
                        MinistryLegis_id: item.MinistryLegis_id,
                        MinistryLegisName: item.MinistryLegisName,
                        Priority: item.Priority,
                     }),
                  })
               } else if (item.Strata === 'Division') {
                  const docRefDivision = doc(db, 'Divisions', item.Division_id)
                  await updateDoc(docRefDivision, {
                     Legislatures: arrayUnion({
                        Legislature_id: item.id,
                        Name: item.Name,
                        NameAlt: item.NameAlt,
                        Strata: item.Strata,
                        Nature: item.Nature,
                        HasHouses: item.HasHouses,
                        HasConstituencies: item.HasConstituencies,
                        Image: item.Image,
                        MinistryLegis_id: item.MinistryLegis_id,
                        MinistryLegisName: item.MinistryLegisName,
                        Priority: item.Priority,
                     }),
                  })
               } else if (item.Strata === 'District') {
                  const docRefDistrict = doc(db, 'Districts', item.District_id)
                  await updateDoc(docRefDistrict, {
                     Legislatures: arrayUnion({
                        Legislature_id: item.id,
                        Name: item.Name,
                        NameAlt: item.NameAlt,
                        Strata: item.Strata,
                        Nature: item.Nature,
                        HasHouses: item.HasHouses,
                        HasConstituencies: item.HasConstituencies,
                        Image: item.Image,
                        MinistryLegis_id: item.MinistryLegis_id,
                        MinistryLegisName: item.MinistryLegisName,
                        Priority: item.Priority,
                     }),
                  })
               }
            })
         } catch (error) {
            console.log(error.message)
         }
      }
   }

   // count updates for new fields

   const CountUpdate = async () => {
      try {
         const querry = query(collection(db, 'Divisions'))
         const querySnapshot = await getDocs(querry)

         querySnapshot.forEach(async (docSnap) => {
            const coll = collection(db, 'Districts')
            const q = query(coll, where('Division_id', '==', docSnap.id))
            const snapshot = await getCountFromServer(q)

            console.log('count: ', snapshot.data().count)
            const Count = snapshot.data().count

            const docRefUpdate = doc(db, 'Divisions', docSnap.id)
            await updateDoc(docRefUpdate, {
               DistrictsCount: Count,
            })
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   // simple updates

   const simpleUpdate = async () => {
      try {
         // const docRefPP = doc(db, 'PoliticalParties', 'mcnD4BJlEglyRCwxD4Hb')
         // const docSnapPP = await getDoc(docRefPP)
         // const docRefUpdatePP = doc(db, 'BaseLists', 'PoliticalParties-lsTDGRF8XHWnR3VjVuB4')
         // await updateDoc(docRefUpdatePP, {
         //    Parties: arrayUnion({
         //       Party_id: docSnapPP.id,
         //       Name: docSnapPP.data().Name,
         //       NameHindi: docSnapPP.data().NameHindi,
         //       NameLocal: docSnapPP.data().NameLocal,
         //       Abbreviation: docSnapPP.data().Abbreviation,
         //       ECIStatus: docSnapPP.data().ECIStatus,
         //       Active: true,
         //       Flag: docSnapPP.data().Flag,
         //       Symbol: docSnapPP.data().Symbol,
         //    }),
         // })
         ///
         /// new batch:
         // let countAdded = 0
         // let coreListId = ''
         // const querry = query(collection(db, 'ElectionCandidates'), where('Election_id', '==', 'eHt7k2NNw54Xaq2T8kAK'))
         // const querySnapshot = await getDocs(querry)
         // querySnapshot.forEach(async (docSnap) => {
         //    //
         //    const record = {
         //       Candidate_id: docSnap.id,
         //       Name: docSnap.data().Name,
         //       NameHindi: docSnap.data().NameHindi,
         //       NameLocal: docSnap.data().NameLocal,
         //       PhaseNum: docSnap.data().PhaseNum,
         //       Constituency_id: docSnap.data().Constituency_id,
         //       ConstituencyName: docSnap.data().ConstituencyName,
         //       ConstituencyNameHindi: docSnap.data().ConstituencyNameHindi,
         //       ConstituencyNameLocal: docSnap.data().ConstituencyNameLocal,
         //       Age: docSnap.data().Age,
         //       DoB: docSnap.data().DoB,
         //       Gender: docSnap.data().Gender,
         //       Party_id: docSnap.data().Party_id,
         //       PartyName: docSnap.data().PartyName,
         //       PartyNameHindi: docSnap.data().PartyNameHindi,
         //       PartyNameLocal: docSnap.data().PartyNameLocal,
         //       PartyAbbreviation: docSnap.data().PartyAbbreviation,
         //       PartyFlag: docSnap.data().PartyFlag,
         //       PartySymbol: docSnap.data().PartySymbol,
         //       AllottedSymbol: docSnap.data().AllottedSymbol,
         //       Alliances: docSnap.data().Alliances,
         //       Image: docSnap.data().Image,
         //       CoreListId: coreListId,
         //    }
         //    console.log('record', record)
         //    countAdded = countAdded + 1
         //    console.log('count: ', countAdded)
         //    if (countAdded === 1) {
         //       // first create the doc and then update
         //       coreListId = 'eHt7k2NNw54Xaq2T8kAK' + '-CoreList-001'
         //       const docRef = doc(db, 'ElectionCandidates', coreListId)
         //       setDoc(docRef, { Candidates: [] }, { merge: true })
         //       console.log('created document: ', coreListId)
         //       const recordNew = { ...record, CoreListId: coreListId }
         //       const docRefElectionCandidates = doc(db, 'ElectionCandidates', coreListId)
         //       await updateDoc(docRefElectionCandidates, {
         //          Candidates: arrayUnion(recordNew),
         //       })
         //       const docRefElection = doc(db, 'Elections', 'eHt7k2NNw54Xaq2T8kAK')
         //       await updateDoc(docRefElection, {
         //          CandidatesCount: increment(1),
         //          CandidatesCoreLists: arrayUnion(coreListId),
         //       })
         //    } else if (countAdded === 1001) {
         //       // first create the doc and then update
         //       coreListId = 'eHt7k2NNw54Xaq2T8kAK' + '-CoreList-002'
         //       const docRef = doc(db, 'ElectionCandidates', coreListId)
         //       setDoc(docRef, { Candidates: [] }, { merge: true })
         //       console.log('created document: ', coreListId)
         //       const recordNew = { ...record, CoreListId: coreListId }
         //       const docRefElectionCandidates = doc(db, 'ElectionCandidates', coreListId)
         //       await updateDoc(docRefElectionCandidates, {
         //          Candidates: arrayUnion(recordNew),
         //       })
         //       const docRefElection = doc(db, 'Elections', 'eHt7k2NNw54Xaq2T8kAK')
         //       await updateDoc(docRefElection, {
         //          CandidatesCount: increment(1),
         //          CandidatesCoreLists: arrayUnion(coreListId),
         //       })
         //    } else {
         //       // straightway update the doc
         //       const docRefUpdate = doc(db, 'ElectionCandidates', coreListId)
         //       await updateDoc(docRefUpdate, {
         //          Candidates: arrayUnion(record),
         //       })
         //       const docRefElection = doc(db, 'Elections', 'eHt7k2NNw54Xaq2T8kAK')
         //       await updateDoc(docRefElection, {
         //          CandidatesCount: increment(1),
         //       })
         //    }
         // })
         //
         // const docRefElection = doc(db, 'Elections', 'eHt7k2NNw54Xaq2T8kAK')
         // const docSnapElection = await getDoc(docRefElection)
         // const constiList = docSnapElection.data().Constituencies
         // const docRefCore = doc(db, 'ElectionCandidates', 'eHt7k2NNw54Xaq2T8kAK-CoreList-002')
         // const docSnapCore = await getDoc(docRefCore)
         // const candidateList = docSnapCore.data().Candidates
         // candidateList.forEach(async (item) => {
         //    const consti = constiList.find((itemConsti) => itemConsti.Constituency_id === item.Constituency_id)
         //    const constiNum = consti.ConstituencyNumber
         //    const docRefUpdateCandidate = doc(db, 'ElectionCandidates', item.Candidate_id)
         //    await updateDoc(docRefUpdateCandidate, {
         //       ConstituencyNumber: constiNum,
         //       CoreListId: item.CoreListId,
         //    })
         // })

         const q = query(collection(db, 'ElectionCandidates'), where('Election_id', '==', 'lfoOBVgQSifbNXkzh07j'))

         const querySnapshot = await getDocs(q)
         querySnapshot.forEach(async (docSnap) => {
            await deleteDoc(doc(db, 'ElectionCandidates', docSnap.id))
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   // new updates

   const newUpdate = async () => {
      try {
         const querry = query(collection(db, 'Legislatures'))
         const querySnapshot = await getDocs(querry)

         querySnapshot.forEach(async (docSnap) => {
            const querryConsti = query(collection(db, 'LegisConstituencies'), where('Legislature_id', '==', docSnap.id))
            const querySnapshotConsti = await getDocs(querryConsti)

            querySnapshotConsti.forEach(async (docSnapConsti) => {
               const docRefUpdate = doc(db, 'Legislatures', docSnap.id)
               await updateDoc(docRefUpdate, {
                  Constituencies: arrayUnion({
                     Constituency_id: docSnapConsti.id,
                     Legislature_id: docSnapConsti.data().Legislature_id,
                     LegislatureName: docSnapConsti.data().LegislatureName,
                     ConstituencyNumber: docSnapConsti.data().ConstituencyNumber,
                     Name: docSnapConsti.data().Name,
                     NameHindi: docSnapConsti.data().NameHindi,
                     NameLocal: docSnapConsti.data().NameLocal,
                     NameAlt: docSnapConsti.data().NameAlt,
                     StateName: docSnapConsti.data().StateName,
                     State_id: docSnapConsti.data().State_id,
                     DivisionName: docSnapConsti.data().DivisionName,
                     Division_id: docSnapConsti.data().Division_id,
                     DistrictName: docSnapConsti.data().DistrictName,
                     District_id: docSnapConsti.data().District_id,
                     SubDistrict_id: docSnapConsti.data().SubDistrict_id,
                     SubDistrictName: docSnapConsti.data().SubDistrictName,
                     Block_id: docSnapConsti.data().Block_id,
                     BlockName: docSnapConsti.data().BlockName,
                     Village_id: docSnapConsti.data().Village_id,
                     VillageName: docSnapConsti.data().VillageName,
                     ParentConsti_id: docSnapConsti.data().ParentConsti_id,
                     ParentConstiName: docSnapConsti.data().ParentConstiName,
                     IsNominated: docSnapConsti.data().IsNominated,
                     Strata: docSnapConsti.data().Strata,
                  }),
               })
            })
         })
      } catch (error) {
         console.log(error.message)
      }
   }

   return (
      <div>
         <div>
            <Button
               type="submit"
               sx={{ display: 'none' }}
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  fetchDistricts(e)
               }}
            >
               update districts for division name...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               sx={{ display: 'none' }}
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  fetchParties(e)
               }}
            >
               update all parties...
            </Button>
         </div>

         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  updatePinCodes(e)
               }}
            >
               update all pincodes...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  updateLegislatures(e)
               }}
            >
               update all legislatures...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  updateCountry(e)
               }}
            >
               update country for its states...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  fetchStatesList(e)
               }}
            >
               update states for its districts...
            </Button>
         </div>
         <div>For any data</div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  fetchLegisListForUpdateOfConstituencies(e)
               }}
            >
               update any data ...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  fetchConstituenciesForSelfUpdate(e)
               }}
            >
               self update ...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  fetchLegisListForReverseUpdate(e)
               }}
            >
               reverse update countries / states for legis ...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  CountUpdate(e)
               }}
            >
               count update for fields ...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  simpleUpdate(e)
               }}
            >
               simple update for fields ...
            </Button>
         </div>
         <div>
            <Button
               type="submit"
               align="center"
               sx={{ display: 'none' }}
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  newUpdate(e)
               }}
            >
               new update for fields ...
            </Button>
         </div>
      </div>
   )
}

export default MydbOperations
