import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import PropTypes from 'prop-types'
import { DatePicker } from '@mui/lab'
import dayjs from 'dayjs'

function AddDate({ labelName, handleAddDate }) {
   const [dateDetails, setDateDetails] = useState({
      Date: '',
   })
   useEffect(() => {
      console.log('Date details on change of its value: ', dateDetails)
   }, [dateDetails])

   function handleSubmitDate(e) {
      e.preventDefault()

      if (dateDetails.Date === '') {
         alert('Please add some date.')
         return
      }
      handleAddDate(dateDetails)

      reset()
   }

   function reset() {
      setDateDetails({
         Date: '',
      })
   }

   return (
      <Box>
         <Box>
            <DatePicker
               label={labelName}
               name="Date"
               value={dateDetails.Date != null ? dayjs(dateDetails.Date) : null}
               format="DD-MMM-YYYY"
               onChange={(newValue) =>
                  setDateDetails({
                     ...dateDetails,
                     Date: new Date(newValue),
                  })
               }
               size="small"
               views={['year', 'month', 'day']}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            />
         </Box>
         <div className={myStyles.text_align_center}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               sx={{ my: 1 }}
               onClick={(e) => {
                  handleSubmitDate(e)
               }}
               disabled={dateDetails.Date === ''}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddDate.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleAddDate: PropTypes.func.isRequired,
}

export default AddDate
