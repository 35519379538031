import React, { useEffect, useState } from 'react'

import { Box, Card, CardContent, CardMedia, Grid, Tabs, Typography } from '@mui/material'

import styles from './PParties.module.css'
import { useTheme } from '@mui/material/styles'
import { TabContext } from '@mui/lab'
import SwipeableViews from 'react-swipeable-views'
import { StyledTab2, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import { useNavigate, useParams } from 'react-router-dom'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import PPartiesNational from './PPartiesNational'
import PPartiesState from './PPartiesState'
import PPartiesUnrecognised from './PPartiesUnrecognised'
import PPartiesUnRegistered from './PPartiesUnRegistered'

function PParties() {
   const { countryName, countryId } = useParams()
   const docId = 'PoliticalParties-' + countryId
   console.log('parameters from link - countryId: ', countryId, 'Name: ', countryName)
   console.log('docId: ', docId)

   const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()

   const theme = useTheme()
   const [value, setValue] = useState(0)

   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [partiesBase, setPartiesBase] = useState('')
   const [fetchStatus, setFetchStatus] = useState('idle')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchParties(docId)
      }
   }, [])

   const fetchParties = async (docId) => {
      try {
         const docRef = doc(db, 'BaseLists', docId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            setPartiesBase({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   const [countryComplete, setCountryComplete] = useState('')

   useEffect(() => {
      if (fetchStatus !== 'idle') {
         const selectedCountryComplete = countries.find((item) => item.id === countryId)
         if (selectedCountryComplete.Name !== countryName) {
            navigate('/', { replace: true })
         }
         setCountryComplete(selectedCountryComplete)
      }
   }, [partiesBase])

   return (
      <div className={styles.main_div}>
         <Card
            sx={{
               my: 1,
               py: 1,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundImage: 'linear-gradient(to right, #403b4a, #d6d6d6)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto', fontWeight: 700 }}>
                  {partiesBase && (
                     <div>
                        <Typography
                           variant="subtitle1"
                           color="#ffebcd"
                           component="div"
                           sx={{ fontWeight: 600, textAlign: 'center' }}
                        >
                           Political Parties Of
                        </Typography>
                        <Typography
                           component="div"
                           variant="h5"
                           sx={{ fontWeight: 700, textAlign: 'center' }}
                           color="#FFFFFF"
                        >
                           {countryName}
                        </Typography>
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ width: 151, borderRadius: 1, mr: 1 }}
               image={countryComplete.Flag}
               alt="Live from space album cover"
            />
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab2 label="National Level" {...a11yProps(0)} />
                  <StyledTab2 label="State Level" {...a11yProps(1)} />
                  <StyledTab2 label="Registered (Un-recognised)" {...a11yProps(2)} />
                  <StyledTab2 label="Un-Registered" {...a11yProps(3)} />
               </Tabs>
            </div>
            <Grid container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid item xs={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                        id="swipeableee"
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesNational props={partiesBase} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesState props={partiesBase} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesUnrecognised props={partiesBase} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {partiesBase && partiesBase !== '' && <PPartiesUnRegistered props={partiesBase} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid>
               <Grid item xs={4} sx={{ backgroundColor: 'yellow' }}>
                  xs=4
               </Grid>
            </Grid>
         </TabContext>
      </div>
   )
}

export default PParties
