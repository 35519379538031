import React from 'react'

import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import myStyles from './Pager.module.css'

function Pager({
   totalCount,
   totalPages,
   presentPage,
   showingPresentCountX,
   showingPresentCountY,
   handlePreviousClick,
   handleNextClick,
}) {
   function handlePrevious(e) {
      e.preventDefault()

      handlePreviousClick()
   }

   function handleNext(e) {
      e.preventDefault()

      handleNextClick()
   }

   return (
      <div className={myStyles.pagerContainer}>
         <div className={myStyles.pagerRangeDisplay}>
            <Typography variant="body2" component="div" color="text.secondary">
               Showing <strong>{showingPresentCountX}</strong> to <strong>{showingPresentCountY}</strong> of{' '}
               <strong>{totalCount}</strong> results
            </Typography>
         </div>

         <div className={myStyles.pagerNavigator}>
            <Button
               disabled={presentPage <= 1}
               onClick={(e) => {
                  handlePrevious(e)
               }}
               sx={{ py: 0 }}
            >
               <ArrowBackIosRounded sx={{ bgcolor: 'text.primary', color: 'background.paper', p: 0, m: 0 }} />
            </Button>
            <span>{presentPage}</span>
            <Button
               disabled={presentPage === totalPages || totalPages === 0}
               onClick={(e) => {
                  handleNext(e)
               }}
               sx={{ py: 0 }}
               size="small"
            >
               <ArrowForwardIosRounded
                  // disabled={presentPage === totalPages || totalPages === 0}
                  sx={{ bgcolor: 'text.primary', color: 'background.paper', p: 0, m: 0 }}
               />
            </Button>
         </div>
      </div>
   )
}

Pager.propTypes = {
   totalCount: PropTypes.number.isRequired,
   totalPages: PropTypes.number.isRequired,
   presentPage: PropTypes.number.isRequired,
   showingPresentCountX: PropTypes.number.isRequired,
   showingPresentCountY: PropTypes.number.isRequired,
   handlePreviousClick: PropTypes.func.isRequired,
   handleNextClick: PropTypes.func.isRequired,
}

export default Pager
