import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import { doc, getDoc } from 'firebase/firestore'

import styles from './PParty.module.css'
import { Box, Card, CardContent, CardMedia, Tabs, Typography } from '@mui/material'
import { TabContext } from '@mui/lab'

import SwipeableViews from 'react-swipeable-views'
import { StyledTab, TabPanel, a11yProps } from '../../../Components/TabPanelSystem'
import { db } from '../../../FirebaseConfig'
import PPartyBasicInfo from '../PPartyBasicInfo/PPartyBasicInfo'
import Grid from '@mui/material/Unstable_Grid2'
import PPartyUnits from '../PPartyUnits/PPartyUnits'
import PPartyMems from '../PPartyMems/PPartyMems'
import PPartyRules from '../PPartyRules/PPartyRules'
import PPartyManifestoes from '../PPartyManifestoes/PPartyManifestoes'
import PPartyCampaigns from '../PPartyCampaigns/PPartyCampaigns'

function PParty() {
   const { pPartyId, pPartyName } = useParams()
   console.log('parameters from link: ', pPartyId, '-', pPartyName)

   const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [pParty, setPParty] = useState('')
   const [fetchStatus, setFetchStatus] = useState('idle')

   const fetchPParty = async (pPartyId) => {
      try {
         const docRef = doc(db, 'PoliticalParties', pPartyId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            console.log('data on fetch: ', docSnap.data())
            setPParty({ ...docSnap.data(), id: docSnap.id })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      if (fetchStatus === 'idle') {
         console.log('fetch status before fetching party: ', fetchStatus)
         console.log('pParty id  before fetching party', pPartyId)
         fetchPParty(pPartyId)
         console.log('crossed call to fetch party')
         console.log('pparty value from initial useeffect: ', pParty)
      } else {
         console.log('did not fetch')
      }
   }, [])

   return (
      <div className={styles.main_div}>
         <Card
            sx={{
               my: 1,
               py: 0,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#1B1212',
               backgroundImage: 'linear-gradient(to right, #403b4a, #d6d6d6)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto', fontWeight: 700 }}>
                  {pParty && (
                     <div>
                        <Typography variant="h5" color="#FFFFFF" component="div" sx={{ fontWeight: 600 }}>
                           {pParty.NameLocal}
                        </Typography>
                        <Typography variant="h5" color="#FFFFFF" component="div" sx={{ fontWeight: 600 }}>
                           {pParty.NameHindi}
                        </Typography>
                        <Typography component="div" variant="h5" sx={{ fontWeight: 700 }} color="#FFFFFF">
                           {pParty.Name}
                        </Typography>
                        <Box
                           sx={{
                              display: 'inline',
                              mx: 0.5,
                              fontSize: 14,
                              color: '#ffffff',
                           }}
                        >
                           {pParty.Abbreviation}
                        </Box>
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll start:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(pParty.DateStart.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box>
                        <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(pParty.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ maxWidth: 150, maxHeight: 70, borderRadius: 1, mr: 1, objectFit: 'contain' }}
               image={pParty.Flag ? pParty.Flag : pParty.Symbol}
               alt={pParty.Name}
            />
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  // centered
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                     mb: 1,
                  }}
               >
                  <StyledTab label="Basic info" {...a11yProps(0)} />
                  <StyledTab label="Units" {...a11yProps(1)} />
                  <StyledTab label="Members" {...a11yProps(2)} />
                  <StyledTab label="Rules" {...a11yProps(3)} />
                  <StyledTab label="Manifestoes" {...a11yProps(4)} />
                  <StyledTab label="Campaigns" {...a11yProps(5)} />
               </Tabs>
            </div>
            <Grid container spacing={1} sx={{ mt: 1 }} disableEqualOverflow>
               <Grid xs={12} sm={8} md={8}>
                  <SwipeableViews
                     axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                     index={value}
                     onChangeIndex={handleChangeNavTabIndex}
                  >
                     <TabPanel value={value} index={0} dir={theme.direction}>
                        {pParty && <PPartyBasicInfo props={pParty} />}
                     </TabPanel>
                     <TabPanel value={value} index={1} dir={theme.direction}>
                        {pParty && <PPartyUnits props={pParty} />}
                     </TabPanel>
                     <TabPanel value={value} index={2} dir={theme.direction}>
                        {pParty && <PPartyMems props={pParty} />}
                     </TabPanel>
                     <TabPanel value={value} index={3} dir={theme.direction}>
                        {pParty && <PPartyRules props={pParty} />}
                     </TabPanel>
                     <TabPanel value={value} index={4} dir={theme.direction}>
                        {pParty && <PPartyManifestoes props={pParty} />}
                     </TabPanel>
                     <TabPanel value={value} index={5} dir={theme.direction}>
                        {pParty && <PPartyCampaigns props={pParty} />}
                     </TabPanel>
                  </SwipeableViews>
               </Grid>
               <Grid xs={12} sm={4} md={4}>
                  <Box sx={{ backgroundColor: 'Yellow', height: '100%' }}>Adverts</Box>
               </Grid>
            </Grid>
         </TabContext>
      </div>
   )
}

export default PParty
