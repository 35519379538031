import { Typography } from '@mui/material'
import React from 'react'

function ConstitutionLinks() {
   return (
      <div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #403b4a, #e36363, #e36363, #FFFFFF)',
               pl: 1,
            }}
         >
            Links:
         </Typography>
      </div>
   )
}

export default ConstitutionLinks
