import React, { useEffect, useState } from 'react'
import {
   collection,
   addDoc,
   doc,
   updateDoc,
   deleteDoc,
   arrayUnion,
   getDoc,
   arrayRemove,
   increment,
} from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'

import {
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   Link,
   MenuItem,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'

import styles from './GeoDivisions.module.css'
import Header from '../../Components/Header/Header'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../redux/reducers/countries-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { useNavigate } from 'react-router-dom'
import { selectUsers } from '../../redux/reducers/users-slice'

function BlocksAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [blockDetails, setBlockDetails] = useState({
      LGDCode: '',
      Name: '',
      NameLocal: '',
      NameHindi: '',
      NameCode: '',
      NameAlt: '',
      HeadQuarter: '',
      HeadQuarterLocal: '',
      HeadQuarterHindi: '',
      HeadQuarterAlt: '',
      Type: '',
      Flag: null,
      Symbol: null,
      ForLegis: false,
      Country_id: '',
      CountryName: '',
      State_id: '',
      StateName: '',
      Division_id: '',
      DivisionName: '',
      District_id: '',
      DistrictName: '',
      SubDistrict_id: '',
      SubDistrictName: '',
      DownLevel: 'Villages',
      Villages: [],
      Legislatures: [],
      VillagesCount: 0,
      Map: '',
      LocalLanguageEng: '',
      LocalLanguageHindi: '',
      LocalLanguageLocal: '',
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })
   useEffect(() => {
      console.log('Block details on change of its value: ', blockDetails)
   }, [blockDetails])
   const [showForm, setShowForm] = useState(false)

   // related to countries
   const countries = useSelector(selectCountries).countriesList
   const [selectedCountry, setSelectedCountry] = useState('')

   const handleChangeCountry = (event) => {
      event.preventDefault()

      setSelectedState('')
      setIndividualState('')
      setSelectedDivision('')
      setSelectedDistrict('')
      setIndividualDistrict('')
      // setSelectedSubDistrict('')
      // setIndividualSubDistrict('')
      setShowForm(false)
      setBlocksList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedCountry) {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)
         setBlockDetails({
            ...blockDetails,
            Country_id: selectedCountry,
            CountryName: selectedCountryComplete.Name,
         })
         fetchStatesList()
      } else {
         setBlockDetails({
            ...blockDetails,
            Country_id: '',
            CountryName: '',
         })
         setStatesList([])
      }
   }, [selectedCountry])

   // related to states
   const [statesList, setStatesList] = useState([])
   const [selectedState, setSelectedState] = useState('')
   const [hasDivisions, setHasDivisions] = useState(false)

   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   const [individualState, setIndividualState] = useState('')

   const handleChangeState = async (event) => {
      event.preventDefault()

      setSelectedDivision('')
      setSelectedDistrict('')
      setIndividualDistrict('')
      // setSelectedSubDistrict('')
      // setIndividualSubDistrict('')
      setShowForm(false)
      setBlocksList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedState(event.target.value)

            if (event.target.value !== '') {
               const docRef = doc(db, 'States', event.target.value)
               const docSnap = await getDoc(docRef)

               if (docSnap.exists()) {
                  setIndividualState({ id: docSnap.id, ...docSnap.data() })
               } else {
                  // docSnap.data() will be undefined in this case
                  setIndividualState('')
                  console.log('No such document!')
               }
            } else {
               setIndividualState('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedState && individualState) {
         setBlockDetails({ ...blockDetails, State_id: selectedState, StateName: individualState.Name })

         const sortedListDivisions = [...individualState.Divisions].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDivisionsList(sortedListDivisions)

         const sortedListDistricts = [...individualState.Districts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDistrictsListRoot(sortedListDistricts)

         if (individualState.DownLevel === 'Divisions') {
            setHasDivisions(true)
            setDistrictsList([])
         } else {
            setHasDivisions(false)
            setDistrictsList(sortedListDistricts)
         }
      } else {
         setBlockDetails({ ...blockDetails, State_id: '', StateName: '' })
         setDivisionsList([])
         setDistrictsListRoot([])
         setDistrictsList([])
         setHasDivisions(false)
      }
   }, [selectedState, individualState])

   // related to Divisions
   const [divisionsList, setDivisionsList] = useState('')
   const [selectedDivision, setSelectedDivision] = useState('')

   const handleChangeDivision = (event) => {
      event.preventDefault()

      setSelectedDistrict('')
      setIndividualDistrict('')
      // setSelectedSubDistrict('')
      // setIndividualSubDistrict('')
      setShowForm(false)
      setBlocksList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDivision(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDivision) {
         const selectedDivisionComplete = divisionsList.find((item) => item.Division_id === selectedDivision)
         setBlockDetails({
            ...blockDetails,
            Division_id: selectedDivision,
            DivisionName: selectedDivisionComplete.Name,
         })
         fetchDistrictsListByDivision()
      } else {
         setBlockDetails({
            ...blockDetails,
            Division_id: '',
            DivisionName: '',
         })
         setDistrictsList([])
      }
   }, [selectedDivision])

   // related to districts
   const [districtsListRoot, setDistrictsListRoot] = useState('')
   const [districtsList, setDistrictsList] = useState('')
   const [selectedDistrict, setSelectedDistrict] = useState('')
   const [individualDistrict, setIndividualDistrict] = useState('')

   const fetchDistrictsListByDivision = async () => {
      setDistrictsList(districtsListRoot.filter((item) => item.Division_id === selectedDivision))
   }

   const handleChangeDistrict = async (event) => {
      event.preventDefault()

      // setSelectedSubDistrict('')
      // setIndividualSubDistrict('')
      setShowForm(false)
      setBlocksList([])

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDistrict(event.target.value)

            if (event.target.value !== '') {
               const docRef = doc(db, 'Districts', event.target.value)
               const docSnap = await getDoc(docRef)

               if (docSnap.exists()) {
                  setIndividualDistrict({ id: docSnap.id, ...docSnap.data() })
               } else {
                  // docSnap.data() will be undefined in this case
                  setIndividualDistrict('')
               }
            } else {
               setIndividualDistrict('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDistrict && individualDistrict) {
         setBlockDetails({
            ...blockDetails,
            District_id: selectedDistrict,
            DistrictName: individualDistrict.Name,
         })

         // const sortedListSubDistricts = [...individualDistrict.SubDistricts].sort((a, b) => {
         //    if (b.Name > a.Name) {
         //       return -1
         //    } else if (b.Name < a.Name) {
         //       return 1
         //    } else {
         //       return 0
         //    }
         // })
         // setSubDistrictsList(sortedListSubDistricts)

         const sortedListBlocks = [...individualDistrict.Blocks].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setBlocksList(sortedListBlocks)
         setShowForm(true)
      } else {
         setBlockDetails({
            ...blockDetails,
            District_id: '',
            DistrictName: '',
         })
         // setSubDistrictsList([])

         setBlocksList([])
         setShowForm(false)
      }
   }, [selectedDistrict, individualDistrict])

   // related to subDistricts
   // const [subDistrictsList, setSubDistrictsList] = useState('')
   // const [selectedSubDistrict, setSelectedSubDistrict] = useState('')
   // const [individualSubDistrict, setIndividualSubDistrict] = useState('')

   // const handleChangeSubDistrict = async (event) => {
   //    event.preventDefault()

   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper) {
   //          setSelectedSubDistrict(event.target.value)

   //          if (event.target.value !== '') {
   //             const docRef = doc(db, 'SubDistricts', event.target.value)
   //             const docSnap = await getDoc(docRef)

   //             if (docSnap.exists()) {
   //                setIndividualSubDistrict({ id: docSnap.id, ...docSnap.data() })
   //             } else {
   //                // docSnap.data() will be undefined in this case
   //                setIndividualSubDistrict('')
   //                console.log('No such document!')
   //             }
   //          } else {
   //             setIndividualSubDistrict('')
   //          }
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }

   // useEffect(() => {
   //    if (selectedSubDistrict && individualSubDistrict) {
   //       setBlockDetails({
   //          ...blockDetails,
   //          SubDistrict_id: selectedSubDistrict,
   //          SubDistrictName: individualSubDistrict.Name,
   //       })

   //       const sortedListBlocks = [...individualSubDistrict.Blocks].sort((a, b) => {
   //          if (b.Name > a.Name) {
   //             return -1
   //          } else if (b.Name < a.Name) {
   //             return 1
   //          } else {
   //             return 0
   //          }
   //       })
   //       setBlocksList(sortedListBlocks)
   //       setShowForm(true)
   //    } else {
   //       setBlockDetails({
   //          ...blockDetails,
   //          SubDistrict_id: '',
   //          SubDistrictName: '',
   //       })
   //       setBlocksList([])
   //       setShowForm(false)
   //    }
   // }, [selectedSubDistrict, individualSubDistrict])

   // related to form filling and submission

   const [blocksList, setBlocksList] = useState([])

   function handleBlockDetails(e) {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setBlockDetails({ ...blockDetails, [e.target.name]: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const [lastBlock, setLastBlock] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddBlock = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               blockDetails.Name.length >= 3 &&
               blockDetails.NameHindi.length >= 3 &&
               blockDetails.LGDCode.length > 1 &&
               blockDetails.Country_id.length >= 2 &&
               blockDetails.State_id.length >= 2 &&
               blockDetails.District_id.length >= 2
            ) {
               blockDetails.CrBy = auth.currentUser.uid
               blockDetails.CrDt = new Date()
               blockDetails.CrIP = ip.data.ip
               console.log('blockDetails 1: ', blockDetails)
               setLastBlock(blockDetails.Name)
               setBlockDetails({
                  ...blockDetails,
                  Name: '',
                  NameLocal: '',
                  NameHindi: '',
                  LGDCode: '',
                  CrBy: '',
                  CrDt: '',
                  CrDtSys: '',
                  CrIP: '',
               })
               setUploading(true)
               try {
                  const docRef = await addDoc(collection(db, 'Blocks'), blockDetails)
                  blockDetails.id = docRef.id

                  const newDetailForInsertUpdate = {
                     Block_id: docRef.id,
                     Name: blockDetails.Name,
                     NameLocal: blockDetails.NameLocal,
                     NameHindi: blockDetails.NameHindi,
                     Flag: blockDetails.Flag,
                     Symbol: blockDetails.Symbol,
                     ForLegis: blockDetails.ForLegis,
                  }

                  // const docRefUpdateSubDistrict = doc(db, 'SubDistricts', selectedSubDistrict)
                  const docRefUpdateDistrict = doc(db, 'Districts', selectedDistrict)
                  const docRefUpdateState = doc(db, 'States', selectedState)
                  const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)

                  // await updateDoc(docRefUpdateSubDistrict, {
                  //    Blocks: arrayUnion(newDetailForInsertUpdate),
                  //    BlocksCount: increment(1),
                  // })

                  await updateDoc(docRefUpdateDistrict, {
                     Blocks: arrayUnion(newDetailForInsertUpdate),
                     BlocksCount: increment(1),
                  })

                  if (selectedDivision !== '') {
                     const docRefUpdateDivision = doc(db, 'Divisions', selectedDivision)
                     await updateDoc(docRefUpdateDivision, {
                        BlocksCount: increment(1),
                     })
                  }

                  await updateDoc(docRefUpdateState, {
                     BlocksCount: increment(1),
                  })

                  await updateDoc(docRefUpdateCountry, {
                     BlocksCount: increment(1),
                  })

                  setBlocksList([...blocksList, newDetailForInsertUpdate])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding sub district name')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const blockRef = doc(db, 'Blocks', id)
            try {
               await updateDoc(blockRef, {
                  ForLegis: !thisForLegis,
               })

               const docRefDistrict = doc(db, 'Districts', selectedDistrict)

               await updateDoc(docRefDistrict, {
                  Blocks: arrayRemove({
                     Block_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: thisForLegis,
                  }),
               })

               await updateDoc(docRefDistrict, {
                  Blocks: arrayUnion({
                     Block_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: !thisForLegis,
                  }),
               })

               setEditStatus(true)
               setForLegis({ id, thisForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         // eslint-disable-next-line array-callback-return
         districtsList.map((item) => {
            if (item.District_id === forLegis.id) {
               item.ForLegis = !forLegis.ForLegis
            }
         })
         setEditStatus(false)
      }
   }, [forLegis, editStatus])

   const handleDelete = async (e, id, thisName, thisNameLocal, thisNameHindi, thisFlag, thisSymbol, thisForLegis) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this district?')) {
               try {
                  await deleteDoc(doc(db, 'Blocks', id))

                  // const docRefSubDistrict = doc(db, 'SubDistricts', selectedSubDistrict)
                  const docRefDistrict = doc(db, 'Districts', selectedDistrict)
                  const docRefState = doc(db, 'States', selectedState)
                  const docRefCountry = doc(db, 'Countries', selectedCountry)

                  await updateDoc(docRefDistrict, {
                     Blocks: arrayRemove({
                        Block_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        ForLegis: thisForLegis,
                     }),
                     BlocksCount: increment(-1),
                  })

                  if (selectedDivision !== '') {
                     const docRefDivision = doc(db, 'Divisions', selectedDivision)
                     await updateDoc(docRefDivision, {
                        BlocksCount: increment(-1),
                     })
                  }

                  await updateDoc(docRefState, {
                     BlocksCount: increment(-1),
                  })

                  await updateDoc(docRefCountry, {
                     BlocksCount: increment(-1),
                  })

                  setBlocksList(blocksList.filter((item) => item.Block_id !== id))
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         {(isDataEditor || isSuper) && (
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }} useFlexGap flexWrap="wrap">
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link
                  href="/geo-political-divisions/blocks/admin"
                  color="#FFFFFF"
                  onClick={(e) => onClick(e)}
                  className={styles.link2}
               >
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         )}
         {(isDataEditor || isSuper) && (
            <Box
               sx={{
                  border: 1,
                  my: 1,
                  mx: 5,
                  pb: 1,
                  px: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-root': { width: '15ch' },
                  '& .MuiTextField-root': { width: '50ch' },
                  '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     fontWeight: 600,
                     textAlign: 'center',
                     fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                     ],
                  }}
               >
                  List a block
               </Typography>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel id="demo-select-small-label">Country</InputLabel>
                     <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedCountry}
                        label="Country"
                        onChange={handleChangeCountry}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {countries.map((item) => (
                           <MenuItem key={item.id} value={item.id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>State</InputLabel>
                     <Select value={selectedState} label="State" onChange={handleChangeState}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.State_id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {hasDivisions && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Division</InputLabel>
                     <Select value={selectedDivision} label="Division" onChange={handleChangeDivision}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {divisionsList &&
                           divisionsList.map((item) => (
                              <MenuItem key={item.Division_id} value={item.Division_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               )}
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>District</InputLabel>
                     <Select value={selectedDistrict} label="District" onChange={handleChangeDistrict}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {districtsList &&
                           districtsList.map((item) => (
                              <MenuItem key={item.District_id} value={item.District_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {/* <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Sub-District</InputLabel>
                     <Select value={selectedSubDistrict} label="Sub-District" onChange={handleChangeSubDistrict}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {subDistrictsList &&
                           subDistrictsList.map((item) => (
                              <MenuItem key={item.SubDistrict_id} value={item.SubDistrict_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div> */}
               {showForm && (
                  <div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleBlockDetails(e)
                           }}
                           value={blockDetails.Name}
                           name="Name"
                           id="name"
                           label="Name"
                           variant="outlined"
                           margin="dense"
                           required
                           fullWidth
                           size="small"
                           sx={{ paddingBottom: 0 }}
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleBlockDetails(e)
                           }}
                           value={blockDetails.NameLocal}
                           name="NameLocal"
                           id="nameLocal"
                           label="Local Name"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleBlockDetails(e)
                           }}
                           value={blockDetails.NameHindi}
                           name="NameHindi"
                           id="nameHindi"
                           label="Name in Hindi"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleBlockDetails(e)
                           }}
                           value={blockDetails.LGDCode}
                           name="LGDCode"
                           id="lGDCode"
                           label="LGDCode"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           required
                           fullWidth
                        ></TextField>
                     </div>
                     {/* <div>
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>Pesa Status</InputLabel>
                           <Select
                              value={blockDetails.PesaStatus}
                              name="PesaStatus"
                              label="Pesa Status"
                              onChange={(e) => {
                                 handleBlockDetails(e)
                              }}
                           >
                              <MenuItem value="">
                                 <em>-- select --</em>
                              </MenuItem>
                              <MenuItem value="Covered">Covered</MenuItem>
                              <MenuItem value="Partially covered">Partially covered</MenuItem>
                              <MenuItem value="Not Covered">Not Covered</MenuItem>
                           </Select>
                        </FormControl>
                     </div> */}
                     {/* <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleBlockDetails(e)
                           }}
                           value={blockDetails.Flag}
                           name="Flag"
                           id="flag"
                           label="Flag"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleBlockDetails(e)
                           }}
                           value={blockDetails.Symbol}
                           name="Symbol"
                           id="symbol"
                           label="Symbol"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div> */}
                     <div className={styles.alertDiv}>
                        {uploading && (
                           <MyLoaderCircularGradient title={'Listing the block ...'}></MyLoaderCircularGradient>
                        )}
                        {uploaded && (
                           <Alert variant="outlined" severity="success">
                              Block <strong>{lastBlock}</strong> listed successfully.
                           </Alert>
                        )}
                        {uploadingFailed && (
                           <Alert variant="outlined" severity="error">
                              Error occured! Block <strong>{lastBlock}</strong> could not be listed.
                           </Alert>
                        )}
                     </div>
                     <div>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           size="small"
                           onClick={(e) => {
                              handleAddBlock(e)
                           }}
                           disabled={
                              blockDetails.Name.length < 3 ||
                              blockDetails.NameHindi.length < 3 ||
                              blockDetails.LGDCode.length < 2 ||
                              blockDetails.Country_id.length < 2 ||
                              blockDetails.State_id.length < 2 ||
                              blockDetails.District_id.length < 2
                           }
                        >
                           Submit
                        </Button>
                     </div>
                  </div>
               )}
            </Box>
         )}

         <div className={styles.div200}>
            {(isDataEditor || isSuper) && (
               <div>
                  <Header title="List of blocks" />
                  {blocksList &&
                     blocksList.map((item, index) => (
                        <Box
                           key={item.Block_id}
                           alignItems="center"
                           sx={{
                              border: 1,
                              my: 1,
                              p: 0.5,
                              mx: 5,
                              borderRadius: 2,
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <div>{index + 1}.</div>
                           <div className={styles.state__details}>
                              <p className={styles.state__capital}>{item.Block_id}</p>
                              <h3 className={styles.state__name}>{item.Name}</h3>
                              <p className={styles.state__capital}>
                                 {item.NameLocal} {item.NameHindi}
                              </p>
                           </div>
                           <div className={item.ForLegis ? styles.statusOk : styles.statusFalse}>
                              {item.ForLegis ? 'Open for operation' : 'Not ready for operation'}
                           </div>
                           <div className={styles.editButtons}>
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={item.ForLegis ? 'error' : 'success'}
                                 size="small"
                                 sx={{ textTransform: 'none', marginBottom: 0.5, px: 1, py: 0, minWidth: 0 }}
                                 onClick={(e) => {
                                    handleOperation(
                                       e,
                                       item.Block_id,
                                       item.Name,
                                       item.NameLocal,
                                       item.NameHindi,
                                       item.Flag,
                                       item.Symbol,
                                       item.ForLegis,
                                    )
                                 }}
                              >
                                 {item.ForLegis ? 'Stop operation' : 'Start operation'}
                              </Button>
                              {isDataEditor && (
                                 <Button
                                    type="submit"
                                    align="center"
                                    variant="contained"
                                    color={'error'}
                                    size="small"
                                    sx={{ textTransform: 'none', px: 1, py: 0, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleDelete(
                                          e,
                                          item.Block_id,
                                          item.Name,
                                          item.NameLocal,
                                          item.NameHindi,
                                          item.Flag,
                                          item.Symbol,
                                          item.ForLegis,
                                       )
                                    }}
                                 >
                                    Delete
                                 </Button>
                              )}
                           </div>
                        </Box>
                     ))}
               </div>
            )}
         </div>
      </div>
   )
}

export default BlocksAdmin
