import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './MainFooter.module.css'

function MainFooter() {
   return (
      <div className={styles.footer}>
         <NavLink className={styles.footerText} to="/about">
            About
         </NavLink>{' '}
         <span className={styles.footerText}>|</span>
         <NavLink className={styles.footerText} to="/contact">
            Contact
         </NavLink>{' '}
         <span className={styles.footerText}>|</span>
         <NavLink className={styles.footerText} to="/privacy">
            Privacy
         </NavLink>{' '}
         <span className={styles.footerText}>|</span>
         <NavLink className={styles.footerText} to="/terms">
            Terms of use
         </NavLink>
      </div>
   )
}

export default MainFooter
