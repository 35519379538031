import React, { useEffect, useState } from 'react'

import {
   addDoc,
   collection,
   deleteDoc,
   doc,
   endBefore,
   getCountFromServer,
   getDocs,
   limit,
   limitToLast,
   orderBy,
   query,
   startAfter,
   where,
} from 'firebase/firestore'

import { auth, db } from '../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../redux/reducers/countries-slice'
import { selectUsers } from '../../redux/reducers/users-slice'

import styles from './GeoDivisions.module.css'
import {
   Alert,
   Avatar,
   Box,
   Button,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import Header from '../../Components/Header/Header'
import { ArrowBackIosRounded, ArrowForwardIosRounded, LocalPostOffice } from '@mui/icons-material'
import Pager from '../../Components/Pager/Pager'

function PinCodes() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const [selectedCountry, setSelectedCountry] = useState([])

   const [statesList, setStatesList] = useState([])
   const [selectedState, setSelectedState] = useState([])

   const [districtsList, setDistrictsList] = useState([])
   const [selectedDistrict, setSelectedDistrict] = useState([])

   const [pinCodesList, setPinCodesList] = useState([])
   const [uploadingPinCodesList, setUploadingPinCodesList] = useState(false)
   const pageSize = 10
   const [totalCount, setTotalCount] = useState(0)
   const [totalPages, setTotalPages] = useState(0)
   const [presentPage, setPresentPage] = useState(1)

   const [showingPresentCountX, setShowingPresentCountX] = useState(0)
   const [showingPresentCountY, setShowingPresentCountY] = useState(0)

   const [direction, setDirection] = useState('')
   const [firstDoc, setFirstDoc] = useState(undefined)
   const [lastDoc, setLastDoc] = useState(undefined)
   const [fetchStatus, setFetchStatus] = useState('initial')

   const [pinCodeDetails, setPinCodeDetails] = useState({
      Country_id: '',
      CountryName: '',
      State_id: '',
      StateName: '',
      District_id: '',
      DistrictName: '',
      Office: '',
      OfficeType: '',
      PinCode: '',
      Parent_id: '',
      ParentPinCode: '',
      ParentOffice: '',
      ParentOfficeType: '',
      Deleted: false,
      ForAddress: true,
   })

   useEffect(() => {
      console.log('Pin code details on change: ', pinCodeDetails)
   }, [pinCodeDetails])

   // related to countries
   const countriesList = useSelector(selectCountries).countriesList

   const handleCountryChange = (event) => {
      setPinCodesList([])
      setSelectedCountry(event.target.value)
      const selectedCountryComplete = countriesList.find((item) => item.id === event.target.value)
      setPinCodeDetails({
         ...pinCodeDetails,
         CountryName: selectedCountryComplete.Name,
         Country_id: event.target.value,
      })
   }
   useEffect(() => {
      setSelectedState('')
      setDistrictsList([])
      setSelectedDistrict('')
      if (selectedCountry) {
         fetchStatesList()
      } else {
         setStatesList([])
      }
   }, [selectedCountry])

   // related to states

   const fetchStatesList = async () => {
      try {
         const q = query(collection(db, 'States'), where('Country_id', '==', selectedCountry), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         const statesListFetched = []
         querySnapshot.forEach((doc) => {
            statesListFetched.push({ id: doc.id, ...doc.data() })
         })
         setStatesList(statesListFetched)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   const handleStateChange = (event) => {
      setPinCodesList([])
      setSelectedState(event.target.value)
      const selectedStateComplete = statesList.find((item) => item.id === event.target.value)
      setPinCodeDetails({ ...pinCodeDetails, StateName: selectedStateComplete.Name, State_id: event.target.value })
   }

   useEffect(() => {
      // setPinCodeDetails({ ...districtDetails, State_id: selectedState })
      setSelectedDistrict('')
      if (selectedState) {
         fetchDistrictsList()
      } else {
         setDistrictsList([])
      }
   }, [selectedState])

   // related to districts

   const fetchDistrictsList = async () => {
      try {
         const q = query(collection(db, 'Districts'), where('State_id', '==', selectedState), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         const districtsListFetched = []
         querySnapshot.forEach((doc) => {
            districtsListFetched.push({ id: doc.id, ...doc.data() })
         })
         setDistrictsList(districtsListFetched)
         console.log('Districts list on fetch: ', districtsListFetched)
      } catch (err) {
         console.log('error', err)
      }
   }

   const handleDistrictChange = (event) => {
      setSelectedDistrict(event.target.value)
      const selectedDistrictComplete = districtsList.find((item) => item.id === event.target.value)
      setPinCodeDetails({
         ...pinCodeDetails,
         DistrictName: selectedDistrictComplete.Name,
         District_id: event.target.value,
      })
   }

   useEffect(() => {
      if (selectedDistrict) {
         fetchPinCodesListFirst()
      } else {
         setPinCodesList([])
      }
   }, [selectedDistrict])

   // related to pin codes list

   const fetchPinCodesListFirst = async () => {
      try {
         setUploadingPinCodesList(true)
         setPresentPage(1)
         // fetch first set of data with limit
         const q = query(
            collection(db, 'PinCodes'),
            where('District_id', '==', selectedDistrict),
            orderBy('PinCode', 'asc'),
            limit(pageSize),
         )
         const querySnapshot = await getDocs(q)
         const pinCodesListFetched = []
         querySnapshot.forEach((doc) => {
            pinCodesListFetched.push({ id: doc.id, ...doc.data() })
         })
         setPinCodesList(pinCodesListFetched)
         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1])
         setFirstDoc(querySnapshot.docs[0])

         // fetch count of all data without limit
         const qCount = query(
            collection(db, 'PinCodes'),
            where('District_id', '==', selectedDistrict),
            orderBy('PinCode', 'asc'),
         )
         const countSnapshot = await getCountFromServer(qCount)
         const count = countSnapshot.data().count

         setTotalCount(count)
         console.log('count: ', count)
         const numPages = Math.ceil(count / pageSize)
         setTotalPages(numPages)
         setFetchStatus('sequel')

         setShowingPresentCountX(1)
         if (numPages > 1) {
            setShowingPresentCountY(pageSize)
         } else {
            setShowingPresentCountY(count)
         }

         setUploadingPinCodesList(false)
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchPinCodesListNext = async () => {
      try {
         setUploadingPinCodesList(true)
         let q
         if (direction === 'next' && lastDoc) {
            q = query(
               collection(db, 'PinCodes'),
               where('District_id', '==', selectedDistrict),
               orderBy('PinCode', 'asc'),
               startAfter(lastDoc),
               limit(pageSize),
            )
         } else if (direction === 'prev' && firstDoc) {
            q = query(
               collection(db, 'PinCodes'),
               where('District_id', '==', selectedDistrict),
               orderBy('PinCode', 'asc'),
               endBefore(firstDoc),
               limitToLast(pageSize),
            )
         }

         const querySnapshot = await getDocs(q)
         const pinCodesListFetched = []
         querySnapshot.forEach((doc) => {
            pinCodesListFetched.push({ id: doc.id, ...doc.data() })
         })
         setPinCodesList(pinCodesListFetched)
         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1])
         setFirstDoc(querySnapshot.docs[0])
         setUploadingPinCodesList(false)
      } catch (err) {
         console.log('error', err)
      }
   }

   // Fetch paginated data based on page
   useEffect(() => {
      if (fetchStatus !== 'initial') {
         fetchPinCodesListNext()
      }
   }, [presentPage])

   // Handle pagination button clicks
   const handlePreviousClick = () => {
      if (presentPage === 1) return
      setDirection('prev')
      setPresentPage(presentPage - 1)

      setShowingPresentCountX(showingPresentCountX - pageSize)
      setShowingPresentCountY(showingPresentCountX - 1)
   }

   const handleNextClick = () => {
      if (presentPage !== totalPages) {
         setDirection('next')
         setPresentPage(presentPage + 1)
         setShowingPresentCountX(showingPresentCountX + pageSize)
         if (presentPage + 1 < totalPages) {
            setShowingPresentCountY(showingPresentCountY + pageSize)
         } else {
            setShowingPresentCountY(totalCount)
         }
      }
   }

   // related to form filling and submit

   function handlePinCodeDetails(e) {
      setPinCodeDetails({ ...pinCodeDetails, [e.target.name]: e.target.value })
   }

   const [lastPinCode, setLastPinCode] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddPinCode = async (e) => {
      e.preventDefault()
      if (
         pinCodeDetails.Country_id.length >= 8 &&
         pinCodeDetails.State_id.length >= 8 &&
         pinCodeDetails.District_id.length >= 8 &&
         pinCodeDetails.PinCode.length >= 4
      ) {
         pinCodeDetails.CrBy = auth.currentUser.uid
         pinCodeDetails.CrDt = new Date()
         pinCodeDetails.CrIP = ip.data.ip
         console.log('pinCodeDetails 1: ', pinCodeDetails)
         setLastPinCode(`${pinCodeDetails.Office}  ${pinCodeDetails.PinCode}`)
         setPinCodeDetails({
            ...pinCodeDetails,
            Office: '',
            OfficeType: '',
            PinCode: '',
         })
         setUploading(true)
         console.log('pinCodeDetails 2: ', pinCodeDetails, ' uploading: ', uploading)
         try {
            const docRef = await addDoc(collection(db, 'PinCodes'), pinCodeDetails)
            pinCodeDetails.id = docRef.id
            setPinCodesList([pinCodeDetails, ...pinCodesList])
            setUploading(false)
            setUploaded(true)
            setUploadingFailed(false)
         } catch (error) {
            alert('Error adding pin code')
            setUploading(false)
            setUploaded(false)
            setUploadingFailed(false)
         }
      }
   }

   // Related to edits

   const handleDelete = async (id) => {
      setUploading(false)
      setUploaded(false)
      setUploadingFailed(false)
      if (confirm('Are you sure you want to erase this pin code?')) {
         try {
            await deleteDoc(doc(db, 'PinCodes', id))
            setPinCodesList(pinCodesList.filter((item) => item.id !== id))
         } catch (error) {
            alert('Error deleting pin code')
         }
      } else {
         //
      }
   }

   const handleEdit = async (id) => {
      alert('no edit yet')
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         <div>
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }}>
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link
                  href="/geo-political-divisions/pin-codes/admin"
                  onClick={(e) => onClick(e)}
                  color="#ffffff"
                  className={styles.link2}
               >
                  PinCodes
               </Link>
            </Stack>
         </div>
         <Box
            sx={{
               border: 1,
               my: 1,
               mx: 5,
               pb: 1,
               px: 2,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch' },
               '& .MuiTextField-root': { width: '50ch' },
               '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Typography
               variant="h6"
               sx={{
                  fontWeight: 600,
                  textAlign: 'center',
                  fontFamily: [
                     '-apple-system',
                     'BlinkMacSystemFont',
                     '"Segoe UI"',
                     'Roboto',
                     '"Helvetica Neue"',
                     'Arial',
                     'sans-serif',
                     '"Apple Color Emoji"',
                     '"Segoe UI Emoji"',
                     '"Segoe UI Symbol"',
                  ],
               }}
            >
               List a pin code
            </Typography>
            <div>
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel id="demo-select-small-label">Country</InputLabel>
                  <Select
                     labelId="demo-select-small-label"
                     id="demo-select-small"
                     value={selectedCountry}
                     label="Country"
                     onChange={handleCountryChange}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {countriesList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                           {item.Name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </div>
            <div>
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                     labelId="state-label"
                     id="state"
                     value={selectedState}
                     label="State"
                     onChange={handleStateChange}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {statesList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                           {item.Name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </div>
            <div>
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel id="district-label">District</InputLabel>
                  <Select
                     labelId="district-label"
                     id="district"
                     value={selectedDistrict}
                     label="District"
                     onChange={handleDistrictChange}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {/* {districtsList &&
                        districtsList.length > 0 && */}
                     {districtsList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                           {item.Name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handlePinCodeDetails(e)
                  }}
                  value={pinCodeDetails.Office}
                  name="Office"
                  id="office"
                  label="Office name"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            {/* <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handlePinCodeDetails(e)
                  }}
                  value={pinCodeDetails.OfficeType}
                  name="OfficeType"
                  id="officeType"
                  label="Office type"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div> */}
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handlePinCodeDetails(e)
                  }}
                  value={pinCodeDetails.PinCode}
                  name="PinCode"
                  id="pinCode"
                  label="Postal code"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
               ></TextField>
            </div>
            <div className={styles.alertDiv}>
               {uploading && <MyLoaderCircularGradient title={'Listing the pin code ...'}></MyLoaderCircularGradient>}
               {uploaded && (
                  <Alert variant="outlined" severity="success">
                     Postal code <strong>{lastPinCode}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailed && (
                  <Alert variant="outlined" severity="error">
                     Error occured! Postal code <strong>{lastPinCode}</strong> could not be listed.
                  </Alert>
               )}
            </div>
            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleAddPinCode(e)
                  }}
                  disabled={
                     pinCodeDetails.PinCode.length < 4 ||
                     pinCodeDetails.Country_id.length < 8 ||
                     pinCodeDetails.State_id.length < 8 ||
                     pinCodeDetails.District_id.length < 8
                  }
               >
                  Submit
               </Button>
            </div>
         </Box>
         <Header
            title={selectedDistrict ? `List of pin codes for ${pinCodeDetails.DistrictName}` : 'List of pin codes'}
         />
         <div className={styles.div200}>
            {uploadingPinCodesList && (
               <MyLoaderCircularGradient title={'Fetching the pin code list ...'}></MyLoaderCircularGradient>
            )}
            {!uploadingPinCodesList && pinCodesList && pinCodesList.length > 0 && (
               <div>
                  <Pager
                     totalCount={totalCount}
                     totalPages={totalPages}
                     presentPage={presentPage}
                     showingPresentCountX={showingPresentCountX}
                     showingPresentCountY={showingPresentCountY}
                     handleNextClick={handleNextClick}
                     handlePreviousClick={handlePreviousClick}
                  ></Pager>
                  <List dense>
                     {pinCodesList.map((item, index) => (
                        <div key={item.id}>
                           <ListItem
                              key={item.id}
                              secondaryAction={
                                 <div>
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={() => {
                                          handleDelete(item.id)
                                       }}
                                    >
                                       <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={() => {
                                          handleEdit(item.id)
                                       }}
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </div>
                              }
                           >
                              <ListItemAvatar>
                                 <Avatar>
                                    <LocalPostOffice />
                                 </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                 secondaryTypographyProps={{ align: 'left' }}
                                 primary={item.PinCode}
                                 secondary={item.Office}
                              />
                           </ListItem>
                           <Divider />
                        </div>
                     ))}
                  </List>
                  {/* Pagination */}
                  <div className={styles.pagerContainer}>
                     <div className={styles.pagerRangeDisplay}>
                        <Typography variant="body2" component="div" color="text.secondary">
                           Showing <strong>{showingPresentCountX}</strong> to <strong>{showingPresentCountY}</strong> of{' '}
                           <strong>{totalCount}</strong> results
                        </Typography>
                     </div>

                     <div className={styles.pagerNavigator}>
                        <Button disabled={presentPage <= 1} onClick={handlePreviousClick}>
                           <ArrowBackIosRounded sx={{ bgcolor: 'text.primary', color: 'background.paper' }} />
                        </Button>
                        <span>{presentPage}</span>
                        <Button
                           disabled={presentPage === totalPages || totalPages === 0}
                           onClick={handleNextClick}
                           sx={{ m: 0, p: 0 }}
                        >
                           <ArrowForwardIosRounded sx={{ bgcolor: 'text.primary', color: 'background.paper' }} />
                        </Button>
                     </div>
                  </div>
               </div>
            )}
            {(!pinCodesList || pinCodesList.length === 0) && <div>no pincodes available</div>}
         </div>
      </div>
   )
}

export default PinCodes
