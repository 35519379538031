import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { Avatar, Box, Divider, Link, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'

function PPartiesUnrecognised({ props }) {
   const partiesBase = props
   const user = useSelector(selectUsers)
   const ip = user.ip

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [partiesList, setPartiesList] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         const partiesListBase = partiesBase.Parties
         const partiesListFiltered = partiesListBase.filter(
            (party) => party.ECIStatus === 'Registered (Un-Recognised)' || party.ECIStatus === '',
         )

         const sortedListParties = partiesListFiltered.sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setPartiesList(sortedListParties)

         setFetchStatus('success')
      }
   }, [])

   console.log(partiesBase, ip)
   return (
      <div>
         <Box
            sx={{
               px: 2,
            }}
         >
            <List dense>
               {partiesList.map &&
                  partiesList.map.length > 0 &&
                  partiesList.map((item, index) => (
                     <Link
                        key={item.id}
                        href={`/political-party/${item.Name}/${item.Party_id}`}
                        sx={{ textDecoration: 'none !important', px: 0 }}
                     >
                        <ListItem key={item.id} sx={{ padding: 0 }}>
                           <ListItemAvatar>
                              <Avatar alt="Travis Howard" src={item.Flag} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left' }}
                              primary={item.Name}
                              secondary={
                                 <>
                                    {item.NameHindi}
                                    <br />
                                    <strong>{item.Abbreviation}</strong>
                                    <br />
                                    {item.NameLocal}
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </Link>
                  ))}
            </List>
         </Box>
      </div>
   )
}

PPartiesUnrecognised.propTypes = {
   props: PropTypes.object.isRequired,
}

export default PPartiesUnrecognised
