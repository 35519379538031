import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import validator from 'validator'

function AddWeblink({ handleAddWeblink }) {
   //
   const [weblinkDetails, setWeblinkDetails] = useState({
      Weblink: '',
   })
   useEffect(() => {
      console.log('Party details on change of its value: ', weblinkDetails)
   }, [weblinkDetails])

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid URL')
      } else {
         setErrorMessage('Is Not Valid URL')
      }
   }

   function handleWeblinkDetails(event) {
      setWeblinkDetails({ ...weblinkDetails, [event.target.name]: event.target.value })
      validate(event.target.value)
   }

   function handleSubmitWeblink(e) {
      e.preventDefault()

      if (weblinkDetails.Text === '') {
         alert('Please add some text.')
         return
      }

      if (errorMessage !== '' || errorMessage === 'Is Valid URL') {
         handleAddWeblink(weblinkDetails)
      }

      reset()
   }

   function reset() {
      setWeblinkDetails({
         Weblink: '',
      })
   }

   return (
      <Box>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleWeblinkDetails}
               value={weblinkDetails.Weblink}
               name="Weblink"
               id="weblink"
               label="Website"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <br />
         <span
            style={{
               fontWeight: 'bold',
               color: 'red',
            }}
         >
            {errorMessage}
         </span>
         <div className={myStyles.margin_top_one}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               onClick={(e) => {
                  handleSubmitWeblink(e)
               }}
               disabled={errorMessage === '' || errorMessage === 'Is Not Valid URL'}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddWeblink.propTypes = {
   handleAddWeblink: PropTypes.func.isRequired,
}

export default AddWeblink
