export const TempData = [
   {
      NameHindi: 'अड्डू मजरा',
      'S No': 1,
      'Village LGD Code': 57489,
      'Village Name (In English)': 'Addu Majra (278)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57400,
      'Census2011 Code': 57489,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'अहमन',
      'S No': 2,
      'Village LGD Code': 57426,
      'Village Name (In English)': 'Ahman (175)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51100,
      'Census2011 Code': 57426,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'अलाउद्दीन मजरा',
      'S No': 3,
      'Village LGD Code': 57463,
      'Village Name (In English)': 'Alauddin Majra (301)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54800,
      'Census2011 Code': 57463,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'अंबाला सिटी',
      'S No': 4,
      'Village LGD Code': 934086,
      'Village Name (In English)': 'Ambala City (50)',
      'Village Name (In Local language)': 'अंबाला सिटी',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'अमिपुर',
      'S No': 5,
      'Village LGD Code': 57436,
      'Village Name (In English)': 'Amipur (258)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 52100,
      'Census2011 Code': 57436,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'आनंदपुर जलबेरा',
      'S No': 6,
      'Village LGD Code': 57371,
      'Village Name (In English)': 'Anandpur Jalbera (121)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 45400,
      'Census2011 Code': 57371,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बाकनौर',
      'S No': 7,
      'Village LGD Code': 57445,
      'Village Name (In English)': 'Baknour (282)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53000,
      'Census2011 Code': 57445,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बलाना',
      'S No': 8,
      'Village LGD Code': 57368,
      'Village Name (In English)': 'Balana (173)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 45100,
      'Census2011 Code': 57368,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बांबा',
      'S No': 9,
      'Village LGD Code': 57446,
      'Village Name (In English)': 'Bamba (302)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53100,
      'Census2011 Code': 57446,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बनहपुर',
      'S No': 10,
      'Village LGD Code': 57451,
      'Village Name (In English)': 'Banhpur (288)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53600,
      'Census2011 Code': 57451,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बरौल',
      'S No': 11,
      'Village LGD Code': 57488,
      'Village Name (In English)': 'Baraul (277)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57300,
      'Census2011 Code': 57488,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बरौला',
      'S No': 12,
      'Village LGD Code': 57487,
      'Village Name (In English)': 'Baraula (276)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57200,
      'Census2011 Code': 57487,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बारिंगन',
      'S No': 13,
      'Village LGD Code': 57428,
      'Village Name (In English)': 'Baringan (260)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51300,
      'Census2011 Code': 57428,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बतरोहण',
      'S No': 14,
      'Village LGD Code': 57458,
      'Village Name (In English)': 'Batrohan (294)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54300,
      'Census2011 Code': 57458,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बेढसां',
      'S No': 15,
      'Village LGD Code': 57490,
      'Village Name (In English)': 'Bedsaan (268)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57500,
      'Census2011 Code': 57490,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बेगो मजरा',
      'S No': 16,
      'Village LGD Code': 57409,
      'Village Name (In English)': 'Bego Majra (181)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 49400,
      'Census2011 Code': 57409,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बेहबलपुर',
      'S No': 17,
      'Village LGD Code': 57405,
      'Village Name (In English)': 'Behbalpur (170)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 49000,
      'Census2011 Code': 57405,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'भारी',
      'S No': 18,
      'Village LGD Code': 57424,
      'Village Name (In English)': 'Bhari (176)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50900,
      'Census2011 Code': 57424,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'भुन्नी',
      'S No': 19,
      'Village LGD Code': 57469,
      'Village Name (In English)': 'Bhunni (290)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55400,
      'Census2011 Code': 57469,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'भूरंगपुर',
      'S No': 20,
      'Village LGD Code': 57433,
      'Village Name (In English)': 'Bhurangpur (286)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51800,
      'Census2011 Code': 57433,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बीछपरी',
      'S No': 21,
      'Village LGD Code': 57499,
      'Village Name (In English)': 'Bichpari (270)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 58400,
      'Census2011 Code': 57499,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'बिशंगढ़',
      'S No': 22,
      'Village LGD Code': 57482,
      'Village Name (In English)': 'Bishangarh (305)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56700,
      'Census2011 Code': 57482,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'चुगना',
      'S No': 23,
      'Village LGD Code': 57462,
      'Village Name (In English)': 'Chugna (299)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54700,
      'Census2011 Code': 57462,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'ददियाना',
      'S No': 24,
      'Village LGD Code': 57353,
      'Village Name (In English)': 'Dadiana (36)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 42800,
      'Census2011 Code': 57353,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'डांगढ़ेरी',
      'S No': 25,
      'Village LGD Code': 57343,
      'Village Name (In English)': 'Dangdehri (33)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 40900,
      'Census2011 Code': 57343,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'डेंगरियन',
      'S No': 26,
      'Village LGD Code': 57430,
      'Village Name (In English)': 'Dangerian (261)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51500,
      'Census2011 Code': 57430,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'दानपुर',
      'S No': 27,
      'Village LGD Code': 57473,
      'Village Name (In English)': 'Danipur (322)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55800,
      'Census2011 Code': 57473,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'दौदपुर',
      'S No': 28,
      'Village LGD Code': 57450,
      'Village Name (In English)': 'Daudpur (287)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53500,
      'Census2011 Code': 57450,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'देवला मजरा',
      'S No': 29,
      'Village LGD Code': 57457,
      'Village Name (In English)': 'Delu Majra (293)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54200,
      'Census2011 Code': 57457,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'डेलू मजरा',
      'S No': 30,
      'Village LGD Code': 57457,
      'Village Name (In English)': 'Delu Majra (293)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54200,
      'Census2011 Code': 57457,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'देवीनगर',
      'S No': 31,
      'Village LGD Code': 57354,
      'Village Name (In English)': 'Devinagar (37)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 43000,
      'Census2011 Code': 57354,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'धुधाला',
      'S No': 32,
      'Village LGD Code': 57372,
      'Village Name (In English)': 'Dhudhala (109)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 45500,
      'Census2011 Code': 57372,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'धूलकोट',
      'S No': 33,
      'Village LGD Code': 57361,
      'Village Name (In English)': 'Dhulkot (60)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44200,
      'Census2011 Code': 57361,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'धुरकड़ा',
      'S No': 34,
      'Village LGD Code': 57365,
      'Village Name (In English)': 'Dhurkra (118)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44800,
      'Census2011 Code': 57365,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'दुराना',
      'S No': 35,
      'Village LGD Code': 57495,
      'Village Name (In English)': 'Durana (271)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 58000,
      'Census2011 Code': 57495,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'घगड़ू',
      'S No': 36,
      'Village LGD Code': 57434,
      'Village Name (In English)': 'Ghagru (284)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51900,
      'Census2011 Code': 57434,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'घेल',
      'S No': 37,
      'Village LGD Code': 57356,
      'Village Name (In English)': 'Ghel (52)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 43200,
      'Census2011 Code': 57356,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'गोबिंदगढ़',
      'S No': 38,
      'Village LGD Code': 57419,
      'Village Name (In English)': 'Gobindgarh (255)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50400,
      'Census2011 Code': 57419,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'गोरसियन',
      'S No': 39,
      'Village LGD Code': 57464,
      'Village Name (In English)': 'Gorsian (309)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54900,
      'Census2011 Code': 57464,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'हमायूपुर',
      'S No': 40,
      'Village LGD Code': 57421,
      'Village Name (In English)': 'Humayupur (179)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50600,
      'Census2011 Code': 57421,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'इस्माइलपुर',
      'S No': 41,
      'Village LGD Code': 57431,
      'Village Name (In English)': 'Ismailpur (262)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51600,
      'Census2011 Code': 57431,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'जागोली',
      'S No': 42,
      'Village LGD Code': 57468,
      'Village Name (In English)': 'Jagoli (314)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55300,
      'Census2011 Code': 57468,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'जैतपुरा',
      'S No': 43,
      'Village LGD Code': 57475,
      'Village Name (In English)': 'Jaitpura (324)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56000,
      'Census2011 Code': 57475,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'जलालपुर',
      'S No': 44,
      'Village LGD Code': 57485,
      'Village Name (In English)': 'Jalalpur (275)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57000,
      'Census2011 Code': 57485,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'जंधेरी',
      'S No': 45,
      'Village LGD Code': 57474,
      'Village Name (In English)': 'Jandheri (323)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55900,
      'Census2011 Code': 57474,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'जंधली',
      'S No': 46,
      'Village LGD Code': 57510,
      'Village Name (In English)': 'Jandli (113)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44400,
      'Census2011 Code': 57510,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'जनसूआ',
      'S No': 47,
      'Village LGD Code': 57480,
      'Village Name (In English)': 'Jansua (315)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56500,
      'Census2011 Code': 57480,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'जनसूई',
      'S No': 48,
      'Village LGD Code': 57481,
      'Village Name (In English)': 'Jansui (308)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56600,
      'Census2011 Code': 57481,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'काकरू',
      'S No': 49,
      'Village LGD Code': 934087,
      'Village Name (In English)': 'Kakru (31)',
      'Village Name (In Local language)': 'काकरू',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कालाल माजरा',
      'S No': 50,
      'Village LGD Code': 57491,
      'Village Name (In English)': 'Kalal Majra (267)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57600,
      'Census2011 Code': 57491,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कलावर',
      'S No': 51,
      'Village LGD Code': 57472,
      'Village Name (In English)': 'Kalawar (320)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55700,
      'Census2011 Code': 57472,
      'Village Status': 'Inhabi tant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कलेरान',
      'S No': 52,
      'Village LGD Code': 57484,
      'Village Name (In English)': 'Kaleran (307)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56900,
      'Census2011 Code': 57484,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कालू माजरा',
      'S No': 53,
      'Village LGD Code': 57357,
      'Village Name (In English)': 'Kalu Majra (55)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 43400,
      'Census2011 Code': 57357,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कांगवाल',
      'S No': 54,
      'Village LGD Code': 57470,
      'Village Name (In English)': 'Kangwal (319)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55500,
      'Census2011 Code': 57470,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कनवला',
      'S No': 55,
      'Village LGD Code': 57511,
      'Village Name (In English)': 'Kanwla (110)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44500,
      'Census2011 Code': 57511,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कनवली',
      'S No': 56,
      'Village LGD Code': 57363,
      'Village Name (In English)': 'Kanwli (111)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44600,
      'Census2011 Code': 57363,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'खैरा',
      'S No': 57,
      'Village LGD Code': 57435,
      'Village Name (In English)': 'Khaira (281)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 52000,
      'Census2011 Code': 57435,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'खाना माजरा',
      'S No': 58,
      'Village LGD Code': 57448,
      'Village Name (In English)': 'Khana Majra (297)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53300,
      'Census2011 Code': 57448,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'खस्पुरा',
      'S No': 59,
      'Village LGD Code': 57440,
      'Village Name (In English)': 'Khaspura (266)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 52500,
      'Census2011 Code': 57440,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'खुरमपुर माजरी',
      'S No': 60,
      'Village LGD Code': 934090,
      'Village Name (In English)': 'Khurampur Majri (41)',
      'Village Name (In Local language)': 'खुरमपुर माजरी',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'खुर्चनपुर',
      'S No': 61,
      'Village LGD Code': 57454,
      'Village Name (In English)': 'Khurchanpur (290)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53900,
      'Census2011 Code': 57454,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कोंकपुर',
      'S No': 62,
      'Village LGD Code': 57493,
      'Village Name (In English)': 'Konkpur (273)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57800,
      'Census2011 Code': 57493,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'कुर्बानपुर',
      'S No': 63,
      'Village LGD Code': 57429,
      'Village Name (In English)': 'Kurbanpur (259)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51400,
      'Census2011 Code': 57429,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'लदाना',
      'S No': 64,
      'Village LGD Code': 57407,
      'Village Name (In English)': 'Ladana (172)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 49200,
      'Census2011 Code': 57407,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'लाखनौर साहिब',
      'S No': 65,
      'Village LGD Code': 57416,
      'Village Name (In English)': 'Lakhnaur Sahib (183)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50100,
      'Census2011 Code': 57416,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'ललाना',
      'S No': 66,
      'Village LGD Code': 57492,
      'Village Name (In English)': 'Lalana (269)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57700,
      'Census2011 Code': 57492,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'लिहरसा',
      'S No': 67,
      'Village LGD Code': 57358,
      'Village Name (In English)': 'Liharsa (54)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 43500,
      'Census2011 Code': 57358,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'लोहगढ़',
      'S No': 68,
      'Village LGD Code': 57345,
      'Village Name (In English)': 'Lohgarh (34)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 41100,
      'Census2011 Code': 57345,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'लोटन',
      'S No': 69,
      'Village LGD Code': 57455,
      'Village Name (In English)': 'Loton (291)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54000,
      'Census2011 Code': 57455,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'भूरंगपुर',
      'S No': 70,
      'Village LGD Code': 57400,
      'Village Name (In English)': 'Machhondi (124)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 48500,
      'Census2011 Code': 57400,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मलौर',
      'S No': 71,
      'Village LGD Code': 57432,
      'Village Name (In English)': 'Malour (283)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51700,
      'Census2011 Code': 57432,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      'NameHin di': 'मलवा',
      'S No': 72,
      'Village LGD Code': 57486,
      'Village Name (In English)': 'Malwa (274)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57100,
      'Census2011 Code': 57486,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मनकपुर',
      'S No': 73,
      'Village LGD Code': 57344,
      'Village Name (In English)': 'Manakpur (35)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 41000,
      'Census2011 Code': 57344,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मंडली',
      'S No': 74,
      'Village LGD Code': 57362,
      'Village Name (In English)': 'Mandli (59)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44300,
      'Census2011 Code': 57362,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मांका',
      'S No': 75,
      'Village LGD Code': 57422,
      'Village Name (In English)': 'Manka (178)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50700,
      'Census2011 Code': 57422,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मंसूरपुर',
      'S No': 76,
      'Village LGD Code': 57496,
      'Village Name (In English)': 'Mansurpur (249)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 58100,
      'Census2011 Code': 57496,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मर्दोन',
      'S No': 77,
      'Village LGD Code': 57500,
      'Village Name (In English)': 'Mardon (252)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 58500,
      'Census2011 Code': 57500,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मस्तपुर',
      'S No': 78,
      'Village LGD Code': 57427,
      'Village Name (In English)': 'Mastpur (257)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51200,
      'Census2011 Code': 57427,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मटेहरी जट्टान',
      'S No': 79,
      'Village LGD Code': 57370,
      'Village Name (In English)': 'Matehri Jattan (120)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 45300,
      'Census2011 Code': 57370,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मटेहरी शेखान',
      'S No': 80,
      'Village LGD Code': 57420,
      'Village Name (In English)': 'Matehri Shekhan (256)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50500,
      'Census2011 Code': 57420,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मेहलान',
      'S No': 81,
      'Village LGD Code': 57477,
      'Village Name (In English)': 'Mehlan (318)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56200,
      'Census2011 Code': 57477,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मेटलान',
      'S No': 82,
      'Village LGD Code': 57465,
      'Village Name (In English)': 'Metlan (298)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55000,
      'Census2011 Code': 57465,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मियां माजरा',
      'S No': 83,
      'Village LGD Code': 57447,
      'Village Name (In English)': 'Miyan Majra (300)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53200,
      'Census2011 Code': 57447,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मोहरी',
      'S No': 84,
      'Village LGD Code': 57417,
      'Village Name (In English)': 'Mohri (253)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50200,
      'Census2011 Code': 57417,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मुण्डा माजरा',
      'S No': 85,
      'Village LGD Code': 57449,
      'Village Name (In English)': 'Munda Majra (285)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53400,
      'Census2011 Code': 57449,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'मुजफ्फर',
      'S No': 86,
      'Village LGD Code': 57423,
      'Village Name (In English)': 'Muzafra (177)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50800,
      'Census2011 Code': 57423,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'नंदियाला',
      'S No': 87,
      'Village LGD Code': 57438,
      'Village Name (In English)': 'Nandiali (264)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 52300,
      'Census2011 Code': 57438,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'नंदियाला',
      'S No': 88,
      'Village LGD Code': 57456,
      'Village Name (In English)': 'Nanyola (292)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54100,
      'Census2011 Code': 57456,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'नसीरपुर',
      'S No': 89,
      'Village LGD Code': 934093,
      'Village Name (In English)': 'Nasirpur (115)',
      'Village Name (In Local language)': 'नसीरपुर',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'निहर्सा',
      'S No': 90,
      'Village LGD Code': 57466,
      'Village Name (In English)': 'Niharsa (310)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55100,
      'Census2011 Code': 57466,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'निहर्सी',
      'S No': 91,
      'Village LGD Code': 57461,
      'Village Name (In English)': 'Niharsi (311)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54600,
      'Census2011 Code': 57461,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'निजामपुर',
      'S No': 92,
      'Village LGD Code': 57355,
      'Village Name (In English)': 'Nizampur (53)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 43100,
      'Census2011 Code': 57355,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'नूरपुर',
      'S No': 93,
      'Village LGD Code': 57494,
      'Village Name (In English)': 'Nurpur (272)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 57900,
      'Census2011 Code': 57494,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'पंजोला',
      'S No': 94,
      'Village LGD Code': 57453,
      'Village Name (In English)': 'Panjola (289)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53800,
      'Census2011 Code': 57453,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'पट्टी आचारजा',
      'S No': 95,
      'Village LGD Code': 934095,
      'Village Name (In English)': 'Patti Acharjan (56)',
      'Village Name (In Local language)': 'पट्टी आचारजा',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'पट्टी जट्टान',
      'S No': 96,
      'Village LGD Code': 934101,
      'Village Name (In English)': 'Pattijatan (38)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'पट्टी कलाला',
      'S No': 97,
      'Village LGD Code': 934104,
      'Village Name (In English)': 'Patti Kalalan (21)',
      'Village Name (In Local language)': 'पट्टी कलाला',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'पट्टी मेहर',
      'S No': 98,
      'Village LGD Code': 934108,
      'Village Name (In English)': 'Patti Mehar (58)',
      'Village Name (In Local language)': 'पट्टी मेहर',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'पट्टी रंगरा',
      'S No': 99,
      'Village LGD Code': 934113,
      'Village Name (In English)': 'Patti Rangran (40)',
      'Village Name (In Local language)': 'पट्टी रंगरा',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'पट्टी सेखा',
      'S No': 100,
      'Village LGD Code': 934117,
      'Village Name (In English)': 'Patti Shekhan (39)',
      'Village Name (In Local language)': 'पट्टी सेखा',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'रसूलपुर',
      'S No': 101,
      'Village LGD Code': 57460,
      'Village Name (In English)': 'Rasulpur (312)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54500,
      'Census2011 Code': 57460,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'रत्तांगढ़ उर्फ नकटपुर',
      'S No': 102,
      'Village LGD Code': 57452,
      'Village Name (In English)': 'Rattangarh Urf Nakatpur (296)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 53700,
      'Census2011 Code': 57452,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'रावलान',
      'S No': 103,
      'Village LGD Code': 57406,
      'Village Name (In English)': 'Rawalan (171)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 49100,
      'Census2011 Code': 57406,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'रोशनपुरा',
      'S No': 104,
      'Village LGD Code': 57476,
      'Village Name (In English)': 'Roshanpura (325)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56100,
      'Census2011 Code': 57476,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'रुपू माजरा',
      'S No': 105,
      'Village LGD Code': 57366,
      'Village Name (In English)': 'Rupu Majra (116)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44900,
      'Census2011 Code': 57366,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सादोपुर',
      'S No': 106,
      'Village LGD Code': 946375,
      'Village Name (In English)': 'Sadopur (32)',
      'Village Name (In Local language)': 'सादोपुर (32)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'साहिबपुरा',
      'S No': 107,
      'Village LGD Code': 57497,
      'Village Name (In English)': 'Sahibpura (250)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 58200,
      'Census2011 Code': 57497,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सखरोआं',
      'S No': 108,
      'Village LGD Code': 57439,
      'Village Name (In English)': 'Sakhroan (265)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 52400,
      'Census2011 Code': 57439,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सराय महदूद',
      'S No': 109,
      'Village LGD Code': 57364,
      'Village Name (In English)': 'Sarai Mahdud (112)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 44700,
      'Census2011 Code': 57364,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सारंगपुर',
      'S No': 110,
      'Village LGD Code': 57367,
      'Village Name (In English)': 'Sarangpur (117)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 45000,
      'Census2011 Code': 57367,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सेगता',
      'S No': 111,
      'Village LGD Code': 57443,
      'Village Name (In English)': 'Segta (304)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 52800,
      'Census2011 Code': 57443,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सेगटी',
      'S No': 112,
      'Village LGD Code': 57483,
      'Village Name (In English)': 'Segti (306)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56800,
      'Census2011 Code': 57483,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'शेखूपुर',
      'S No': 113,
      'Village LGD Code': 57467,
      'Village Name (In English)': 'Shekhupur (313)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 55200,
      'Census2011 Code': 57467,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सिंघवाला',
      'S No': 114,
      'Village LGD Code': 934123,
      'Village Name (In English)': 'Singhawala (57)',
      'Village Name (In Local language)': 'सिंघवाला',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सोंदा',
      'S No': 115,
      'Village LGD Code': 57359,
      'Village Name (In English)': 'Sonda (114)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 43900,
      'Census2011 Code': 57359,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सोंटा',
      'S No': 116,
      'Village LGD Code': 57479,
      'Village Name (In English)': 'Sonta (316)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56400,
      'Census2011 Code': 57479,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सोंटी',
      'S No': 117,
      'Village LGD Code': 57478,
      'Village Name (In English)': 'Sonti (317)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 56300,
      'Census2011 Code': 57478,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सुब्बा अकबेरपुर',
      'S No': 118,
      'Village LGD Code': 934126,
      'Village Name (In English)': 'Suba Akbarpur (59)',
      'Village Name (In Local language)': 'सुब्बा अकबेरपुर',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सुल्लर',
      'S No': 119,
      'Village LGD Code': 57425,
      'Village Name (In English)': 'Sullar (174)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 51000,
      'Census2011 Code': 57425,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'सुल्तानपुर',
      'S No': 120,
      'Village LGD Code': 934129,
      'Village Name (In English)': 'Sultanpur (42)',
      'Village Na me (In Local language)': 'सुल्तानपुर',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 0,
      'Census2011 Code': 0,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'तर',
      'S No': 121,
      'Village LGD Code': 57437,
      'Village Name (In English)': 'Tar (263)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 52200,
      'Census2011 Code': 57437,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'तेजान',
      'S No': 122,
      'Village LGD Code': 57418,
      'Village Name (In English)': 'Tejan (254)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 50300,
      'Census2011 Code': 57418,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'उदयपुर',
      'S No': 123,
      'Village LGD Code': 57459,
      'Village Name (In English)': 'Udaipur (295)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 54400,
      'Census2011 Code': 57459,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'उगरा',
      'S No': 124,
      'Village LGD Code': 57374,
      'Village Name (In English)': 'Ugara (122)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 45700,
      'Census2011 Code': 57374,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
   {
      NameHindi: 'याकूबपुर',
      'S No': 125,
      'Village LGD Code': 57404,
      'Village Name (In English)': 'Yaqubpur (168)',
      Hierarchy: 'Ambala(Sub-District) / Ambala(District) / Haryana(State)',
      'Census 2001 Code': 48900,
      'Census2011 Code': 57404,
      'Village Status': 'Inhabitant',
      'Pesa Status': 'Not Covered',
   },
]
