import { createTheme, styled } from '@mui/material/styles'

import { Avatar } from '@mui/material'

export const theme = createTheme({
   palette: {
      background: {
         paper: '#fff',
      },
      text: {
         primary: '#173A5E',
         secondary: '#46505A',
      },
      action: {
         active: '#001E3C',
      },
      ochre: {
         main: '#E3D026',
         light: '#E9DB5D',
         dark: '#A29415',
         contrastText: '#242105',
      },
   },
})

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
   width: 50,
   height: 50,
   border: `0px solid ${theme.palette.background.paper}`,
}))

export const BigAvatar = styled(Avatar)(({ theme }) => ({
   width: 150,
   height: 150,
   border: `0px solid ${theme.palette.background.paper}`,
}))

function stringToColor(string) {
   let hash = 0
   let i

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
   }

   let color = '#'

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
   }
   /* eslint-enable no-bitwise */

   return color
}

export function stringAvatar(name) {
   return {
      sx: {
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar50(name) {
   return {
      sx: {
         width: 50,
         height: 50,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar50Single(name) {
   return {
      sx: {
         width: 50,
         height: 50,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
   }
}

export function stringAvatar80(name) {
   return {
      sx: {
         width: 80,
         height: 80,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
         // padding: 0.25,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar80Single(name) {
   return {
      sx: {
         width: 80,
         height: 80,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
   }
}

export function stringAvatar150(name) {
   return {
      sx: {
         width: 150,
         height: 150,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
   }
}

export function stringAvatar150Single(name) {
   return {
      sx: {
         width: 150,
         height: 150,
         border: `0px solid ${theme.palette.background.paper}`,
         bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
   }
}
