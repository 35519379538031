import { Outlet } from 'react-router-dom'
import MainHeader from '../Components/MainHeader/MainHeader'
import MainFooter from '../Components/MainFooter/MainFooter'

const Layout = () => {
   return (
      <>
         <MainHeader />

         <Outlet />

         <MainFooter />
      </>
   )
}

export default Layout
