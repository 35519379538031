import React, { useEffect, useState } from 'react'
import {
   collection,
   addDoc,
   doc,
   updateDoc,
   deleteDoc,
   arrayUnion,
   getDoc,
   arrayRemove,
   increment,
} from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'

import {
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   Link,
   MenuItem,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'

import styles from './GeoDivisions.module.css'
import Header from '../../Components/Header/Header'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../redux/reducers/countries-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { useNavigate } from 'react-router-dom'
import { selectUsers } from '../../redux/reducers/users-slice'

function DistrictsAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [districtDetails, setDistrictDetails] = useState({
      Name: '',
      NameOld: [],
      NameLocal: '',
      NameLocalOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameAlt: '',
      NameAltOld: [],
      HeadQuarter: '',
      HeadQuarterOld: [],
      HeadQuarter_alt: '',
      Type: '',
      Flag: null,
      FlagOld: [],
      Symbol: null,
      SymbolOld: [],
      ForLegis: false,
      Country_id: '',
      CountryName: '',
      State_id: '',
      StateName: '',
      Division_id: '',
      DivisionName: '',
      DownLevel: 'SubDistricts',
      SubDistricts: [],
      Legislatures: [],
      VillagesCount: 0,
      BlocksCount: 0,
      SubDistrictsCount: 0,
      LGDCode: '',
      CensusCode: [],
      Map: '',
      NameCode: '',
      LocalLanguageEng: '',
      LocalLanguageHindi: '',
      LocalLanguageLocal: '',
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })
   useEffect(() => {
      console.log('District details on change of its value: ', districtDetails)
   }, [districtDetails])
   const [show, setShow] = useState(false)

   // related to countries
   const countries = useSelector(selectCountries).countriesList
   const [selectedCountry, setSelectedCountry] = useState('')

   const handleChangeCountry = (event) => {
      event.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      setSelectedState('')
      setSelectedDivision('')
      setShow(false)
      setHasDivisions(false)
      setDistrictsList([])
      if (selectedCountry) {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)
         setDistrictDetails({
            ...districtDetails,
            Country_id: selectedCountry,
            CountryName: selectedCountryComplete.Name,
         })
         fetchStatesList()
      } else {
         setDistrictDetails({
            ...districtDetails,
            Country_id: '',
            CountryName: '',
         })
         setStatesList('')
      }
   }, [selectedCountry])

   // related to states
   const [statesList, setStatesList] = useState('')
   const [selectedState, setSelectedState] = useState('')
   const [hasDivisions, setHasDivisions] = useState(false)

   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   const [individualState, setIndividualState] = useState('')

   const handleChangeState = async (event) => {
      event.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedState(event.target.value)

            const docRef = doc(db, 'States', event.target.value)
            const docSnap = await getDoc(docRef)

            if (docSnap.exists()) {
               setIndividualState(docSnap.data())
            } else {
               // docSnap.data() will be undefined in this case
               console.log('No such document!')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedState && individualState) {
         setDistrictDetails({ ...districtDetails, State_id: selectedState, StateName: individualState.Name })

         const sortedListDivisions = [...individualState.Divisions].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDivisionsList(sortedListDivisions)

         const sortedListDistricts = [...individualState.Districts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setDistrictsListRoot(sortedListDistricts)
         setDistrictsList(sortedListDistricts)

         setSelectedDivision('')

         if (individualState.DownLevel === 'Divisions') {
            setHasDivisions(true)
            setShow(false)
         } else {
            setHasDivisions(false)
            setShow(true)
         }
      } else {
         setDistrictDetails({ ...districtDetails, State_id: '', StateName: '' })
         setHasDivisions(false)
         setDivisionsList('')
         setDistrictsList('')
         setShow(false)
      }
   }, [selectedState, individualState])

   // related to Divisions
   const [divisionsList, setDivisionsList] = useState('')
   const [selectedDivision, setSelectedDivision] = useState('')

   const handleChangeDivision = (event) => {
      event.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDivision(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDivision) {
         const selectedDivisionComplete = divisionsList.find((item) => item.Division_id === selectedDivision)
         setDistrictDetails({
            ...districtDetails,
            Division_id: selectedDivision,
            DivisionName: selectedDivisionComplete.Name,
         })
         setShow(true)
         fetchDistrictsListByDivision()
      } else {
         setDistrictDetails({
            ...districtDetails,
            Division_id: '',
            DivisionName: '',
         })
         fetchDistrictsListByState()
         setShow(false)
      }
   }, [selectedDivision])

   // related to Districts
   const [districtsListRoot, setDistrictsListRoot] = useState('')
   const [districtsList, setDistrictsList] = useState('')

   const fetchDistrictsListByState = async () => {
      setDistrictsList(districtsListRoot)
   }
   const fetchDistrictsListByDivision = async () => {
      setDistrictsList(districtsListRoot.filter((item) => item.Division_id === selectedDivision))
   }

   // related to form filling and submission
   function handleDistrictDetails(e) {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setDistrictDetails({ ...districtDetails, [e.target.name]: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const [lastDistrict, setLastDistrict] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddDistrict = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               districtDetails.Name.length >= 3 &&
               districtDetails.Country_id.length >= 2 &&
               districtDetails.State_id.length >= 2
            ) {
               districtDetails.CrBy = auth.currentUser.uid
               districtDetails.CrDt = new Date()
               districtDetails.CrIP = ip.data.ip
               console.log('districtDetails 1: ', districtDetails)
               setLastDistrict(districtDetails.Name)
               setDistrictDetails({
                  ...districtDetails,
                  Name: '',
                  NameHindi: '',
                  NameLocal: '',
                  NameAlt: '',
                  HeadQuarter: '',
                  HeadQuarter_alt: '',
                  Type: '',
                  LGDCode: '',
                  Flag: null,
                  Symbol: null,
                  CrBy: '',
                  CrDt: '',
                  CrDtSys: '',
                  CrIP: '',
               })
               setUploading(true)
               try {
                  const docRef = await addDoc(collection(db, 'Districts'), districtDetails)
                  districtDetails.id = docRef.id

                  const newDetailForDistrictList = {
                     District_id: docRef.id,
                     Name: districtDetails.Name,
                     NameLocal: districtDetails.NameLocal,
                     NameHindi: districtDetails.NameHindi,
                     NameAlt: districtDetails.NameAlt,
                     HeadQuarter: districtDetails.HeadQuarter,
                     Division_id: districtDetails.Division_id,
                     State_id: districtDetails.State_id,
                     Flag: districtDetails.Flag,
                     Symbol: districtDetails.Symbol,
                     ForLegis: districtDetails.ForLegis,
                  }

                  const docRefUpdateState = doc(db, 'States', selectedState)
                  const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)

                  await updateDoc(docRefUpdateState, {
                     Districts: arrayUnion(newDetailForDistrictList),
                     DistrictsCount: increment(1),
                  })

                  if (districtDetails.Division_id !== '') {
                     const docRefUpdateDivision = doc(db, 'Divisions', selectedDivision)
                     await updateDoc(docRefUpdateDivision, {
                        Districts: arrayUnion(newDetailForDistrictList),
                        DistrictsCount: increment(1),
                     })
                  }

                  await updateDoc(docRefUpdateCountry, {
                     DistrictsCount: increment(1),
                  })

                  setDistrictsList([...districtsList, districtDetails])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding district name')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisNameAlt,
      thisHeadQuarter,
      thisDivisionId,
      thisStateId,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const districtRef = doc(db, 'Districts', id)
            try {
               await updateDoc(districtRef, {
                  ForLegis: !thisForLegis,
               })

               const docRefUpdateState = doc(db, 'States', selectedState)

               await updateDoc(docRefUpdateState, {
                  Districts: arrayRemove({
                     District_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     NameAlt: thisNameAlt,
                     HeadQuarter: thisHeadQuarter,
                     Division_id: thisDivisionId,
                     State_id: thisStateId,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: thisForLegis,
                  }),
               })

               await updateDoc(docRefUpdateState, {
                  Districts: arrayUnion({
                     District_id: id,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     NameAlt: thisNameAlt,
                     HeadQuarter: thisHeadQuarter,
                     Division_id: thisDivisionId,
                     State_id: thisStateId,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     ForLegis: !thisForLegis,
                  }),
               })

               if (thisDivisionId !== '') {
                  const docRefUpdateDivision = doc(db, 'Divisions', thisDivisionId)

                  await updateDoc(docRefUpdateDivision, {
                     Districts: arrayRemove({
                        District_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        NameAlt: thisNameAlt,
                        HeadQuarter: thisHeadQuarter,
                        Division_id: thisDivisionId,
                        State_id: thisStateId,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        ForLegis: thisForLegis,
                     }),
                  })

                  await updateDoc(docRefUpdateDivision, {
                     Districts: arrayUnion({
                        District_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        NameAlt: thisNameAlt,
                        HeadQuarter: thisHeadQuarter,
                        Division_id: thisDivisionId,
                        State_id: thisStateId,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        ForLegis: !thisForLegis,
                     }),
                  })
               }

               setEditStatus(true)
               setForLegis({ id, thisForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         // eslint-disable-next-line array-callback-return
         districtsList.map((item) => {
            if (item.District_id === forLegis.id) {
               item.ForLegis = !forLegis.ForLegis
            }
         })
         setEditStatus(false)
      }
   }, [forLegis, editStatus])

   const handleDelete = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisNameAlt,
      thisHeadQuarter,
      thisDivisionId,
      thisStateId,
      thisFlag,
      thisSymbol,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this district?')) {
               try {
                  await deleteDoc(doc(db, 'Districts', id))

                  const docRefUpdateState = doc(db, 'States', selectedState)
                  await updateDoc(docRefUpdateState, {
                     Districts: arrayRemove({
                        District_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        NameAlt: thisNameAlt,
                        HeadQuarter: thisHeadQuarter,
                        Division_id: thisDivisionId,
                        State_id: thisStateId,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        ForLegis: thisForLegis,
                     }),
                     DistrictsCount: increment(-1),
                  })

                  if (thisDivisionId !== '') {
                     const docRefUpdateDivision = doc(db, 'Divisions', thisDivisionId)
                     await updateDoc(docRefUpdateDivision, {
                        Districts: arrayRemove({
                           District_id: id,
                           Name: thisName,
                           NameLocal: thisNameLocal,
                           NameHindi: thisNameHindi,
                           NameAlt: thisNameAlt,
                           HeadQuarter: thisHeadQuarter,
                           Division_id: thisDivisionId,
                           State_id: thisStateId,
                           Flag: thisFlag,
                           Symbol: thisSymbol,
                           ForLegis: thisForLegis,
                        }),
                        DistrictsCount: increment(-1),
                     })
                  }

                  const docRefUpdateCountry = doc(db, 'Countries', selectedCountry)
                  await updateDoc(docRefUpdateCountry, {
                     DistrictsCount: increment(-1),
                  })

                  setDistrictsList(districtsList.filter((item) => item.District_id !== id))
                  setDistrictsListRoot(districtsListRoot.filter((item) => item.District_id !== id))
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         {(isDataEditor || isSuper) && (
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }} useFlexGap flexWrap="wrap">
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link
                  href="/geo-political-divisions/districts/admin"
                  color="#FFFFFF"
                  onClick={(e) => onClick(e)}
                  className={styles.link2}
               >
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         )}
         {(isDataEditor || isSuper) && (
            <Box
               sx={{
                  border: 1,
                  my: 1,
                  mx: 5,
                  pb: 1,
                  px: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-root': { width: '15ch' },
                  '& .MuiTextField-root': { width: '50ch' },
                  '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     fontWeight: 600,
                     textAlign: 'center',
                     fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                     ],
                  }}
               >
                  List a district
               </Typography>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel id="demo-select-small-label">Country</InputLabel>
                     <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedCountry}
                        label="Country"
                        onChange={handleChangeCountry}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {countries.map((item) => (
                           <MenuItem key={item.id} value={item.id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>State</InputLabel>
                     <Select value={selectedState} label="State" onChange={handleChangeState}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.State_id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </div>
               {hasDivisions && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Division</InputLabel>
                     <Select value={selectedDivision} label="Division" onChange={handleChangeDivision}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {divisionsList &&
                           divisionsList.map((item) => (
                              <MenuItem key={item.Division_id} value={item.Division_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               )}
               {show && (
                  <div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.Name}
                           name="Name"
                           id="name"
                           label="Name"
                           variant="outlined"
                           margin="dense"
                           required
                           fullWidth
                           size="small"
                           sx={{ paddingBottom: 0 }}
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.NameHindi}
                           name="NameHindi"
                           id="nameHindi"
                           label="Hindi name"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.NameLocal}
                           name="NameLocal"
                           id="nameLocal"
                           label="Local name"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.HeadQuarter}
                           name="HeadQuarter"
                           id="headquarter"
                           label="Headquarter"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           required
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.HeadQuarter_alt}
                           name="HeadQuarter_alt"
                           id="headquarterAlt"
                           label="Other Headquarter"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.LGDCode}
                           name="LGDCode"
                           id="lGDCode"
                           label="LGD Code"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.Flag}
                           name="Flag"
                           id="flag"
                           label="Flag"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div>
                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleDistrictDetails(e)
                           }}
                           value={districtDetails.Symbol}
                           name="Symbol"
                           id="symbol"
                           label="Symbol"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </div>
                     <div className={styles.alertDiv}>
                        {uploading && (
                           <MyLoaderCircularGradient title={'Listing the district ...'}></MyLoaderCircularGradient>
                        )}
                        {uploaded && (
                           <Alert variant="outlined" severity="success">
                              District <strong>{lastDistrict}</strong> listed successfully.
                           </Alert>
                        )}
                        {uploadingFailed && (
                           <Alert variant="outlined" severity="error">
                              Error occured! District <strong>{lastDistrict}</strong> could not be listed.
                           </Alert>
                        )}
                     </div>
                     <div>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           size="small"
                           onClick={(e) => {
                              handleAddDistrict(e)
                           }}
                           disabled={
                              districtDetails.Name.length < 3 ||
                              districtDetails.Country_id.length < 2 ||
                              districtDetails.State_id.length < 2
                           }
                        >
                           Submit
                        </Button>
                     </div>
                  </div>
               )}
            </Box>
         )}

         <div className={styles.div200}>
            {(isDataEditor || isSuper) && (
               <div>
                  <Header title="List of districts" />
                  {districtsList &&
                     districtsList.map((item, index) => (
                        <Box
                           key={item.District_id}
                           alignItems="center"
                           sx={{
                              border: 1,
                              my: 1,
                              p: 0.5,
                              mx: 5,
                              borderRadius: 2,
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <div>{index + 1}.</div>
                           <div className={styles.state__details}>
                              <p className={styles.state__capital}>{item.District_id}</p>
                              <h3 className={styles.state__name}>{item.Name}</h3>
                              <p className={styles.state__capital}>HQ: {item.HeadQuarter}</p>
                              <p className={styles.state__capital}>State / UT: {item.StateName}</p>
                              <p className={styles.state__capital}>Division: {item.DivisionName}</p>
                              <p className={styles.state__capital}>Division id: {item.Division_id}</p>
                           </div>
                           <div className={item.ForLegis ? styles.statusOk : styles.statusFalse}>
                              {item.ForLegis ? 'Open for operation' : 'Not ready for operation'}
                           </div>
                           <div className={styles.editButtons}>
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={item.ForLegis ? 'error' : 'success'}
                                 size="small"
                                 sx={{ textTransform: 'none', marginBottom: 0.5, px: 1, py: 0, minWidth: 0 }}
                                 onClick={(e) => {
                                    handleOperation(
                                       e,
                                       item.District_id,
                                       item.Name,
                                       item.NameAlt,
                                       item.HeadQuarter,
                                       item.Division_id,
                                       item.State_id,
                                       item.Flag,
                                       item.Symbol,
                                       item.ForLegis,
                                    )
                                 }}
                              >
                                 {item.ForLegis ? 'Stop operation' : 'Start operation'}
                              </Button>
                              {isDataEditor && (
                                 <Button
                                    type="submit"
                                    align="center"
                                    variant="contained"
                                    color={'error'}
                                    size="small"
                                    sx={{ textTransform: 'none', px: 1, py: 0, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleDelete(
                                          e,
                                          item.District_id,
                                          item.Name,
                                          item.NameAlt,
                                          item.HeadQuarter,
                                          item.Division_id,
                                          item.State_id,
                                          item.Flag,
                                          item.Symbol,
                                          item.ForLegis,
                                       )
                                    }}
                                 >
                                    Delete
                                 </Button>
                              )}
                           </div>
                        </Box>
                     ))}
               </div>
            )}
         </div>
      </div>
   )
}

export default DistrictsAdmin
