import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import validator from 'validator'

function AddEmail({ handleAddEmail }) {
   //
   const [emailDetails, setEmailDetails] = useState({
      Email: '',
   })
   useEffect(() => {
      console.log('Party details on change of its value: ', emailDetails)
   }, [emailDetails])

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid Email')
      } else {
         setErrorMessage('Is Not Valid Email')
      }
   }

   function handleEmailDetails(event) {
      setEmailDetails({ ...emailDetails, [event.target.name]: event.target.value })
      validate(event.target.value)
   }

   function handleSubmitEmail(e) {
      e.preventDefault()

      if (errorMessage !== '' || errorMessage === 'Is Valid Email') {
         handleAddEmail(emailDetails)
      }

      reset()
   }

   function reset() {
      setEmailDetails({
         Email: '',
      })
   }

   return (
      <Box>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleEmailDetails}
               value={emailDetails.Email}
               name="Email"
               id="email"
               label="Email"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <br />
         <span
            style={{
               fontWeight: 'bold',
               color: 'red',
            }}
         >
            {errorMessage}
         </span>
         <div className={myStyles.margin_top_one}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               onClick={(e) => {
                  handleSubmitEmail(e)
               }}
               disabled={errorMessage === '' || errorMessage === 'Is Not Valid Email'}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddEmail.propTypes = {
   handleAddEmail: PropTypes.func.isRequired,
}

export default AddEmail
