import { createSlice } from '@reduxjs/toolkit'

export const usersSlice = createSlice({
   name: 'users',
   initialState: {
      currentUser: null,
      ip: null,
   },
   reducers: {
      setUser: (users, action) => {
         users.currentUser = action.payload
      },
      setIP: (users, action) => {
         users.ip = action.payload
      },
   },
})

export const { setUser, setIP } = usersSlice.actions

export const selectUsers = (state) => state.users

export default usersSlice.reducer
