/* eslint-disable no-case-declarations */
import {
   Alert,
   Button,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   Link,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Stack,
   TextField,
   Typography,
   Box,
} from '@mui/material'

import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import React, { useEffect, useState } from 'react'

import styles from './LegisAllAdmin.module.css'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   deleteDoc,
   doc,
   getDoc,
   getDocs,
   orderBy,
   query,
   updateDoc,
   where,
} from 'firebase/firestore'
import { auth, db } from '../../../FirebaseConfig'
import Header from '../../../Components/Header/Header'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { selectUsers } from '../../../redux/reducers/users-slice'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'

function LegisAllAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('legis all admin - first use effect entered')

      if (user.currentUser !== null) {
         console.log('legis all admin - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('legis all admin - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [legisDetails, setLegisDetails] = useState({
      Name: '',
      NameOld: [],
      NameAlt: [],
      NameAltOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameLocal: '',
      NameLocalOld: [],
      Strata: '',
      Nature: '',
      Type: '',
      Duration: '',
      SingularMem: '',
      HasHouses: '',
      HasConstituencies: '',
      ParentLegislature_id: '',
      ParentLegislatureName: '',
      ElectiveSeats: 0,
      ElectiveSeatsOld: [],
      NominatedSeats: 0,
      NominatedSeatsOld: [],
      NominatingAuthorities: '',
      NominatingAuthoritiesOld: [],
      Constituencies: [],
      Image: null,
      ImagesOld: [],
      Flag: null,
      FlagOld: [],
      Symbol: null,
      SymbolOld: [],
      MinistryLegis_id: '',
      MinistryLegisName: '',
      Priority: 0,
      ForLegis: false,
      CountryName: '',
      Country_id: '',
      StateName: '',
      State_id: '',
      DivisionName: '',
      Division_id: '',
      DistrictName: '',
      District_id: '',
      SubDistrictName: '',
      SubDistrict_id: '',
      BlockName: '',
      Block_id: '',
      VillageName: '',
      Village_id: '',
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })

   useEffect(() => {
      // just for testing
      console.log('state details on change of its value: ', legisDetails)
   }, [legisDetails])

   const [showStrata, setShowStrata] = useState(false)
   const [showStates, setShowStates] = useState(false)
   const [showDivisions, setShowDivisions] = useState(false)
   const [showDistricts, setShowDistricts] = useState(false)
   const [showParentLegisSelect, setShowParentLegisSelect] = useState(false)
   const [parentLegisList, setParentLegisList] = useState([])
   const [showForm, setShowForm] = useState(false)
   const [showLegisList, setShowLegisList] = useState(false)

   const [priority, setPriority] = useState(false)

   // related to countries
   const [selectedCountry, setSelectedCountry] = useState('')
   const [selectedCountryName, setSelectedCountryName] = useState('')
   const countries = useSelector(selectCountries).countriesList

   const handleCountryChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(event.target.value)
            const selectedCountryComplete = countries.find((item) => item.id === event.target.value)
            setSelectedCountryName(selectedCountryComplete.Name)
            setLegisDetails({ ...legisDetails, CountryName: selectedCountryComplete.Name })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      // on selction change of country
      setLegisDetails({ ...legisDetails, Country_id: selectedCountry })
      setSelectedStrata('')
      setShowStates(false)
      setSelectedStateComplete('')
      setShowDivisions(false)
      setSelectedDivision('')
      setShowDistricts(false)
      setSelectedDistrict('')
      setShowForm(false)
      setShowLegisList(false)
      if (selectedCountry) {
         // a country is selected
         setShowStrata(true)
      } else {
         // no country selected
         setShowStrata(false)
      }
   }, [selectedCountry])

   // related to strata
   const [selectedStrata, setSelectedStrata] = useState('')
   const handleStrataChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedStrata(event.target.value)
            setLegisDetails({
               ...legisDetails,
               Strata: event.target.value,
            })

            setShowStates(false)
            setSelectedStateComplete('')
            setShowDivisions(false)
            setSelectedDivision('')
            setShowDistricts(false)
            setSelectedDistrict('')
            setShowForm(false)
            setShowLegisList(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      setLegisDetails({
         ...legisDetails,
         Strata: selectedStrata,
         StateName: '',
         DivisionName: '',
         DistrictName: '',
         ParentLegislature_id: '',
         ParentLegislatureName: '',
      })
      setParentLegisList([])

      if (selectedStrata) {
         console.log('entered selected strata')

         switch (selectedStrata) {
            case 'Central':
               console.log('entered selected strata case central')
               setShowParentLegisSelect(false)
               setShowForm(true)
               fetchLegisListCentral()
               setShowLegisList(true)
               break
            case 'State':
               fetchParentLegisListCentral()
               setShowParentLegisSelect(true)
               fetchStatesList()
               setShowStates(true)
               break
            case 'Division':
               setShowParentLegisSelect(false)
               break
            case 'District':
               setShowParentLegisSelect(false)
               break
         }
      }
   }, [selectedStrata])

   // related to states
   const [statesList, setStatesList] = useState('')
   const [selectedStateComplete, setSelectedStateComplete] = useState('')
   const [selectedState, setSelectedState] = useState(false)
   const [selectedStateName, setSelectedStateName] = useState(false)

   const fetchStatesList = async () => {
      try {
         const q = query(collection(db, 'States'), where('Country_id', '==', selectedCountry), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         const statesListFetched = []
         querySnapshot.forEach((doc) => {
            statesListFetched.push({ id: doc.id, ...doc.data() })
         })
         setStatesList(statesListFetched)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }
   const handleStateChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedStateComplete(statesList.find((item) => item.id === event.target.value))
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      setShowDivisions(false)
      setSelectedDivision('')
      setShowDistricts(false)
      setSelectedDistrict('')
      setShowForm(false)
      setShowLegisList(false)

      if (selectedStateComplete) {
         setLegisDetails({ ...legisDetails, State_id: selectedStateComplete.id, StateName: selectedStateComplete.Name })
         setSelectedState(selectedStateComplete.id)
         setSelectedStateName(selectedStateComplete.Name)

         switch (selectedStrata) {
            case 'State':
               setShowForm(true)
               fetchLegisList()
               setShowLegisList(true)
               break
            case 'Division':
               if (selectedStateComplete.DownLevel === 'Divisions') {
                  // it has divisions. So, display divisions list.
                  fetchDivisionsList()
                  setShowDivisions(true)
               } else {
                  // display that it does not have any divisions
               }
               break
            case 'District':
               switch (selectedStateComplete.DownLevel) {
                  case 'Divisions':
                     // it has divisions. So, display divisions list.
                     fetchDivisionsList()
                     setShowDivisions(true)
                     break
                  case 'Districts':
                     // it has downlevel districts. So display districts list
                     fetchDistrictsListByState()
                     setShowDistricts(true)
                     break
               }
               break
            default:
         }
      }
   }, [selectedStateComplete])

   // related to Divisions
   const [divisionsList, setDivisionsList] = useState('')
   const [selectedDivision, setSelectedDivision] = useState('')
   const [selectedDivisionName, setSelectedDivisionName] = useState('')
   const fetchDivisionsList = async () => {
      try {
         const q = query(
            collection(db, 'Divisions'),
            where('State_id', '==', selectedStateComplete.id),
            orderBy('Name', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const divisionsListFetched = []
         querySnapshot.forEach((doc) => {
            divisionsListFetched.push({ id: doc.id, ...doc.data() })
         })
         setDivisionsList(divisionsListFetched)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }
   const handleDivisionChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDivision(event.target.value)
            const selectedDivisionComplete = divisionsList.find((item) => item.id === event.target.value)
            setSelectedDivisionName(selectedDivisionComplete.Name)
            setLegisDetails({ ...legisDetails, DivisionName: selectedDivisionComplete.Name })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      setLegisDetails({ ...legisDetails, Division_id: selectedDivision })
      setShowDistricts(false)
      setSelectedDistrict('')
      setShowForm(false)
      setShowLegisList(false)

      if (selectedDivision) {
         switch (selectedStrata) {
            case 'Division':
               // division is selected and strata is division
               setShowForm(true)
               fetchLegisList()
               setShowLegisList(true)
               break
            case 'District':
               // division is selcted and strata is district
               fetchDistrictsListByDivision()
               setShowDistricts(true)
               break
         }
      }
   }, [selectedDivision])

   // related to Districts
   const [districtsList, setDistrictsList] = useState('')
   const [selectedDistrict, setSelectedDistrict] = useState('')
   const [selectedDistrictName, setSelectedDistrictName] = useState('')
   const fetchDistrictsListByState = async () => {
      try {
         const q = query(
            collection(db, 'Districts'),
            where('State_id', '==', selectedStateComplete.id),
            orderBy('Name', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const districtsListFetched = []
         querySnapshot.forEach((doc) => {
            districtsListFetched.push({ id: doc.id, ...doc.data() })
         })
         setDistrictsList(districtsListFetched)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }
   const fetchDistrictsListByDivision = async () => {
      try {
         const q = query(
            collection(db, 'Districts'),
            where('Division_id', '==', selectedDivision),
            orderBy('Name', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const districtsListFetched = []
         querySnapshot.forEach((doc) => {
            districtsListFetched.push({ id: doc.id, ...doc.data() })
         })
         setDistrictsList(districtsListFetched)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }
   const handleDistrictChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedDistrict(event.target.value)
            const selectedDistrictComplete = districtsList.find((item) => item.id === event.target.value)
            setSelectedDistrictName(selectedDistrictComplete.Name)
            setLegisDetails({ ...legisDetails, DistrictName: selectedDistrictComplete.Name })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      setLegisDetails({ ...legisDetails, District_id: selectedDistrict })
      if (selectedDistrict) {
         setShowForm(true)
         fetchLegisList()
         setShowLegisList(true)
      } else {
         //  district is not selected at all
         setShowForm(false)
         setShowLegisList(false)
      }
   }, [selectedDistrict])

   // related to parent legislature lists

   const fetchParentLegisListCentral = async () => {
      try {
         const q = query(
            collection(db, 'Legislatures'),
            where('Country_id', '==', selectedCountry),
            where('Strata', '==', 'Central'),
            where('Type', '==', 'Legislative'),
            orderBy('Priority', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const legisListFetched = []
         querySnapshot.forEach((doc) => {
            legisListFetched.push({ id: doc.id, ...doc.data() })
         })
         setParentLegisList(legisListFetched)
      } catch (err) {
         console.log('error fetching parent legis list: ', err)
      }
   }

   const handleParentLegislatureChange = (event) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (event.target.value !== '') {
               const selectedParentLegisComplete = parentLegisList.find((item) => item.id === event.target.value)
               setLegisDetails({
                  ...legisDetails,
                  ParentLegislature_id: event.target.value,
                  ParentLegislatureName: selectedParentLegisComplete.Name,
               })
            } else {
               setLegisDetails({
                  ...legisDetails,
                  ParentLegislature_id: '',
                  ParentLegislatureName: '',
               })
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // const fetchParentLegisListState = async () => {
   //    try {
   //       const q = query(
   //          collection(db, 'Legislatures'),
   //          where('State_id', '==', selectedStateComplete.id),
   //          where('Strata', '==', 'State'),
   //          orderBy('Priority', 'asc'),
   //       )
   //       const querySnapshot = await getDocs(q)
   //       const legisListFetched = []
   //       querySnapshot.forEach((doc) => {
   //          legisListFetched.push({ id: doc.id, ...doc.data() })
   //       })
   //       setParentLegisList(legisListFetched)
   //    } catch (err) {
   //       console.log('error fetching parent legis list: ', err)
   //    }
   // }

   // const fetchParentLegisListDivision = async () => {
   //    try {
   //       const q = query(
   //          collection(db, 'Legislatures'),
   //          where('State_id', '==', selectedStateComplete.id),
   //          where('Strata', '==', 'Division'),
   //          orderBy('Priority', 'asc'),
   //       )
   //       const querySnapshot = await getDocs(q)
   //       const legisListFetched = []
   //       querySnapshot.forEach((doc) => {
   //          legisListFetched.push({ id: doc.id, ...doc.data() })
   //       })
   //       setParentLegisList(legisListFetched)
   //    } catch (err) {
   //       console.log('error fetching parent legis list: ', err)
   //    }
   // }

   // related to Legislature list
   const [legisList, setLegisList] = useState('')
   const [legisListRoot, setLegisListRoot] = useState('')
   const fetchLegisList = async () => {
      try {
         const q = query(
            collection(db, 'Legislatures'),
            where('Country_id', '==', selectedCountry),
            where('State_id', '==', selectedStateComplete.id),
            where('Division_id', '==', selectedDivision),
            where('District_id', '==', selectedDistrict),
            orderBy('Priority', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const legisListFetched = []
         querySnapshot.forEach((doc) => {
            legisListFetched.push({ id: doc.id, ...doc.data() })
         })
         setLegisList(legisListFetched)
         setLegisListRoot(legisListFetched)
         console.log(legisList)
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchLegisListCentral = async () => {
      try {
         const q = query(
            collection(db, 'Legislatures'),
            where('Country_id', '==', selectedCountry),
            where('Strata', '==', 'Central'),
            orderBy('Priority', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const legisListFetched = []
         querySnapshot.forEach((doc) => {
            legisListFetched.push({ id: doc.id, ...doc.data() })
         })
         setLegisList(legisListFetched)
         setLegisListRoot(legisListFetched)
         console.log(legisList)
      } catch (err) {
         console.log('error', err)
      }
   }

   // related to natures
   const [selectedNature, setSelectedNature] = useState('')
   const [naturesList, setNaturesList] = useState('')
   const fetchNaturesList = async () => {
      try {
         const docRef = doc(db, 'BaseLists', 'LegislatureNatures')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            const list = docSnap.data().Natures

            const sortedList = [...list].sort((a, b) => {
               if (b.Name > a.Name) {
                  return -1
               } else if (b.Name < a.Name) {
                  return 1
               } else {
                  return 0
               }
            })
            setNaturesList(sortedList)
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document!')
         }
      } catch (err) {
         console.log('error fetching natures list: ', err)
      }
   }
   useEffect(() => {
      fetchNaturesList()
   }, [])

   const handleNatureChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedNature(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      setLegisDetails({ ...legisDetails, Nature: selectedNature })
   }, [selectedNature])

   // related to form filling and submission
   function handleLegisDetails(e) {
      setLegisDetails({ ...legisDetails, [e.target.name]: e.target.value })
      if (e.target.name === 'Priority') {
         setPriority(e.target.value)
      }
   }

   const [selectedType, setSelectedType] = useState('')

   function handleChangeType(e) {
      e.preventDefault()
      setLegisDetails({ ...legisDetails, [e.target.name]: e.target.value })
      setSelectedType(e.target.value)

      const newList = legisListRoot.filter((item) => item.Type === e.target.value)

      const newListSorted = newList.sort(function (x, y) {
         return x.Priority - y.Priority || x.Name - y.Name
      })

      setLegisList(newListSorted)
   }

   const [lastLegis, setLastLegis] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddLegis = async (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (legisDetails.Name.length >= 3 && legisDetails.Type.length >= 3) {
               legisDetails.CrBy = auth.currentUser.uid
               legisDetails.CrDt = new Date()
               legisDetails.CrIP = ip.data.ip
               console.log('legisDetails 1: ', legisDetails)
               setLastLegis(legisDetails.Name)

               setUploading(true)
               console.log('legisDetails 2: ', legisDetails, ' uploading: ', uploading)
               try {
                  const docRef = await addDoc(collection(db, 'Legislatures'), legisDetails)
                  legisDetails.id = docRef.id
                  console.log('created record ')

                  const thisDocRef = doc(db, 'Legislatures', docRef.id)
                  await updateDoc(thisDocRef, {
                     NameOld: arrayUnion({
                        Name: legisDetails.Name,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NameAltOld: arrayUnion({
                        NameAlt: legisDetails.NameAlt,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NameHindiOld: arrayUnion({
                        NameHindi: legisDetails.NameHindi,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NameLocalOld: arrayUnion({
                        NameLocal: legisDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     ElectiveSeatsOld: arrayUnion({
                        ElectiveSeats: legisDetails.ElectiveSeats,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NominatedSeatsOld: arrayUnion({
                        NominatedSeats: legisDetails.NominatedSeats,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NominatingAuthoritiesOld: arrayUnion({
                        NominatingAuthorities: legisDetails.NominatingAuthorities,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     ImagesOld: arrayUnion({
                        Image: legisDetails.Image,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  console.log('updated the newly created record ')

                  console.log('created the doc ref s ')

                  const detailsForInsertUpdate = {
                     Legislature_id: docRef.id,
                     Name: legisDetails.Name,
                     NameHindi: legisDetails.NameHindi,
                     NameLocal: legisDetails.NameLocal,
                     NameAlt: legisDetails.NameAlt,
                     Strata: legisDetails.Strata,
                     Nature: legisDetails.Nature,
                     Type: legisDetails.Type,
                     HasHouses: legisDetails.HasHouses,
                     HasConstituencies: legisDetails.HasConstituencies,
                     Image: legisDetails.Image,
                     MinistryLegis_id: legisDetails.MinistryLegis_id,
                     MinistryLegisName: legisDetails.MinistryLegisName,
                     Priority: legisDetails.Priority,
                  }

                  console.log('initialized the update object record ')

                  switch (legisDetails.Strata) {
                     case 'Central':
                        console.log('entered case central ')
                        const docRefCentral = doc(db, 'Countries', legisDetails.Country_id)
                        await updateDoc(docRefCentral, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'State':
                        console.log('entered case state ')
                        const docRefState = doc(db, 'States', legisDetails.State_id)
                        await updateDoc(docRefState, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'Division':
                        console.log('entered case Division ')
                        // eslint-disable-next-line no-case-declarations
                        const docRefDivision = doc(db, 'Divisions', legisDetails.Division_id)
                        await updateDoc(docRefDivision, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'District':
                        console.log('entered case District ')
                        const docRefDistrict = doc(db, 'Districts', legisDetails.District_id)
                        await updateDoc(docRefDistrict, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'SubDistrict':
                        console.log('entered case sub-district ')
                        const docRefSubDistrict = doc(db, 'SubDistricts', legisDetails.SubDistrict_id)
                        await updateDoc(docRefSubDistrict, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'Block':
                        console.log('entered case block ')
                        const docRefBlock = doc(db, 'Blocks', legisDetails.Block_id)
                        await updateDoc(docRefBlock, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'Village':
                        console.log('entered case village ')
                        const docRefVillage = doc(db, 'Villages', legisDetails.Village_id)
                        await updateDoc(docRefVillage, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                  }

                  setLegisDetails({
                     Name: '',
                     NameOld: [],
                     NameAlt: [],
                     NameAltOld: [],
                     NameHindi: '',
                     NameHindiOld: [],
                     NameLocal: '',
                     NameLocalOld: [],
                     Strata: selectedStrata,
                     Nature: '',
                     Type: selectedType,
                     Duration: '',
                     SingularMem: '',
                     HasHouses: '',
                     HasConstituencies: '',
                     ParentLegislature_id: '',
                     ParentLegislatureName: '',
                     ElectiveSeats: 0,
                     ElectiveSeatsOld: [],
                     NominatedSeats: 0,
                     NominatedSeatsOld: [],
                     NominatingAuthorities: '',
                     NominatingAuthoritiesOld: [],
                     Constituencies: [],
                     Image: null,
                     ImagesOld: [],
                     Flag: null,
                     FlagOld: [],
                     Symbol: null,
                     SymbolOld: [],
                     MinistryLegis_id: '',
                     MinistryLegisName: '',
                     Priority: priority,
                     ForLegis: false,
                     CountryName: selectedCountryName,
                     Country_id: selectedCountry,
                     StateName: selectedStateName,
                     State_id: selectedState,
                     DivisionName: selectedDivisionName,
                     Division_id: selectedDivision,
                     DistrictName: selectedDistrictName,
                     District_id: selectedDistrict,
                     SubDistrictName: '',
                     SubDistrict_id: '',
                     BlockName: '',
                     Block_id: '',
                     VillageName: '',
                     Village_id: '',
                     CrBy: '',
                     CrDt: '',
                     CrDtSys: '',
                     CrIP: '',
                  })
                  console.log('reached end ')
                  setLegisList([...legisList, legisDetails])
                  setLegisListRoot([...legisListRoot, legisDetails])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding legislature')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (e, id, ForLegis) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const stateRef = doc(db, 'Legislatures', id)
            try {
               await updateDoc(stateRef, {
                  ForLegis: !ForLegis,
               })
               setEditStatus(true)
               setForLegis({ id, ForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         // eslint-disable-next-line array-callback-return
         legisList.map((item) => {
            if (item.id === forLegis.id) {
               item.ForLegis = !forLegis.ForLegis
            }
         })
         setEditStatus(false)
      }
   }, [forLegis, editStatus])

   const handleDelete = async (
      id,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisNameAlt,
      thisStrata,
      thisNature,
      thisType,
      thisHasHouses,
      thisHasConstituencies,
      thisImage,
      thisMinistryLegisId,
      thisMinistryLegisName,
      thisPriority,
      thisCountryId,
      thisStateId,
      thisDivisionId,
      thisDistrictId,
   ) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this legislature?')) {
               try {
                  await deleteDoc(doc(db, 'Legislatures', id))

                  const detailsForDeleteUpdate = {
                     Legislature_id: id,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     NameAlt: thisNameAlt,
                     Strata: thisStrata,
                     Nature: thisNature,
                     Type: thisType,
                     HasHouses: thisHasHouses,
                     HasConstituencies: thisHasConstituencies,
                     Image: thisImage,
                     MinistryLegis_id: thisMinistryLegisId,
                     MinistryLegisName: thisMinistryLegisName,
                     Priority: thisPriority,
                  }

                  switch (thisStrata) {
                     case 'Central':
                        const docRefCentral = doc(db, 'Countries', thisCountryId)
                        await updateDoc(docRefCentral, {
                           Legislatures: arrayRemove(detailsForDeleteUpdate),
                        })
                        break
                     case 'State':
                        const docRefState = doc(db, 'States', thisStateId)
                        await updateDoc(docRefState, {
                           Legislatures: arrayRemove(detailsForDeleteUpdate),
                        })
                        break
                     case 'Division':
                        // eslint-disable-next-line no-case-declarations
                        const docRefDivision = doc(db, 'Divisions', thisDivisionId)
                        await updateDoc(docRefDivision, {
                           Legislatures: arrayRemove(detailsForDeleteUpdate),
                        })
                        break
                     case 'District':
                        const docRefDistrict = doc(db, 'Districts', thisDistrictId)
                        await updateDoc(docRefDistrict, {
                           Legislatures: arrayRemove(detailsForDeleteUpdate),
                        })
                        break
                     case 'SubDistrict':
                        const docRefSubDistrict = doc(db, 'SubDistricts', legisDetails.District_id)
                        await updateDoc(docRefSubDistrict, {
                           Legislatures: arrayRemove(detailsForDeleteUpdate),
                        })
                        break
                     case 'Block':
                        const docRefBlock = doc(db, 'Blocks', legisDetails.District_id)
                        await updateDoc(docRefBlock, {
                           Legislatures: arrayRemove(detailsForDeleteUpdate),
                        })
                        break
                     case 'Village':
                        const docRefVillage = doc(db, 'Villages', legisDetails.District_id)
                        await updateDoc(docRefVillage, {
                           Legislatures: arrayRemove(detailsForDeleteUpdate),
                        })
                        break
                  }

                  setLegisList(legisList.filter((item) => item.id !== id))
                  setLegisListRoot(legisListRoot.filter((item) => item.id !== id))
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error deleting district')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // copy to clipboard
   const [indexOfCopy, setIndexOfCopy] = useState('') // The text you want to copy
   const [copyStatus, setCopyStatus] = useState(false) // To indicate if the text was copied
   const onCopyText = (index) => {
      setCopyStatus(true)
      setIndexOfCopy(index)
      setTimeout(() => setCopyStatus(false), 2000) // Reset status after 2 seconds
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         <div>
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }}>
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link href="/geo-political-divisions/states/admin" className={styles.link}>
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" color="#ffffff" onClick={(e) => onClick(e)} className={styles.link2}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         </div>
         <Box
            sx={{
               border: 1,
               my: 1,
               mx: 5,
               pb: 1,
               px: 2,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch' },
               '& .MuiTextField-root': { width: '50ch' },
               '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Typography
               variant="h6"
               sx={{
                  fontWeight: 600,
                  textAlign: 'center',
                  fontFamily: [
                     '-apple-system',
                     'BlinkMacSystemFont',
                     '"Segoe UI"',
                     'Roboto',
                     '"Helvetica Neue"',
                     'Arial',
                     'sans-serif',
                     '"Apple Color Emoji"',
                     '"Segoe UI Emoji"',
                     '"Segoe UI Symbol"',
                  ],
               }}
            >
               List a legislature
            </Typography>
            <div>
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel id="demo-select-small-label">Country</InputLabel>
                  <Select
                     labelId="demo-select-small-label"
                     id="demo-select-small"
                     value={selectedCountry}
                     label="Country"
                     onChange={handleCountryChange}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {countries.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                           {item.Name}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </div>
            <div>
               {showStrata && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Strata</InputLabel>
                     <Select value={legisDetails.Strata} label="Strata" onChange={handleStrataChange}>
                        <MenuItem value="">
                           <em>-- select --</em>
                        </MenuItem>
                        <MenuItem value="Central">Central</MenuItem>
                        <MenuItem value="State">State</MenuItem>
                        <MenuItem value="Division">Division</MenuItem>
                        <MenuItem value="District">Local</MenuItem>
                     </Select>
                  </FormControl>
               )}
            </div>
            <div>
               {showStates && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>State</InputLabel>
                     <Select value={legisDetails.State_id} label="State" onChange={handleStateChange}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               )}
            </div>
            <div>
               {showDivisions && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>Division</InputLabel>
                     <Select value={selectedDivision} label="Division" onChange={handleDivisionChange}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {divisionsList &&
                           divisionsList.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               )}
            </div>
            <div>
               {showDistricts && (
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel>District</InputLabel>
                     <Select value={selectedDistrict} label="District" onChange={handleDistrictChange}>
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {districtsList &&
                           districtsList.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               )}
            </div>
            {showParentLegisSelect && (
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel id="demo-select-small-label">Parent legislature</InputLabel>
                     <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={legisDetails.ParentLegislature_id}
                        label="Parent legislature"
                        onChange={handleParentLegislatureChange}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {parentLegisList.map((item) => (
                           <MenuItem key={item.id} value={item.id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
            )}

            {showForm && (
               <div>
                  <div>
                     <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Type:</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="demo-controlled-radio-buttons-group"
                           name="Type"
                           value={legisDetails.Type}
                           onChange={(e) => {
                              handleChangeType(e)
                           }}
                        >
                           <FormControlLabel value="Legislative" control={<Radio size="small" />} label="Legislative" />
                           <FormControlLabel value="Executive" control={<Radio size="small" />} label="Executive" />
                           <FormControlLabel value="Ministry" control={<Radio size="small" />} label="Ministry" />
                           <FormControlLabel
                              value="Independent Department"
                              control={<Radio size="small" />}
                              label="Independent Department"
                           />
                           {/* <FormControlLabel value="Department" control={<Radio size="small" />} label="Department" /> */}
                           <FormControlLabel
                              value="Apex / Independent Body"
                              control={<Radio size="small" />}
                              label="Apex / Independent Body"
                           />
                        </RadioGroup>
                     </FormControl>
                  </div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleLegisDetails(e)
                        }}
                        value={legisDetails.Name}
                        name="Name"
                        id="name"
                        label="Name"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        size="small"
                        sx={{ paddingBottom: 0 }}
                     ></TextField>
                  </div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleLegisDetails(e)
                        }}
                        value={legisDetails.NameHindi}
                        name="NameHindi"
                        id="nameHindi"
                        label="Name (in HIndi)"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                     ></TextField>
                  </div>
                  <div>
                     <TextField
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleLegisDetails(e)
                        }}
                        value={legisDetails.NameLocal}
                        name="NameLocal"
                        id="nameLocal"
                        label="Name (in Local language)"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                     ></TextField>
                  </div>
                  {(legisDetails.Type === 'Legislative' || legisDetails.Type === 'Executive') && (
                     <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl sx={{ m: 1, width: '100%' }} size="small">
                           <InputLabel id="demo-select-small-label">Nature</InputLabel>
                           <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={legisDetails.Nature}
                              label="Nature"
                              onChange={handleNatureChange}
                           >
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {naturesList &&
                                 naturesList.map((item) => (
                                    <MenuItem key={item.id} value={item.Name}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>

                        <TextField
                           type="number"
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleLegisDetails(e)
                           }}
                           value={legisDetails.Duration}
                           name="Duration"
                           id="duration"
                           label="Duration (years)"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>

                        <FormControl>
                           <FormLabel id="demo-controlled-radio-buttons-group">Is singular member?</FormLabel>
                           <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="SingularMem"
                              value={legisDetails.SingularMem}
                              onChange={(e) => {
                                 handleLegisDetails(e)
                              }}
                           >
                              <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                              <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                           </RadioGroup>
                        </FormControl>

                        <FormControl>
                           <FormLabel id="demo-controlled-radio-buttons-group">Has houses?</FormLabel>
                           <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="HasHouses"
                              value={legisDetails.HasHouses}
                              onChange={(e) => {
                                 handleLegisDetails(e)
                              }}
                           >
                              <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                              <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                           </RadioGroup>
                        </FormControl>

                        <FormControl>
                           <FormLabel id="demo-controlled-radio-buttons-group">Has constituencies?</FormLabel>
                           <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="HasConstituencies"
                              value={legisDetails.HasConstituencies}
                              onChange={(e) => {
                                 handleLegisDetails(e)
                              }}
                           >
                              <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                              <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                           </RadioGroup>
                        </FormControl>

                        <TextField
                           type="number"
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleLegisDetails(e)
                           }}
                           value={legisDetails.ElectiveSeats}
                           name="ElectiveSeats"
                           id="electiveSeats"
                           label="Elective Seats"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>

                        <TextField
                           type="number"
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleLegisDetails(e)
                           }}
                           value={legisDetails.NominatedSeats}
                           name="NominatedSeats"
                           id="nominatedSeats"
                           label="Nominated Seats"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>

                        <TextField
                           onBlur={(event) => event}
                           onChange={(e) => {
                              handleLegisDetails(e)
                           }}
                           value={legisDetails.NominatingAuthorities}
                           name="NominatingAuthorities"
                           id="NominatingAuthorities"
                           label="Nominating / Appointing Authorities"
                           variant="outlined"
                           margin="dense"
                           size="small"
                           fullWidth
                        ></TextField>
                     </Box>
                  )}

                  <div>
                     <TextField
                        type="number"
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleLegisDetails(e)
                        }}
                        value={legisDetails.Priority}
                        name="Priority"
                        id="priority"
                        label="Priority"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                     ></TextField>
                  </div>
                  <div className={styles.alertDiv}>
                     {uploading && (
                        <MyLoaderCircularGradient title={'Listing the legislature ...'}></MyLoaderCircularGradient>
                     )}
                     {uploaded && (
                        <Alert variant="outlined" severity="success">
                           <strong>{lastLegis}</strong> listed successfully.
                        </Alert>
                     )}
                     {uploadingFailed && (
                        <Alert variant="outlined" severity="error">
                           Error occured! <strong>{lastLegis}</strong> could not be listed.
                        </Alert>
                     )}
                  </div>
                  <div>
                     <Button
                        type="submit"
                        align="center"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                           handleAddLegis(e)
                        }}
                        disabled={legisDetails.Name.length < 3 || legisDetails.Type.length < 3}
                     >
                        Submit
                     </Button>
                  </div>
               </div>
            )}
         </Box>
         <Header title="List of legislatures" />
         <div className={styles.div200}>
            {showLegisList &&
               legisList &&
               legisList.map((item, index) => (
                  <Box
                     key={item.id}
                     sx={{
                        bgcolor: '#fff',
                        borderRadius: 2,
                        p: 2,
                        minWidth: 300,
                        my: 0.5,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        backgroundImage: `linear-gradient(
                           to bottom,
                           rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 100%
                           )`,
                     }}
                  >
                     <Box>
                        <Box sx={{ color: '#FF5F1F', fontSize: 14 }}>
                           <CopyToClipboard text={item.id} onCopy={() => onCopyText(index)}>
                              {/* <button className={styles.button_orange}>id: {item.id}</button> */}
                              <Button variant="contained" color="success" size="small">
                                 id: {item.id}
                              </Button>
                           </CopyToClipboard>
                           {copyStatus && indexOfCopy === index && <p>id copied!</p>}
                           {/* {copyStatus && <div className={`alert alert-success`}>copied</div>} */}
                        </Box>
                        <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>{item.NameAlt}</Box>
                        {/* <Box sx={{ color: '#173A5E', fontSize: 24, fontWeight: 'medium' }}>{item.Name}</Box> */}
                        <Box sx={{ color: '#ffffff', fontSize: 18, fontWeight: 'medium' }}>{item.Name}</Box>
                        <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>Country: {item.CountryName}</Box>
                        {item.StateName && <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>State: {item.StateName}</Box>}
                        {item.DistrictName && (
                           <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>District: {item.DistrictName.join(', ')}</Box>
                        )}
                        {item.Type && <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>Type: {item.Type}</Box>}
                        {item.Priority && <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>Priority: {item.Priority}</Box>}
                        <Box
                           sx={{
                              display: 'inline',
                              mx: 0.5,
                              fontSize: 14,
                           }}
                        >
                           <Link href={`/legislature/${item.Name}/${item.id}`} color="#00FFFF">
                              <InfoSharpIcon />
                           </Link>
                        </Box>
                        {item.ForLegis ? (
                           <Box sx={{ color: '#0FFF50', display: 'inline', fontSize: 14 }}>Open for operation</Box>
                        ) : (
                           <Box sx={{ color: '#FF7F50', display: 'inline', fontSize: 14 }}>Not ready for operation</Box>
                        )}
                     </Box>
                     <Box sx={{ fontSize: 12, display: 'flex', flexDirection: 'column' }}>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color={item.ForLegis ? 'error' : 'success'}
                           size="small"
                           sx={{
                              textTransform: 'none',
                              fontWeight: '400',
                              marginBottom: 0.5,
                              px: 1,
                              py: 0,
                              minWidth: 0,
                           }}
                           onClick={(e) => {
                              handleOperation(e, item.id, item.ForLegis)
                           }}
                        >
                           {item.ForLegis ? 'Stop operation' : 'Start operation'}
                        </Button>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color={'error'}
                           size="small"
                           sx={{ textTransform: 'none', fontWeight: '400', px: 1, py: 0, minWidth: 0 }}
                           onClick={() => {
                              handleDelete(
                                 item.id,
                                 item.Name,
                                 item.NameHindi,
                                 item.NameLocal,
                                 item.NameAlt,
                                 item.Strata,
                                 item.Nature,
                                 item.Type,
                                 item.HasHouses,
                                 item.HasConstituencies,
                                 item.Image,
                                 item.MinistryLegis_id,
                                 item.MinistryLegisName,
                                 item.Priority,
                                 item.Country_id,
                                 item.State_id,
                                 item.Division_id,
                                 item.District_id,
                              )
                           }}
                        >
                           Delete
                        </Button>
                     </Box>
                  </Box>
               ))}
         </div>
      </div>
   )
}

export default LegisAllAdmin
