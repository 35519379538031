import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'

// const StyledTab1s = styled((props) => (
//    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
// ))({
//    '& .MuiTabs-indicator': {
//       display: 'flex',
//       justifyContent: 'center',
//       //   backgroundColor: 'transparent',
//    },
//    '& .MuiTabs-indicatorSpan': {
//       maxWidth: 40,
//       width: '100%',
//       //   backgroundColor: '#635ee7',
//    },
// })

export const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
   // textTransform: 'none',
   fontWeight: theme.typography.fontWeightRegular,
   fontSize: theme.typography.pxToRem(13),
   marginRight: theme.spacing(2),
   padding: 0,
   paddingLeft: 4,
   paddingRight: 4,
   minHeight: 0,
   // background: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)',
   color: '#000000',
   // '&.Mui-selected': {
   //    color: '#fff',
   // },
   '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
   },
}))

export function TabPanel(props) {
   const { children, value, index, ...other } = props

   return (
      <Box
         sx={
            {
               // backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
            }
         }
         role="tabpanel"
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               {/* <Typography>{children}</Typography> */}
               {children}
            </Box>
         )}
      </Box>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
}

export const StyledTab1 = styled((props) => <Tab {...props} />)(({ theme }) => ({
   // textTransform: 'none',
   fontWeight: theme.typography.fontWeightRegular,
   fontSize: theme.typography.pxToRem(13),
   marginRight: theme.spacing(1),
   padding: 0.5,
   minHeight: 0,
   whiteSpace: 'pre-line',
   // background: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)',
   color: '#000000',
   // '&.Mui-selected': {
   //    color: '#fff',
   // },
   '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
   },
}))

export function TabPanel1(props) {
   const { children, value, index, ...other } = props

   return (
      <Box
         sx={{
            minHeight: 275,
            maxHeight: 275,
            backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            borderRadius: 1,
            '&::-webkit-scrollbar': {
               width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
               background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
               backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
               background: '#555',
            },
         }}
         role="tabpanel"
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               {/* <Typography>{children}</Typography> */}
               {children}
            </Box>
         )}
      </Box>
   )
}

TabPanel1.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
}

export const StyledTab2 = styled((props) => <Tab {...props} />)(({ theme }) => ({
   // textTransform: 'none',
   fontWeight: theme.typography.fontWeightRegular,
   fontSize: theme.typography.pxToRem(13),
   marginRight: theme.spacing(2),
   padding: 0,
   minHeight: 0,
   whiteSpace: 'pre-line',
   // background: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)',
   color: '#000000',
   // '&.Mui-selected': {
   //    color: '#fff',
   // },
   '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
   },
}))

export function TabPanel2(props) {
   const { children, value, index, ...other } = props

   return (
      <Box
         sx={{
            minHeight: 300,
            // backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
               width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
               background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
               backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
               background: '#555',
            },
         }}
         role="tabpanel"
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               {/* <Typography>{children}</Typography> */}
               {children}
            </Box>
         )}
      </Box>
   )
}

TabPanel2.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
}

export function a11yProps(index) {
   return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
   }
}
