import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import validator from 'validator'

function AddTextAndWeblink({ labelName, handleAddTextAndWeblink }) {
   const [textAndWeblinkDetails, setTextAndWeblinkDetails] = useState({
      Text: '',
      Weblink: '',
   })
   useEffect(() => {
      console.log('Party details on change of its value: ', textAndWeblinkDetails)
   }, [textAndWeblinkDetails])

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid URL')
      } else {
         setErrorMessage('Is Not Valid URL')
      }
   }

   function handleTextAndWeblinkDetails(event) {
      setTextAndWeblinkDetails({ ...textAndWeblinkDetails, [event.target.name]: event.target.value })
      validate(event.target.value)
   }

   function handleSubmitTextAndWeblink(e) {
      e.preventDefault()

      if (textAndWeblinkDetails.Text === '') {
         alert('Please add some text.')
         return
      }

      if (errorMessage !== '' || errorMessage === 'Is Valid URL') {
         handleAddTextAndWeblink(textAndWeblinkDetails)
      }

      reset()
   }

   function reset() {
      setTextAndWeblinkDetails({
         Text: '',
         Weblink: '',
      })
   }

   return (
      <Box>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleTextAndWeblinkDetails}
               value={textAndWeblinkDetails.Text}
               name="Text"
               id="text"
               label={labelName}
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleTextAndWeblinkDetails}
               value={textAndWeblinkDetails.Weblink}
               name="Weblink"
               id="weblink"
               label="Website"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <div className={myStyles.text_align_center}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               onClick={(e) => {
                  handleSubmitTextAndWeblink(e)
               }}
               disabled={errorMessage === '' || errorMessage === 'Is Not Valid URL'}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddTextAndWeblink.propTypes = {
   handleAddTextAndWeblink: PropTypes.func.isRequired,
   labelName: PropTypes.string.isRequired,
}

export default AddTextAndWeblink
