import {
   Box,
   Button,
   Chip,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   MenuItem,
   OutlinedInput,
   Radio,
   RadioGroup,
   Select,
   useTheme,
} from '@mui/material'
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../../FirebaseConfig'
import CheckIcon from '@mui/icons-material/Check'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
}

function getStyles(name, personName, theme) {
   return {
      fontWeight:
         personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
   }
}

function AddECIStatus({ countryId, handleAddECIStatus }) {
   const theme = useTheme()

   // console.log('country id in here: ', countryId)

   const [selectedLevel, setSelectedLevel] = useState('')
   //    const [levels, setLevels] = useState('')

   const [statesList, setStatesList] = useState('')
   const [selectedStateNames, setSelectedStateNames] = useState([])

   const [fetchStatus, setFetchStatus] = useState('idle')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchLevels()
         fetchStates(countryId)
         setFetchStatus('success')
      }
   }, [])

   const [eciStatusDetails, setECIStatusDetails] = useState({
      ValueRadio: '',
      ECIStatus: '',
      ECIStatusDate: '',
      State_ids: [],
      StateNames: [],
   })

   useEffect(() => {
      console.log('eci Status Details on change: ', eciStatusDetails)
   }, [eciStatusDetails])

   // related to radio button

   const [levels, setLevels] = useState([])

   const fetchLevels = async () => {
      try {
         const docRef = doc(db, 'BaseLists', 'PartyLevels-lsTDGRF8XHWnR3VjVuB4')
         const docSnap = await getDoc(docRef)

         setLevels(docSnap.data().Levels)
      } catch (err) {
         console.log('error', err)
      }
   }

   const handleChangeRadio = (e) => {
      e.preventDefault()
      setECIStatusDetails({
         ...eciStatusDetails,
         ValueRadio: e.target.value,
      })
   }

   const handleLevelChange = (e) => {
      e.preventDefault()
      setSelectedLevel(e.target.value)
      setECIStatusDetails({
         ...eciStatusDetails,
         ECIStatus: e.target.value,
      })
   }

   // related to states

   const fetchStates = async (countryId) => {
      try {
         console.log('entered fetch states block with country id: ', countryId)
         const q = query(collection(db, 'States'), where('Country_id', '==', countryId), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         console.log('States fetched querySnapshot: ', querySnapshot)
         const statesFetched = []
         querySnapshot.forEach((doc) => {
            statesFetched.push({ id: doc.id, ...doc.data() })
            console.log('this doc: ', doc)
         })
         setStatesList(statesFetched)
         console.log('States fetched by country: ', statesFetched)
      } catch (err) {
         console.log('error fetching states: ', err)
      }
   }

   const handleChangeState = (event) => {
      const {
         target: { value },
      } = event
      // setselectedStateIDs(
      //    // On autofill we get a stringified value.
      //    typeof value === 'string' ? value.split(',') : value,
      // )
      setSelectedStateNames(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value,
      )
   }

   useEffect(() => {
      if (statesList) {
         //  setConstiDetails({ ...constiDetails, StateName: selectedStateNames })
         if (selectedStateNames && selectedStateNames.length) {
            console.log('reached use effect of selected district names inside if condition')
            const selectedStatesList = statesList.filter((f) => selectedStateNames.some((item) => item === f.Name))
            const selectedStateIDsFetched = []
            selectedStatesList.forEach((obj) => {
               selectedStateIDsFetched.push(obj.id)
            })
            // setSelectedStateIDs(selectedStateIDsFetched)
            setECIStatusDetails({
               ...eciStatusDetails,
               State_ids: selectedStateIDsFetched,
               StateNames: selectedStateNames,
            })

            console.log('selected state IDs fetched: ', selectedStateIDsFetched)
         } else {
            console.log('reached use effect of selected state names inside else condition')

            // setSelectedStateIDs([])
            setECIStatusDetails({
               ...eciStatusDetails,
               State_ids: [],
               StateNames: [],
            })
            console.log('selected district IDs fetched: CLEARED')
         }
      }
   }, [selectedStateNames])

   // related to form filling and submit

   function handleECIStatusSubmit(e) {
      e.preventDefault()

      if (selectedLevel === 'State level' && selectedStateNames.length === 0) {
         alert('States cannot be empty. Please add at least 1 state')
         return
      }
      handleAddECIStatus(eciStatusDetails)

      reset()
   }

   function reset() {
      setECIStatusDetails({
         ValueRadio: '',
         ECIStatus: '',
         ECIStatusDate: '',
         State_ids: [],
         StateNames: [],
      })
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
               <Box>
                  <DatePicker
                     label="Notification Date"
                     name="ECIStatusDate"
                     value={eciStatusDetails.ECIStatusDate != null ? dayjs(eciStatusDetails.ECIStatusDate) : null}
                     format="DD-MMM-YYYY"
                     onChange={(newValue) =>
                        setECIStatusDetails({
                           ...eciStatusDetails,
                           ECIStatusDate: new Date(newValue),
                        })
                     }
                     size="small"
                     views={['year', 'month', 'day']}
                     sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                  />
               </Box>
               <Box>
                  <FormControl sx={{ border: 1, borderRadius: 1, borderColor: 'text.disabled', px: 2, mt: 1 }}>
                     <FormLabel id="demo-row-radio-buttons-group-label">Relevance*</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={eciStatusDetails.ValueRadio}
                        onChange={(e) => {
                           handleChangeRadio(e)
                        }}
                     >
                        <FormControlLabel
                           value="Current status"
                           control={<Radio size="small" />}
                           label="Current status"
                        />
                        <FormControlLabel value="Old status" control={<Radio size="small" />} label="Old status" />
                     </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ my: 1, width: 300 }} size="small">
                     <InputLabel id="status-label">Status</InputLabel>
                     <Select
                        labelId="status-label"
                        id="status"
                        value={eciStatusDetails.ECIStatus}
                        label="Status"
                        onChange={(e) => {
                           handleLevelChange(e)
                        }}
                        sx={{ backgroundColor: '#FFFFFF' }}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {levels &&
                           levels.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Box>
               <Box>
                  <FormControl sx={{ mb: 1, width: 300 }}>
                     <InputLabel id="connected-states-label">Connected States</InputLabel>
                     <Select
                        labelId="connected-states-label"
                        id="connected-states"
                        sx={{ backgroundColor: '#ffffff' }}
                        multiple
                        value={eciStatusDetails.StateNames}
                        onChange={handleChangeState}
                        input={<OutlinedInput id="select-multiple-chip" label="Connected States" />}
                        renderValue={(selected) => (
                           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                 <Chip key={value} label={value} />
                              ))}
                           </Box>
                        )}
                        MenuProps={MenuProps}
                     >
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem
                                 key={item.id}
                                 value={item.Name}
                                 style={getStyles(item.id, selectedStateNames, theme)}
                              >
                                 {item.Name}
                                 {selectedStateNames.includes(item.Name) ? <CheckIcon color="info" /> : null}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Box>
               <Box sx={{ textAlign: 'center' }}>
                  <Button
                     type="submit"
                     align="center"
                     variant="contained"
                     color="primary"
                     size="small"
                     sx={{ my: 1 }}
                     onClick={(e) => {
                        handleECIStatusSubmit(e)
                     }}
                     disabled={
                        eciStatusDetails.ECIStatus.length < 3 ||
                        eciStatusDetails.ECIStatusDate === '' ||
                        eciStatusDetails.ValueRadio.length < 5 ||
                        (eciStatusDetails.Level === 'State level' && eciStatusDetails.State_ids === '')
                     }
                  >
                     Submit
                  </Button>
               </Box>
            </Box>
         </div>
      </LocalizationProvider>
   )
}

AddECIStatus.propTypes = {
   countryId: PropTypes.string.isRequired,
   partyLevels: PropTypes.array.isRequired,
   handleAddECIStatus: PropTypes.func.isRequired,
}

export default AddECIStatus
