import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Avatar,
   Badge,
   Box,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   IconButton,
   InputLabel,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Radio,
   RadioGroup,
   Select,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
// import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import PeopleIcon from '@mui/icons-material/People'

import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../../FirebaseConfig'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore'

import AddConstisToPhase from './AddConstisToPhase'

function ElecConstis({ elec, legis, handleConstituencyCandidatesLink }) {
   console.log('value from props from inside Elec Constis page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // style for modal
   const styleModalBox = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: '#ffffff',
      border: '0',
      borderRadius: 1,
      boxShadow: 24,
      pb: 1,
      mx: 1,

      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
         width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
         background: '#f1f1f1',
         borderRadius: 5,
         // margin: 5,
      },
      '&::-webkit-scrollbar-thumb': {
         backgroundColor: '#000000',
         borderRadius: 5,
      },
      '&::-webkit-scrollbar-thumb:hover': {
         background: '#555',
      },

      maxHeight: '90%',
      overflowY: 'auto',

      // background: 'linear-gradient(to top, #cfd1a8 0%, #f9fff3 100%)',
   }

   const styleModalTypography = {
      fontSize: '14px',
      color: '#ffffff',
      backgroundImage: 'linear-gradient(to right, #ffffff, #eb9191, #797580, #797580, #eb9191, #ffffff)',
      mt: 1,
      mb: 2,
   }

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [phasesList, setPhasesList] = useState('')
   const [constisListFromLegis, setConstisListFromLegis] = useState('')
   const [constisListRoot, setConstisListRoot] = useState('')
   const [constisListAvailable, setConstisListAvailable] = useState('')
   const [constisList, setConstisList] = useState('')
   const [selectedPhase, setSelectedPhase] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         const sortedConstisListRoot = [...elec.Constituencies].sort(
            (a, b) => a.ConstituencyNumber - b.ConstituencyNumber,
         )
         setConstisListRoot(sortedConstisListRoot)
         const sortedConstisListFromLegis = [...legis.Constituencies].sort(
            (a, b) => a.ConstituencyNumber - b.ConstituencyNumber,
         )
         setConstisListFromLegis(sortedConstisListFromLegis)

         const sortedPhases = [...elec.Phases].sort((a, b) => a.PhaseNum - b.PhaseNum)
         setPhasesList(sortedPhases)

         setFirstLoadStatus('success')
      }
   }, [])

   useEffect(() => {
      if (phasesList && phasesList.length > 0) {
         const item = phasesList[0]
         setSelectedPhase(item.PhaseNum)
      }
   }, [phasesList])

   const handleChangePhase = async (e) => {
      e.preventDefault()

      setSelectedPhase(e.target.value)
   }

   useEffect(() => {
      if (selectedPhase) {
         setConstisList(constisListRoot.filter((item) => item.PhaseNum === selectedPhase))
         setSortField('ConstituencyNumber')
      }
   }, [selectedPhase])

   const [sortField, setSortField] = useState('Name')

   function handleSortField(e) {
      e.preventDefault()
      setSortField(e.target.value)
   }

   useEffect(() => {
      if (sortField) {
         let sortedConstis
         switch (sortField) {
            case 'Name':
               sortedConstis = [...constisList].sort((a, b) => {
                  if (b.Name > a.Name) {
                     return -1
                  } else if (b.Name < a.Name) {
                     return 1
                  } else {
                     return 0
                  }
               })
               break
            case 'ConstituencyNumber':
               sortedConstis = [...constisList].sort((a, b) => a.ConstituencyNumber - b.ConstituencyNumber)
               break
         }

         setConstisList(sortedConstis)
      }
   }, [sortField])

   // related to form filling and submission of constis

   const [openModalAddConstisToPhase, setOpenModalAddConstisToPhase] = useState(false)

   const handleOpenModalAddConstisToPhase = (e) => {
      e.preventDefault()

      if (constisListFromLegis && constisListRoot.length > 0) {
         // Note: the following code collects all the Constituency_is from the constisListRoot
         const filterIds = new Set(constisListRoot.map((item) => item.Constituency_id))

         // Note: the following code filters out all the filterIds from the constisListFromLegis
         const filteredArray = constisListFromLegis.filter((item) => !filterIds.has(item.Constituency_id))

         setConstisListAvailable(filteredArray)
      } else {
         setConstisListAvailable(constisListFromLegis)
      }

      setOpenModalAddConstisToPhase(true)
   }

   const handleCloseModalAddConstisToPhase = () => setOpenModalAddConstisToPhase(false)

   const [uploadingConstis, setUploadingConstis] = useState(false)
   const [uploadedConstis, setUploadedConstis] = useState(false)
   const [uploadingFailedConstis, setUploadingFailedConstis] = useState(false)

   const handleAddConstisToPhase = async (constisListSelected) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (constisListSelected.length > 0) {
               try {
                  setUploadingConstis(true)
                  const tempListRoot = constisListRoot
                  const tempListConsti = constisList

                  const docRef = doc(db, 'Elections', elec.id)

                  constisListSelected.forEach(async (item) => {
                     const thisConstituency = constisListFromLegis.find(
                        (itemConsti) => itemConsti.Constituency_id === item,
                     )

                     thisConstituency.CrBy = auth.currentUser.uid
                     thisConstituency.CrDt = new Date()
                     thisConstituency.CrIP = ip.data.ip
                     thisConstituency.IsDeleted = false
                     thisConstituency.PhaseNum = selectedPhase

                     await updateDoc(docRef, {
                        Constituencies: arrayUnion(thisConstituency),
                     })

                     setConstisList([...constisList, thisConstituency])

                     tempListRoot.push(thisConstituency)
                     console.log('temp list root:', tempListRoot)

                     tempListConsti.push(thisConstituency)
                     console.log('temp list consti:', tempListConsti)
                  })

                  setConstisListRoot(tempListRoot)
                  console.log('consti list root 2:', tempListRoot)
                  setConstisList(tempListConsti)
                  console.log('consti list consti 2:', tempListConsti)

                  setUploadingConstis(false)
                  setUploadedConstis(true)
                  setUploadingFailedConstis(false)

                  setOpenModalAddConstisToPhase(false)
               } catch (error) {
                  alert('Error adding election 22: ', error.message)
                  console.log('Error adding electione', error)
                  setUploadingConstis(false)
                  setUploadedConstis(false)
                  setUploadingFailedConstis(true)

                  setOpenModalAddConstisToPhase(false)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (
      e,
      thisConstituencyId,
      thisLegislatureId,
      thisLegislatureName,
      thisConstituencyNumber,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisNameAlt,
      thisStateName,
      thisStateId,
      thisDivisionName,
      thisDivisionId,
      thisDistrictName,
      thisDistrictId,
      thisSubDistrictId,
      thisSubDistrictName,
      thisBlockId,
      thisBlockName,
      thisVillageId,
      thisVillageName,
      thisParentConstiId,
      thisParentConstiName,
      thisIsNominated,
      thisStrata,
      thisIsDeleted,
      thisPhaseNum,
      thisCrBy,
      thisCrDt,
      thisCrIP,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this constitency?')) {
               try {
                  const docRefUpdate = doc(db, 'Elections', elec.id)
                  const record = {
                     Constituency_id: thisConstituencyId,
                     Legislature_id: thisLegislatureId,
                     LegislatureName: thisLegislatureName,
                     ConstituencyNumber: thisConstituencyNumber,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     NameAlt: thisNameAlt,
                     StateName: thisStateName,
                     State_id: thisStateId,
                     DivisionName: thisDivisionName,
                     Division_id: thisDivisionId,
                     DistrictName: thisDistrictName,
                     District_id: thisDistrictId,
                     SubDistrict_id: thisSubDistrictId,
                     SubDistrictName: thisSubDistrictName,
                     Block_id: thisBlockId,
                     BlockName: thisBlockName,
                     Village_id: thisVillageId,
                     VillageName: thisVillageName,
                     ParentConsti_id: thisParentConstiId,
                     ParentConstiName: thisParentConstiName,
                     IsNominated: thisIsNominated,
                     Strata: thisStrata,
                     IsDeleted: thisIsDeleted,
                     PhaseNum: thisPhaseNum,
                     CrBy: thisCrBy,
                     CrDt: thisCrDt,
                     CrIP: thisCrIP,
                  }
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayRemove(record),
                  })

                  setConstisList(constisList.filter((item) => item.Constituency_id !== thisConstituencyId))
                  setConstisListRoot(constisListRoot.filter((item) => item.Constituency_id !== thisConstituencyId))
               } catch (error) {
                  alert('Error deleting district')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleEdit = async (id) => {
      //
   }

   // const handleDetail = async (id) => {
   //    //
   // }

   const handleSubmitCandidateLink = async (e, constituencyId) => {
      e.preventDefault()

      console.log('constiDetails before submit: ')
      handleConstituencyCandidatesLink(constituencyId)
   }

   return (
      <Box sx={{ px: 1 }}>
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               px: 1,
               mt: 0.5,
               '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
            }}
         >
            <Box>
               <FormControl sx={{ ml: 1 }} size="small">
                  <InputLabel>Phase / चरण</InputLabel>
                  <Select
                     value={selectedPhase}
                     label="Phase / चरण"
                     onChange={(e) => {
                        handleChangePhase(e)
                     }}
                  >
                     {phasesList &&
                        phasesList.length > 0 &&
                        phasesList.map((item) => (
                           <MenuItem key={item.PhaseNum} value={item.PhaseNum}>
                              {item.PhaseNum}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Box>
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  px: 0.5,
                  ml: 'auto',
                  border: 1,
                  borderRadius: 1,
                  borderColor: '#888889',
               }}
            >
               <Box sx={{ color: '#795548', fontSize: 15 }}>Sort by:</Box>
               <Box>
                  <FormControl
                     sx={{
                        p: 0,
                     }}
                  >
                     {/* <FormLabel id="demo-controlled-radio-buttons-group">Sort by:</FormLabel> */}
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="SortField"
                        value={sortField}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handleSortField(e)
                        }}
                        sx={{
                           backgroundColor: '#ffffff',
                           borderRadius: 1,
                           py: 0,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 13,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        <FormControlLabel
                           value="Name"
                           control={<Radio size="small" />}
                           label="Name"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="ConstituencyNumber"
                           control={<Radio size="small" />}
                           label="Constituency number"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     </RadioGroup>
                  </FormControl>
               </Box>
            </Box>
         </Box>
         <Box>
            <Box
               sx={{
                  p: 1,
                  mt: 0.5,
                  backgroundImage: 'linear-gradient(to right, #48464c, #605d66, #eb9191, #eb9191, #efa7a7)',
                  color: '#ffffff',
               }}
            >
               {phasesList && phasesList.length < 1 && <Box>Constituencies for this election:</Box>}
               {phasesList && phasesList.length < 1 && <Box>इस चुनाव के लिए निर्वाचन क्षेत्र:</Box>}
               {phasesList && phasesList.length > 0 && <Box>Constituencies under phase {selectedPhase}:</Box>}
               {phasesList && phasesList.length > 0 && <Box>चरण {selectedPhase} के निर्वाचन क्षेत्र:</Box>}
            </Box>
            {phasesList && phasesList.length < 1 && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        To be available soon. <br /> शीघ्र उपलब्ध होगा |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}

            {(isSuper || isDataEditor) && phasesList && phasesList.length > 0 && selectedPhase !== '' && (
               <Box>
                  <Button
                     sx={{ py: 0, px: 1, minWidth: 0 }}
                     onClick={(e) => {
                        handleOpenModalAddConstisToPhase(e)
                     }}
                  >
                     Add Constituencies
                  </Button>
               </Box>
            )}

            <Box>
               <List dense>
                  {constisList &&
                     constisList.length > 0 &&
                     constisList.map((item, index) => (
                        <div key={item.id}>
                           <ListItem
                              key={item.id}
                              secondaryAction={
                                 <Box
                                    sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                 >
                                    <Box>
                                       <Button
                                          size="small"
                                          sx={{ padding: 0, minWidth: 0 }}
                                          endIcon={<PeopleIcon />}
                                          onClick={(e) => {
                                             handleSubmitCandidateLink(e, item.Constituency_id)
                                          }}
                                       >
                                          Candidates
                                       </Button>
                                    </Box>
                                    <Box>
                                       <Button
                                          size="small"
                                          sx={{ padding: 0, minWidth: 0 }}
                                          endIcon={<PeopleIcon />}
                                          onClick={(e) => {
                                             handleSubmitCandidateLink(e, item.Constituency_id)
                                          }}
                                       >
                                          प्रत्याशी
                                       </Button>
                                    </Box>
                                    <Box>
                                       {isSuper && (
                                          <IconButton
                                             edge="end"
                                             aria-label="delete"
                                             onClick={(e) => {
                                                handleDelete(
                                                   e,
                                                   item.Constituency_id,
                                                   item.Legislature_id,
                                                   item.LegislatureName,
                                                   item.ConstituencyNumber,
                                                   item.Name,
                                                   item.NameHindi,
                                                   item.NameLocal,
                                                   item.NameAlt,
                                                   item.StateName,
                                                   item.State_id,
                                                   item.DivisionName,
                                                   item.Division_id,
                                                   item.DistrictName,
                                                   item.District_id,
                                                   item.SubDistrict_id,
                                                   item.SubDistrictName,
                                                   item.Block_id,
                                                   item.BlockName,
                                                   item.Village_id,
                                                   item.VillageName,
                                                   item.ParentConsti_id,
                                                   item.ParentConstiName,
                                                   item.IsNominated,
                                                   item.Strata,
                                                   item.IsDeleted,
                                                   item.PhaseNum,
                                                   item.CrBy,
                                                   item.CrDt,
                                                   item.CrIP,
                                                )
                                             }}
                                             sx={{ mr: 1, height: '25px', width: '25px' }}
                                          >
                                             <DeleteIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                       {(isDataEditor || isSuper) && (
                                          <IconButton
                                             edge="end"
                                             aria-label="edit"
                                             onClick={() => {
                                                handleEdit(item.id)
                                             }}
                                             sx={{ mr: 1, height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>

                                    {/* <IconButton
                                       edge="end"
                                       aria-label="detail"
                                       onClick={() => {
                                          handleDetail(item.id)
                                       }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <InfoSharpIcon fontSize="small" />
                                    </IconButton> */}
                                 </Box>
                              }
                           >
                              <ListItemAvatar>
                                 <Avatar sx={{ height: '30px', width: '30px' }}>
                                    <Badge badgeContent={index + 1} color="secondary" max={999}></Badge>
                                 </Avatar>
                              </ListItemAvatar>

                              <ListItemText
                                 secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line', fontSize: '12px' }}
                                 primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                 primary={
                                    <>
                                       {item.Name} ({item.ConstituencyNumber}) {item.NameLocal && <>{item.NameLocal}</>}{' '}
                                       {item.NameHindi}
                                    </>
                                 }
                                 component={'span'}
                                 secondary={
                                    <>
                                       {item.Strata === 'State' && (
                                          <>
                                             {' Parent PC: '}
                                             <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                             >
                                                {item.ParentConstiName}
                                             </Typography>
                                             <br />
                                          </>
                                       )}
                                       {' District: '}
                                       <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                       >
                                          {item.DistrictName.join(', ')}
                                       </Typography>
                                       {item.DivisionName !== '' && (
                                          <>
                                             <br />
                                             {' Division: '}
                                             <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                             >
                                                {item.DivisionName.join(', ')}
                                             </Typography>
                                          </>
                                       )}
                                       {/* {legis.Strata === 'Central' && (
                                    <>
                                       <br />
                                       {' State / UT: '}
                                       <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                       >
                                          {item.StateName}
                                       </Typography>
                                    </>
                                 )} */}
                                    </>
                                 }
                              />
                           </ListItem>
                           <Divider />
                        </div>
                     ))}
               </List>
            </Box>
            <Box id="boxModals">
               <Modal
                  open={openModalAddConstisToPhase}
                  onClose={handleCloseModalAddConstisToPhase}
                  aria-labelledby="modal-modal-title-addConstis"
                  aria-describedby="modal-modal-description-addConstis"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addConstis"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add constituencies to phase {selectedPhase}
                     </Typography>
                     <AddConstisToPhase
                        constisListAvailable={constisListAvailable}
                        uploadingConstis={uploadingConstis}
                        uploadedConstis={uploadedConstis}
                        uploadingFailedConstis={uploadingFailedConstis}
                        handleAddConstisToPhase={handleAddConstisToPhase}
                     ></AddConstisToPhase>
                  </Box>
               </Modal>
            </Box>
         </Box>
      </Box>
   )
}

ElecConstis.propTypes = {
   elec: PropTypes.object.isRequired,
   legis: PropTypes.object.isRequired,
   handleConstituencyCandidatesLink: PropTypes.func.isRequired,
}

export default ElecConstis
