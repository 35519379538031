import React from 'react'
import PropTypes from 'prop-types'

function ElecCandiWorks({ props }) {
   const elecCandi = props
   console.log('value from props from inside Elec Candi Works : ', elecCandi)
   return <div>Elec Candi Works </div>
}

ElecCandiWorks.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecCandiWorks
