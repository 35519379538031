import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../../FirebaseConfig'
import { Avatar, Badge, Box, Card, CardContent, CardMedia, Link, Tabs, Typography, useTheme } from '@mui/material'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import moment from 'moment'
// import { styled } from '@mui/material/styles'

import { TabContext } from '@mui/lab'
import { a11yProps, StyledTab1, TabPanel2 } from '../../../Components/TabPanelSystem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import SwipeableViews from 'react-swipeable-views'
import ElecCandiBasicInfo from '../ElecCandiBasicInfo/ElecCandiBasicInfo'
import ElecCandiCred from '../ElecCandiCred/ElecCandiCred'
import ElecCandiWorks from '../ElecCandiWorks/ElecCandiWorks'
import ElecCandiFeed from '../ElecCandiFeed/ElecCandiFeed'
import {
   SmallAvatar,
   stringAvatar150,
   stringAvatar150Single,
   stringAvatar50,
   stringAvatar50Single,
} from '../../../Utils/MUITheme/MUITheme'

function ElecCandi() {
   const { candiId, candiName } = useParams()
   const navigate = useNavigate()

   // const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [candi, setCandi] = useState('')
   const [elec, setElec] = useState('')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         console.log('reached first blank use effect')
         fetchCandidate(candiId)
      }
   }, [])

   const fetchCandidate = async (candiId) => {
      try {
         const docRef = doc(db, 'ElectionCandidates', candiId)
         const docSnap = await getDoc(docRef)
         console.log('reached fetching')
         if (docSnap.exists()) {
            setCandi({ ...docSnap.data(), id: docSnap.id })

            // console.log('Date 1: ', docSnap.data().DateStart, 'Date 2: ', docSnap.data().DateStart.toDate())
            // console.log('Date 3: ', new Date(docSnap.data().DateStart).toDateString())
            // console.log('Date 4: ', new Date(new Date(docSnap.data().DateStart).toDateString()))
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      console.log('reached use effect of elec ..')
      if (candi !== '') {
         if (candiId !== candi.id || candiName !== candi.Name) {
            navigate('/', { replace: true })
         } else {
            fetchElection(candi.Election_id)
         }
      } else {
         console.log('use effect of elec is null: ')
      }
   }, [candi])

   const fetchElection = async (ElecId) => {
      try {
         const docRef = doc(db, 'Elections', ElecId)
         const docSnap = await getDoc(docRef)
         console.log('reached fetching legislature')
         if (docSnap.exists()) {
            setElec({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      console.log('reached use effect of legis ..')
      if (elec !== '') {
         //
      } else {
         console.log('use effect of legis is null: ')
      }
   }, [elec])

   const theme = useTheme()

   function countWordsUsingReplace(str) {
      // Replace multiple spaces, newlines, and tabs with a single space
      const normalizedStr = str.replace(/\s+/g, ' ').trim()
      // Split the string by a single space and count the resulting words
      return normalizedStr === '' ? 0 : normalizedStr.split(' ').length
   }

   //
   return (
      <Box sx={{ minHeight: 600 }}>
         <Card
            sx={{
               my: 1,
               mx: 5,
               py: 1,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #797580, #545159)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto' }}>
                  {candi && (
                     <div>
                        {candi.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22 }}
                              color="#FFFFFF"
                           >
                              {candi.NameLocal}
                           </Typography>
                        )}
                        {candi.NameHindi && candi.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22 }}
                              color="#FFFFFF"
                           >
                              {candi.NameHindi}
                           </Typography>
                        )}
                        {candi.NameHindi && candi.NameLocal !== '' && (
                           <Typography component="div" sx={{ fontWeight: 700, fontSize: 16 }} color="#FFFFFF">
                              {candi.NameHindi}
                           </Typography>
                        )}
                        {candi.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22 }}
                              color="#FFFFFF"
                           >
                              {candi.Name}
                           </Typography>
                        )}
                        {candi.NameLocal !== '' && (
                           <Typography component="div" sx={{ fontWeight: 700, fontSize: 16 }} color="#FFFFFF">
                              {candi.Name}
                           </Typography>
                        )}
                        <Box sx={{ display: 'flex', direction: 'row' }}>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                              }}
                           >
                              {candi.ElectionNameLocal !== '' && (
                                 <Box>
                                    <Link
                                       sx={{ color: '#ff7f4c', fontWeight: 700 }}
                                       href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                    >
                                       {candi.ElectionNameLocal}
                                    </Link>
                                 </Box>
                              )}
                              <Box>
                                 <Link
                                    sx={{ color: '#ff7f4c', fontWeight: 700 }}
                                    href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                 >
                                    {candi.ElectionNameHindi}
                                 </Link>
                              </Box>
                              <Box>
                                 <Link
                                    sx={{ color: '#ff7f4c', fontWeight: 700 }}
                                    href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                 >
                                    {candi.ElectionName}{' '}
                                 </Link>
                              </Box>
                              {elec && elec.DateStart && (
                                 <Box
                                    sx={{
                                       display: 'inline',
                                       mx: 0.5,
                                       fontSize: 14,
                                       fontWeight: 400,
                                       color: '#ffffff',
                                    }}
                                 >
                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY')}

                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY') !==
                                       moment(elec.DateFinish.toDate()).format('Do MMM YYYY') && (
                                       <> - {moment(elec.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                 </Box>
                              )}
                           </Box>
                        </Box>
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
            // component="img"
            // sx={{ width: 151, borderRadius: 1, mr: 1 }}
            // image="https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            // alt="Live from space album cover"
            >
               {candi.Name && (
                  <Badge
                     overlap="circular"
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                     badgeContent={
                        countWordsUsingReplace(candi.Name) > 1 ? (
                           <SmallAvatar
                              {...stringAvatar50(candi.Name)}
                              alt="Travis Howard"
                              src={candi.PartyFlag ? candi.PartyFlag : candi.AllottedSymbol}
                           />
                        ) : (
                           <SmallAvatar
                              {...stringAvatar50Single(candi.Name)}
                              alt="Travis Howard"
                              src={candi.PartyFlag ? candi.PartyFlag : candi.AllottedSymbol}
                           />
                        )
                     }
                  >
                     {countWordsUsingReplace(candi.Name) > 1 ? (
                        <Avatar {...stringAvatar150(candi.Name)} alt="Travis Howard" src={candi.Image} />
                     ) : (
                        <Avatar {...stringAvatar150Single(candi.Name)} alt="Travis Howard" src={candi.Image} />
                     )}
                  </Badge>
               )}

               {/* <Avatar alt="An apple" sx={{ height: 150, width: 150 }} src={candi.Image} /> */}
            </CardMedia>
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     // pl: 5,
                     minHeight: 25,
                     // background: 'linear-gradient(90deg, #0062ff, #da61ff)',
                     // background: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Credentials \n प्रामाणिकताएँ`} {...a11yProps(1)} />
                  <StyledTab1 label={`Works \n कार्य`} {...a11yProps(2)} />
                  <StyledTab1 label={`Interact \n संवाद करें`} {...a11yProps(3)} />
               </Tabs>
            </div>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {candi && <ElecCandiBasicInfo candi={candi} elec={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {candi && <ElecCandiCred props={candi} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {candi && <ElecCandiWorks candi={candi} elec={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {candi && <ElecCandiFeed candi={candi} elec={elec} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={4}>
                  xs=4
               </Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default ElecCandi
