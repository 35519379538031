import React, { useEffect, useState } from 'react'
import { collection, addDoc, deleteDoc, doc, updateDoc, arrayUnion, arrayRemove, increment } from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'

import {
   Alert,
   Box,
   Button,
   FormControl,
   // FormControlLabel,
   // FormLabel,
   InputLabel,
   Link,
   MenuItem,
   // Radio,
   // RadioGroup,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'

import styles from './GeoDivisions.module.css'
// import { useSelector } from 'react-redux'
// import { selectUser } from '../../redux/reducers/usersSlice'
import Header from '../../Components/Header/Header'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../redux/reducers/users-slice'
import { selectCountries } from '../../redux/reducers/countries-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { useNavigate } from 'react-router-dom'

function StatesAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [stateDetails, setStateDetails] = useState({
      Name: '',
      NameAlt: '',
      Capital: '',
      Capital_alt: '',
      DownLevel: '',
      Type: '',
      Flag: null,
      Symbol: null,
      ForLegis: false,
      Country_id: '',
      CountryName: '',
      Divisions: [],
      Districts: [],
      Legislatures: [],
      VillagesCount: 0,
      BlocksCount: 0,
      SubDistrictsCount: 0,
      DistrictsCount: 0,
      DivisionsCount: 0,
      LGDCode: '',
      NameLocal: '',
      NameHindi: '',
      CensusCode: [],
      Map: '',
      NameCode: '',
      LocalLanguageEng: '',
      LocalLanguageHindi: '',
      LocalLanguageLocal: '',
      CrBy: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',
   })
   useEffect(() => {
      console.log('state details on change of its value: ', stateDetails)
   }, [stateDetails])

   // related to countries
   const [selectedCountry, setSelectedCountry] = useState('')
   const countries = useSelector(selectCountries).countriesList

   const handleChangeCountry = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedCountry(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedCountry) {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)
         setStateDetails({ ...stateDetails, Country_id: selectedCountry, CountryName: selectedCountryComplete.Name })
         fetchStatesList()
      } else {
         setStateDetails({ ...stateDetails, Country_id: '', CountryName: '' })
         setStatesList('')
      }
   }, [selectedCountry])

   // related to states
   const [statesList, setStatesList] = useState('')
   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === selectedCountry)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
         console.log(sortedList)
      } catch (err) {
         console.log('error', err)
      }
   }

   // relate to form filling and submission
   const [lastState, setLastState] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   function handleStateDetails(e) {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setStateDetails({ ...stateDetails, [e.target.name]: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleTypeChange = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setStateDetails({ ...stateDetails, Type: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDownLevelChange = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setStateDetails({ ...stateDetails, DownLevel: e.target.value })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleAddState = async (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               stateDetails.Name.length >= 3 &&
               stateDetails.Capital.length >= 3 &&
               stateDetails.Type.length >= 3 &&
               stateDetails.DownLevel.length >= 3 &&
               stateDetails.Country_id.length >= 2
            ) {
               stateDetails.CrBy = auth.currentUser.uid
               stateDetails.CrDt = new Date()
               stateDetails.CrIP = ip.data.ip
               setLastState(stateDetails.Name)
               setStateDetails({
                  ...stateDetails,
                  Name: '',
                  NameAlt: '',
                  Capital: '',
                  Capital_alt: '',
                  DownLevel: '',
                  Type: '',
                  LGDCode: '',
                  Flag: null,
                  Symbol: null,
                  CrBy: '',
                  CrDt: '',
                  CrDtSys: '',
                  CrIP: '',
               })
               setUploading(true)
               try {
                  const docRef = await addDoc(collection(db, 'States'), stateDetails)

                  const newDetailForStateList = {
                     State_id: docRef.id,
                     Name: stateDetails.Name,
                     NameLocal: stateDetails.NameLocal,
                     NameHindi: stateDetails.NameHindi,
                     NameAlt: stateDetails.NameAlt,
                     Type: stateDetails.Type,
                     Capital: stateDetails.Capital,
                     Flag: stateDetails.Flag,
                     Symbol: stateDetails.Symbol,
                     DownLevel: stateDetails.DownLevel,
                     ForLegis: false,
                  }

                  const docRefUpdate = doc(db, 'Countries', stateDetails.Country_id)
                  await updateDoc(docRefUpdate, {
                     States: arrayUnion(newDetailForStateList),
                     StatesCount: increment(1),
                  })

                  stateDetails.id = docRef.id
                  setStatesList([...statesList, newDetailForStateList])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
                  console.log(
                     'stateDetails 3: ',
                     stateDetails,
                     ' uploading: ',
                     uploading,
                     ' uploaded: ',
                     uploaded,
                     ' uploadingFailed: ',
                     uploadingFailed,
                  )
               } catch (error) {
                  alert('Error adding state name')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (
      e,
      idd,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisNameAlt,
      thisType,
      thisCapital,
      thisFlag,
      thisSymbol,
      thisDownLevel,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            console.log('For Legis of ', thisName, ' is ', thisForLegis)
            const stateRef = doc(db, 'States', idd)
            try {
               await updateDoc(stateRef, {
                  ForLegis: !thisForLegis,
               })

               const docRefUpdate = doc(db, 'Countries', selectedCountry)
               await updateDoc(docRefUpdate, {
                  States: arrayRemove({
                     State_id: idd,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     NameAlt: thisNameAlt,
                     Type: thisType,
                     Capital: thisCapital,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     DownLevel: thisDownLevel,
                     ForLegis: thisForLegis,
                  }),
               })

               await updateDoc(docRefUpdate, {
                  States: arrayUnion({
                     State_id: idd,
                     Name: thisName,
                     NameLocal: thisNameLocal,
                     NameHindi: thisNameHindi,
                     NameAlt: thisNameAlt,
                     Type: thisType,
                     Capital: thisCapital,
                     Flag: thisFlag,
                     Symbol: thisSymbol,
                     DownLevel: thisDownLevel,
                     ForLegis: !thisForLegis,
                  }),
               })

               setEditStatus(true)
               setForLegis({ id: idd, ForLegis: thisForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         console.log('New for legis: ', forLegis)
         // eslint-disable-next-line array-callback-return
         statesList.map((item) => {
            if (item.State_id === forLegis.id) {
               try {
                  item.ForLegis = !forLegis.ForLegis
               } catch (error) {
                  alert('Error updating state item ...')
                  console.log(error)
               }
            }
         })
         setEditStatus(false)
         setForLegis({ id: '', ForLegis: false })
      }
   }, [forLegis, editStatus])

   const handleDelete = async (
      e,
      id,
      thisName,
      thisNameLocal,
      thisNameHindi,
      thisNameAlt,
      thisType,
      thisCapital,
      thisFlag,
      thisSymbol,
      thisDownLevel,
      thisForLegis,
   ) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this state?')) {
               try {
                  await deleteDoc(doc(db, 'States', id))

                  const docRefUpdate = doc(db, 'Countries', selectedCountry)
                  await updateDoc(docRefUpdate, {
                     States: arrayRemove({
                        State_id: id,
                        Name: thisName,
                        NameLocal: thisNameLocal,
                        NameHindi: thisNameHindi,
                        NameAlt: thisNameAlt,
                        Type: thisType,
                        Capital: thisCapital,
                        Flag: thisFlag,
                        Symbol: thisSymbol,
                        DownLevel: thisDownLevel,
                        ForLegis: thisForLegis,
                     }),
                     StatesCount: increment(-1),
                  })

                  setStatesList(statesList.filter((item) => item.State_id !== id))
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               } catch (error) {
                  alert('Error deleting note')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <div className={styles.main_div}>
         {(isSuper || isDataEditor) && (
            <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }} useFlexGap flexWrap="wrap">
               <Link href="/geo-political-divisions/countries/admin" className={styles.link}>
                  Countries
               </Link>
               <Link
                  href="/geo-political-divisions/states/admin"
                  color="#ffffff"
                  onClick={(e) => onClick(e)}
                  className={styles.link2}
               >
                  States
               </Link>
               <Link href="/geo-political-divisions/divisions/admin" className={styles.link}>
                  Divisions
               </Link>
               <Link href="/geo-political-divisions/districts/admin" className={styles.link}>
                  Districts
               </Link>
               <Link href="/geo-political-divisions/subDistricts/admin" className={styles.link}>
                  Sub-Districts
               </Link>
               <Link href="/geo-political-divisions/blocks/admin" className={styles.link}>
                  Blocks
               </Link>
               <Link href="/geo-political-divisions/villages/admin" className={styles.link}>
                  Villages
               </Link>
               <Link href="/legislatures/admin" className={styles.link}>
                  Legislatures
               </Link>
               <Link href="/geo-political-divisions/pin-codes/admin" className={styles.link}>
                  PinCodes
               </Link>
            </Stack>
         )}
         {(isSuper || isDataEditor) && (
            <Box
               sx={{
                  border: 1,
                  my: 1,
                  mx: 5,
                  pb: 1,
                  px: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-root': { width: '15ch' },
                  '& .MuiTextField-root': { width: '50ch' },
                  '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     fontWeight: 600,
                     textAlign: 'center',
                     fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                     ],
                  }}
               >
                  List a State
               </Typography>
               <div>
                  <FormControl sx={{ m: 1 }} size="small">
                     <InputLabel id="demo-select-small-label">Country</InputLabel>
                     <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedCountry}
                        label="Country"
                        onChange={handleChangeCountry}
                        // renderValue={(value) => `⚠️  - ${value.Name}`}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {countries.map((item) => (
                           <MenuItem key={item.id} value={item.id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleStateDetails(e)
                     }}
                     value={stateDetails.Name}
                     name="Name"
                     id="name"
                     label="Name"
                     variant="outlined"
                     margin="dense"
                     required
                     fullWidth
                     size="small"
                     sx={{ paddingBottom: 0 }}
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleStateDetails(e)
                     }}
                     value={stateDetails.NameAlt}
                     name="NameAlt"
                     id="nameAlt"
                     label="Other name"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleStateDetails(e)
                     }}
                     value={stateDetails.Capital}
                     name="Capital"
                     id="capital"
                     label="Capital"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     required
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleStateDetails(e)
                     }}
                     value={stateDetails.Capital_alt}
                     name="Capital_alt"
                     id="capitalAlt"
                     label="Other Capital"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel>Type</InputLabel>
                  <Select value={stateDetails.Type} label="Type" onChange={handleTypeChange}>
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     <MenuItem value="State">State</MenuItem>
                     <MenuItem value="Union Territory">Union Territory</MenuItem>
                  </Select>
               </FormControl>
               <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel>Next down level</InputLabel>
                  <Select value={stateDetails.DownLevel} label="Next down level" onChange={handleDownLevelChange}>
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     <MenuItem value="Divisions">Divisions</MenuItem>
                     <MenuItem value="Districts">Districts</MenuItem>
                  </Select>
               </FormControl>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleStateDetails(e)
                     }}
                     value={stateDetails.LGDCode}
                     name="LGDCode"
                     id="lGDCode"
                     label="LGD Code"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleStateDetails(e)
                     }}
                     value={stateDetails.Flag}
                     name="Flag"
                     id="flag"
                     label="Flag"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <div>
                  <TextField
                     onBlur={(event) => event}
                     onChange={(e) => {
                        handleStateDetails(e)
                     }}
                     value={stateDetails.Symbol}
                     name="Symbol"
                     id="symbol"
                     label="Symbol"
                     variant="outlined"
                     margin="dense"
                     size="small"
                     fullWidth
                  ></TextField>
               </div>
               <div className={styles.alertDiv}>
                  {uploading && <MyLoaderCircularGradient title={'Listing the state ...'}></MyLoaderCircularGradient>}
                  {uploaded && (
                     <Alert variant="outlined" severity="success">
                        State <strong>{lastState}</strong> listed successfully.
                     </Alert>
                  )}
                  {uploadingFailed && (
                     <Alert variant="outlined" severity="error">
                        Error occured! State <strong>{lastState}</strong> could not be listed.
                     </Alert>
                  )}
               </div>
               <div>
                  <Button
                     type="submit"
                     align="center"
                     variant="contained"
                     color="primary"
                     size="small"
                     onClick={(e) => {
                        handleAddState(e)
                     }}
                     disabled={
                        stateDetails.Name.length < 3 ||
                        stateDetails.Capital.length < 3 ||
                        stateDetails.Type.length < 3 ||
                        stateDetails.DownLevel.length < 3 ||
                        stateDetails.Country_id.length < 2
                     }
                  >
                     Submit
                  </Button>
                  {/* {error && <div className={styles.error}>{error}</div>} */}
               </div>
            </Box>
         )}

         {(isSuper || isDataEditor) && (
            <div>
               <Header title="List of states" />
               {statesList &&
                  statesList.map((item, index) => (
                     <Box
                        key={item.State_id}
                        alignItems="center"
                        sx={{
                           border: 1,
                           my: 1,
                           p: 0.5,
                           mx: 5,
                           borderRadius: 2,
                           display: 'flex',
                           flexDirection: 'row',
                        }}
                     >
                        {/* {item.ForLegis && (
                     <div className={styles.ForLegisBadge}>
                        <i className="fa-solid fa-eye"></i>
                     </div>
                  )} */}
                        <div>{index + 1}..</div>
                        <div className={styles.state__details}>
                           <p className={styles.state__capital}>{item.State_id}</p>
                           <h3 className={styles.state__name}>{item.Name}</h3>
                           <p className={styles.state__capital}>{item.Type}</p>
                        </div>
                        <div className={item.ForLegis ? styles.statusOk : styles.statusFalse}>
                           {item.ForLegis ? 'Open for operation' : 'Not ready for operation'}
                        </div>
                        <div className={styles.editButtons}>
                           <Button
                              type="submit"
                              align="center"
                              variant="contained"
                              color={item.ForLegis ? 'error' : 'success'}
                              size="small"
                              sx={{ textTransform: 'none', marginBottom: 0.5, px: 1, py: 0, minWidth: 0 }}
                              onClick={(e) => {
                                 handleOperation(
                                    e,
                                    item.State_id,
                                    item.Name,
                                    item.NameLocal,
                                    item.NameHindi,
                                    item.NameAlt,
                                    item.Type,
                                    item.Capital,
                                    item.Flag,
                                    item.Symbol,
                                    item.DownLevel,
                                    item.ForLegis,
                                 )
                              }}
                           >
                              {item.ForLegis ? 'Stop operation' : 'Start operation'}
                           </Button>
                           {isSuper && (
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={'error'}
                                 size="small"
                                 sx={{ textTransform: 'none', px: 1, py: 0, minWidth: 0 }}
                                 onClick={(e) => {
                                    handleDelete(
                                       e,
                                       item.State_id,
                                       item.Name,
                                       item.NameLocal,
                                       item.NameHindi,
                                       item.NameAlt,
                                       item.Type,
                                       item.Capital,
                                       item.Flag,
                                       item.Symbol,
                                       item.DownLevel,
                                       item.ForLegis,
                                    )
                                 }}
                              >
                                 Delete
                              </Button>
                           )}
                        </div>
                     </Box>
                  ))}
            </div>
         )}
      </div>
   )
}

export default StatesAdmin
