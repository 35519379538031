import React from 'react'
import PropTypes from 'prop-types'

function ElecOrders({ props }) {
   const elec = props
   console.log('value from props from inside elec orders page: ', elec)
   return <div>Elec Orders</div>
}

ElecOrders.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecOrders
