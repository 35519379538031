import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../redux/reducers/users-slice'
import { addDoc, arrayUnion, collection, doc, getDocs, query, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import { useNavigate } from 'react-router-dom'

function AdminRoles() {
   const navigate = useNavigate()

   if (
      auth.currentUser.uid !== 'fOvFUvIMs0bq5GXtqoKvygKOqsJ2' &&
      auth.currentUser.uid !== '858yEVzzRZdXGGQKbCwnkAD6pLk1' &&
      auth.currentUser.uid !== 'Lgm457d2FINaSDFS9ds48RGBaGm1'
   ) {
      navigate('/', { replace: true })
   }

   const user = useSelector(selectUsers)
   const ip = user.ip

   const [selectedLevel, setSelectedLevel] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)
   const [isDisabled, setIsDisabled] = useState(true)
   const [subjectUserId, setSubjectUserId] = useState('')
   const [targetId, setTargetId] = useState('')
   const [targetIdInputLabel, setTargetIdInputLabel] = useState('')
   const [showTargetIdInput, setShowTargetIdInput] = useState(false)
   const [selectedTier, setSelectedTier] = useState(0)

   const userRolesDetails = {
      SiteLevel: [],
      OldSiteLevelRolesNum: 0,
      CountryLevel: [],
      OldCountryLevelRolesNum: 0,
      StateLevel: [],
      OldStateLevelRolesNum: 0,
      DivisionLevel: [],
      OldDivisionLevelRolesNum: 0,
      DistrictLevel: [],
      OldDistrictLevelRolesNum: 0,
      LegislatureLevel: [],
      OldLegislatureLevelRolesNum: 0,
      ProfileLevel: [],
      OldProfileLevelRolesNum: 0,
   }

   useEffect(() => {
      // just for testing
      console.log('role details on change of its value: ', userRolesDetails)
   }, [userRolesDetails])

   const submitButtonStatus = () => {
      let siteLevelReady
      if (selectedLevel !== 'SiteLevel' && targetId.length > 10) {
         siteLevelReady = true
      } else if (selectedLevel === 'SiteLevel' && targetId === '') {
         siteLevelReady = true
      } else {
         siteLevelReady = false
      }

      if (subjectUserId.length > 10 && selectedTier > 0 && selectedTier < 10 && siteLevelReady === true) {
         setIsDisabled(false)
      }
   }

   const handleSubjectUserId = (e) => {
      e.preventDefault()
      setSubjectUserId(e.target.value)
   }

   const handleLevelChange = (e) => {
      e.preventDefault()
      setSelectedLevel(e.target.value)
      switch (e.target.value) {
         case 'SiteLevel':
            setShowTargetIdInput(false)
            setTargetIdInputLabel('')
            setTargetId('')
            break
         case 'CountryLevel':
            setShowTargetIdInput(true)
            setTargetIdInputLabel('Country Id')
            break
         case 'StateLevel':
            setShowTargetIdInput(true)
            setTargetIdInputLabel('State Id')
            break
         case 'DivisionLevel':
            setShowTargetIdInput(true)
            setTargetIdInputLabel('Division Id')
            break
         case 'DistrictLevel':
            setShowTargetIdInput(true)
            setTargetIdInputLabel('District Id')
            break
         case 'LegislatureLevel':
            setShowTargetIdInput(true)
            setTargetIdInputLabel('Legislature Id')
            break
         case 'ProfileLevel':
            setShowTargetIdInput(true)
            setTargetIdInputLabel('Profile Id')
            break
      }

      submitButtonStatus()
   }

   const handleTierChange = (e) => {
      e.preventDefault()
      setSelectedTier(e.target.value)
      submitButtonStatus()
   }

   const handleTargetId = (e) => {
      e.preventDefault()
      setTargetId(e.target.value)
      submitButtonStatus()
   }

   const handleAddUserRole = async (e) => {
      e.preventDefault()
      try {
         setUploading(true)
         const q = query(collection(db, `UserRoles/${subjectUserId}`))
         const querySnapshot = await getDocs(q)
         if (!querySnapshot.exists()) {
            await addDoc(collection(db, `UserRoles/${subjectUserId}`), userRolesDetails)
         }
         const ref = doc(db, `UserRoles/${subjectUserId}`)
         switch (selectedLevel) {
            case 'SiteLevel':
               await updateDoc(ref, {
                  SiteLevel: arrayUnion({
                     Tier: selectedTier,
                  }),
                  OldSiteLevelRolesNum: ref.OldSiteLevelRolesNum + 1,
               })
               await addDoc(collection(db, `PoliticalParties/${subjectUserId}/OldSiteLevelRoles`), {
                  Tier: selectedTier,
                  Added: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               })
               break
            case 'CountryLevel':
               await updateDoc(ref, {
                  CountryLevel: arrayUnion({
                     Country_id: targetId,
                     Tier: selectedTier,
                  }),
                  OldCountryLevelRolesNum: ref.OldCountryLevelRolesNum + 1,
               })
               await addDoc(collection(db, `PoliticalParties/${subjectUserId}/OldCountryLevelRoles`), {
                  Country_id: targetId,
                  Tier: selectedTier,
                  Added: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               })
               break
            case 'StateLevel':
               await updateDoc(ref, {
                  StateLevel: arrayUnion({
                     State_id: targetId,
                     Tier: selectedTier,
                  }),
                  OldStateLevelRolesNum: ref.OldStateLevelRolesNum + 1,
               })
               await addDoc(collection(db, `PoliticalParties/${subjectUserId}/OldStateLevelRoles`), {
                  State_id: targetId,
                  Tier: selectedTier,
                  Added: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               })
               break
            case 'DivisionLevel':
               await updateDoc(ref, {
                  DivisionLevel: arrayUnion({
                     Division_id: targetId,
                     Tier: selectedTier,
                  }),
                  OldDivisionLevelRolesNum: ref.OldDivisionLevelRolesNum + 1,
               })
               await addDoc(collection(db, `PoliticalParties/${subjectUserId}/OldDivisionLevelRoles`), {
                  Division_id: targetId,
                  Tier: selectedTier,
                  Added: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               })
               break
            case 'DistrictLevel':
               await updateDoc(ref, {
                  DistrictLevel: arrayUnion({
                     District_id: targetId,
                     Tier: selectedTier,
                  }),
                  OldDistrictLevelRolesNum: ref.OldDistrictLevelRolesNum + 1,
               })
               await addDoc(collection(db, `PoliticalParties/${subjectUserId}/OldDistrictLevelRoles`), {
                  District_id: targetId,
                  Tier: selectedTier,
                  Added: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               })
               break
            case 'LegislatureLevel':
               await updateDoc(ref, {
                  LegislatureLevel: arrayUnion({
                     Legislature_id: targetId,
                     Tier: selectedTier,
                  }),
                  OldLegislatureLevelRolesNum: ref.OlLegislatureLevelRolesNum + 1,
               })
               await addDoc(collection(db, `PoliticalParties/${subjectUserId}/OldLegislatureLevelRoles`), {
                  Legislature_id: targetId,
                  Tier: selectedTier,
                  Added: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               })
               break
            case 'ProfileLevel':
               await updateDoc(ref, {
                  ProfileLevel: arrayUnion({
                     Profile_id: targetId,
                     Tier: selectedTier,
                  }),
                  OldProfileLevelRolesNum: ref.OldProfileLevelRolesNum + 1,
               })
               await addDoc(collection(db, `PoliticalParties/${subjectUserId}/OldProfileLevelRoles`), {
                  Profile_id: targetId,
                  Tier: selectedTier,
                  Added: true,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               })
               break
            default:
               //
               break
         }
         setUploading(false)
         setUploaded(true)
         setUploadingFailed(false)
      } catch (error) {
         alert('Error adding party name')
         console.log(error.message)
         setUploading(false)
         setUploaded(false)
         setUploadingFailed(true)
      }
   }

   return (
      <Box>
         <Box>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleSubjectUserId(e)
               }}
               value={subjectUserId}
               name="SubjectUserId"
               id="subjectUserId"
               label="User Id"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0 }}
            ></TextField>
         </Box>
         <Box>
            <FormControl sx={{ m: 1 }} size="small">
               <InputLabel>Level</InputLabel>
               <Select value={selectedLevel} label="Level" onChange={handleLevelChange}>
                  <MenuItem value="">
                     <em>-- select --</em>
                  </MenuItem>
                  <MenuItem value="SiteLevel">Site level</MenuItem>
                  <MenuItem value="CountryLevel">Country level</MenuItem>
                  <MenuItem value="StateLevel">State level</MenuItem>
                  <MenuItem value="DivisionLevel">Division level</MenuItem>
                  <MenuItem value="DistrictLevel">District level</MenuItem>
                  <MenuItem value="LegislatureLevel">Legislature level</MenuItem>
                  <MenuItem value="ProfileLevel">Profile level</MenuItem>
               </Select>
            </FormControl>
         </Box>
         <Box>
            <FormControl sx={{ m: 1 }} size="small">
               <InputLabel>Tier</InputLabel>
               <Select value={selectedTier} label="Tier" onChange={handleTierChange}>
                  <MenuItem value="">
                     <em>-- select --</em>
                  </MenuItem>
                  <MenuItem value="1">Tier One</MenuItem>
                  <MenuItem value="2">Tier Two</MenuItem>
                  <MenuItem value="3">Tier Three</MenuItem>
                  <MenuItem value="4">Tier Four</MenuItem>
                  <MenuItem value="5">Tier Five</MenuItem>
                  <MenuItem value="6">Tier Six</MenuItem>
                  <MenuItem value="7">Tier Seven</MenuItem>
                  <MenuItem value="8">Tier Eight</MenuItem>
                  <MenuItem value="9">Tier Nine</MenuItem>
               </Select>
            </FormControl>
         </Box>
         <Box>
            {showTargetIdInput && (
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleTargetId(e)
                  }}
                  value={targetId}
                  name="TargetId"
                  id="targetId"
                  label={targetIdInputLabel}
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0 }}
               ></TextField>
            )}
         </Box>
         <Box>
            {uploading && <MyLoaderCircularGradient title={'Listing the legislature ...'}></MyLoaderCircularGradient>}
            {uploaded && (
               <Alert variant="outlined" severity="success">
                  Role allotted successfully.
               </Alert>
            )}
            {uploadingFailed && (
               <Alert variant="outlined" severity="error">
                  Role could not be allotted.
               </Alert>
            )}
         </Box>
         <Box>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleAddUserRole(e)
               }}
               disabled={isDisabled}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

export default AdminRoles
