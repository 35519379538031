import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import {
   Alert,
   Avatar,
   Badge,
   Box,
   Button,
   Checkbox,
   Divider,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function AddConstisToPhase({
   constisListAvailable,
   handleAddConstisToPhase,
   uploadingConstis,
   uploadedConstis,
   uploadingFailedConstis,
}) {
   const [checkedList, setCheckedList] = useState([])

   const handleCheckList = (e) => {
      //
      if (e.target.checked) {
         if (!checkedList.includes(e.target.value)) {
            setCheckedList([...checkedList, e.target.value])
            // Note:
            // alternative code as follows:
            // setCheckedList((checkedList) => [...checkedList, constiId])
         }
      } else {
         setCheckedList((checkedList) => [...checkedList.filter((consti) => consti !== e.target.value)])
      }
   }

   useEffect(() => {
      if (checkedList) {
         console.log('checked list:', checkedList)
      }
   }, [checkedList])

   const handleSubmitSelectedConsti = async (e) => {
      e.preventDefault()

      console.log('constiDetails before submit: ', checkedList)
      handleAddConstisToPhase(checkedList)
      setCheckedList([])
   }
   return (
      <Box>
         <Box sx={{ width: '45ch', pl: 2 }}>
            <List dense>
               {constisListAvailable.map((item, index) => (
                  <div key={item.Constituency_id}>
                     <ListItem
                        key={item.Constituency_id}
                        secondaryAction={
                           <Checkbox
                              edge="end"
                              onChange={(e) => {
                                 handleCheckList(e)
                              }}
                              value={item.Constituency_id}
                              // checked={checkedList.includes(item.Constituency_id)}
                              inputProps={{ 'aria-labelledby': item.Name }}
                           />
                        }
                        disablePadding
                     >
                        <ListItemAvatar>
                           <Avatar sx={{ height: '30px', width: '30px' }}>
                              <Badge badgeContent={index + 1} color="secondary" max={999}></Badge>
                           </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                           secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                           primaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                           primary={`${item.Name} (${item.ConstituencyNumber})`}
                           component={'span'}
                           secondary={
                              <>
                                 {item.Strata === 'Central' && (
                                    <>
                                       <br />
                                       {' State / UT: '}
                                       <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                       >
                                          {item.StateName}
                                       </Typography>
                                    </>
                                 )}
                              </>
                           }
                        />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
            </List>
         </Box>
         <Box sx={{ mb: 4 }}>
            {uploadingConstis && (
               <MyLoaderCircularGradient title={'Listing the selected constituencies ...'}></MyLoaderCircularGradient>
            )}
            {uploadedConstis && (
               <Alert variant="outlined" severity="success">
                  <strong>selected constituencies</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedConstis && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>selected constituencies</strong> could not be listed.
               </Alert>
            )}
         </Box>
         <Box sx={{ pl: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitSelectedConsti(e)
               }}
               disabled={checkedList.length < 1 || uploadingConstis}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

AddConstisToPhase.propTypes = {
   constisListAvailable: PropTypes.array.isRequired,
   handleAddConstisToPhase: PropTypes.func.isRequired,
   uploadingConstis: PropTypes.bool.isRequired,
   uploadedConstis: PropTypes.bool.isRequired,
   uploadingFailedConstis: PropTypes.bool.isRequired,
}

export default AddConstisToPhase
